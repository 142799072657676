import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OrderService} from '../post-order/order.service';
import {ColumnFilterFormElement, Table, TableFilterEvent} from 'primeng/table';
import {ConfirmationService, FilterMetadata, MessageService, SelectItem, SortEvent} from "primeng/api";
import {Location} from '@angular/common';
import {ExcelExportService} from '../post-order/excel-export.service';
import moment from 'moment-timezone';
import {Router} from "@angular/router";
import {TagsService} from '../setup/tags/tags.service';
import {WebsiteService} from '../setup/websites/website.service';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';
import {ActionCfg, getActionNameLookup, getActionConfigs, getActionSelectItems, getActionColour, getActionBackgroundColour} from '../lookups/actions';
import {BrandCfg, getBrandConfigs, getBrandSelectItems, getBrandBackgroundColour, getBrandColour} from '../lookups/brands';
import {StatusCfg, statusConfigs, getStatusColour, getStatusBackgroundColour, statusSelectItems, allEquipStatusSelectItems} from '../lookups/statuses';
import {TagsResponse} from '../models/responses/tagsResponse.model';
import {Tag} from '../models/tag.model';
import {importTypes} from '../lookups/importTypes';
import {ImportType} from '../models/importType.model';
import {SpreadsheetImportService} from './spreadsheet-import.service';
import {SpreadsheetImportRequest} from '../models/requests/spreadsheetImportRequest.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {convertOrderToData} from '../helpers/convertOrderToData';
import {FilterService} from 'primeng/api';
import {OrderPageResponse} from '../models/responses/orderPageResponse.model';
import {OrderUpdate} from '../models/socket-io/orderUpdate.model';
import {UsersService} from '../setup/users/users.service';
import {renewalTypeForFilters} from '../lookups/renewalTypes';
import {Order} from '../models/order.model';
import {Column} from '../models/column.model';
import {WebsitesResponse} from '../models/responses/websitesResponse.model';
import {Website} from '../models/website.model';
import {getHomepageColumns, orderSpreadsheetCols} from '../lookups/spreadsheetColumns';
import {CancellationCategorySelectItem, getCancellationReasonCategories} from '../lookups/cancellationReasons';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {OrderUpdatesSocketService} from '../sockets/order-updates-socket.service';
import {Title} from '@angular/platform-browser';
import {tdCodeMatchModeOptions} from '../lookups/filterOptions';
import {OrderLockData} from '../models/socket-io/orderLockData.model';
import {getMonitoringOptions} from '../lookups/monitoring';
import {crmDelay, getBase64EncodedFileContents} from '../helpers/helperFunctions';
import {HomepageFormatOrder} from '../models/homepageFormatOrder.model';
import {StringIndexedObject} from '../models/utility/stringIndexedObject.model';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;
ColumnFilterFormElement.prototype.onModelChange = function (value) {
  this.filterConstraint.value = value;
  if (this.type || value === '') {
    this.dt._filter();
  }
}

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class MyOrdersComponent implements OnInit, OnDestroy {
  constructor(
    private orderService: OrderService,
    private location: Location,
    private tagsService: TagsService,
    private websiteService: WebsiteService,
    private locksSocket: LocksSocketService,
    private orderUpdatesSocket: OrderUpdatesSocketService,
    private confirmationService: ConfirmationService,
    private excelService: ExcelExportService,
    private router: Router,
    private messageService: MessageService,
    private spreadsheetImportService: SpreadsheetImportService,
    private filterService: FilterService,
    private userService: UsersService,
    private title: Title,
  ) {
  }
  Object = Object;
  cols: Column[];
  bigSearch: string[];
  locked: OrderLockData[] = [];
  data: HomepageFormatOrder[] = [];
  websites: Website[];
  selectedOrders: StringIndexedObject<HomepageFormatOrder>;
  numberOfSelectedOrders: number;
  selectAllChecked: boolean;
  renewaltypes: SelectItem<string>[] = renewalTypeForFilters;
  readonly statusType: SelectItem<string>[] = statusSelectItems;
  freeMonthsType: SelectItem<string>[];
  monitoringOptions: SelectItem<string>[];
  pStatus: SelectItem<string>[];
  cancellationReasons: SelectItem<string>[];
  selectedstatus: string[];
  selectedMonths: string[];
  selectedmonitoring: string[];
  userName: string;
  selectedColumns: Column[];
  defaultColumns: Column[];
  selectedBrand: string[];
  selectedrenewalType: string[];
  savedColumnSets: SelectItem<Column[]>[];
  logs: {'msg': string; 'status': string; 'tdCode'?: string}[];
  progress: number;
  paginationLoadingProg: number;
  totalRecords: number;
  tagsColor: {[tagName: string]: string} = {};
  findField: string;
  dateFilters: Date[];
  renewalDateFilters: Date[];
  actionDateFilters: Date[];
  actionsInitiatedDateFilters: Date[];
  notesDateDateFilters: Date[];
  returnDateDateFilters: Date[];
  cancellationDateFilters: Date[];
  vimsForDialog: any;
  titleDialog: string;
  sliceOptions: {[option: string]: number};
  mark: number;
  tags: Tag[];
  tagSelectItems: SelectItem<string>[] = [];
  vimModalDisplay: boolean = false;
  showImportDialog: boolean = false;
  curDate: Date = new Date();
  minDAte: Date = new Date("1980");
  All: number;
  selectedAction: string;
  file: File;
  actions: ActionCfg;
  brands: SelectItem<string>[];
  actionSelectItems: SelectItem<string>[];
  actionNameLookup: {[lookupName: string]: string};
  brandConfigs: BrandCfg;
  readonly statusConfigs: StatusCfg = statusConfigs;
  getActionColour = getActionColour;
  getActionBackgroundColour = getActionBackgroundColour;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  getBrandColour = getBrandColour;
  getBrandBackgroundColour = getBrandBackgroundColour;
  importTypes: ImportType[];
  selectedImportType: ImportType;
  allowOrderCreation: boolean = false;
  orderExportOptions: string[] = [
    '1 sheet, 1 tab total',
    '1 sheet, 1 tab/brand',
    '1 sheet/brand'
  ];
  pagesProcessing: number;
  closing: boolean;
  first: number;
  updateReceived: boolean;
  myMoment = moment;
  isSaveColumnDisabled: boolean = false;
  selectedBrandForReview: string = null;
  tdCodeMatchModeOptions: SelectItem<string>[] = tdCodeMatchModeOptions;
  columnSetName: string;
  showColumSetDialog: boolean = false;
  updatesWhileLoading: {[orderId: string]: Order} = {};
  filterYearRange: string;

  showPageCountError(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: 'Error getting page count' + errorMessage,
    });
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  async serverProcessUpload(fileContentsBase64: string) {
    this.logs = [];
    this.progress = -2;
    const importParams: SpreadsheetImportRequest = {
      'appName': this.selectedImportType.appName,
      'filename': this.file.name,
      'fileContents': fileContentsBase64,
      'username': this.userName,
      'email': localStorage.getItem('email'),
    };
    this.spreadsheetImportService.importSpreadsheet(importParams).subscribe(
      (response: SimpleResponse) => {
        if (response.success) {
          this.logs.push({
            'msg': response.message,
            'status': 'alert-success',
            'tdCode': 'N/A'
          });
        } else {
          this.logs.push({
            'msg': response.message,
            'status': 'alert-danger',
            'tdCode': 'N/A'
          });
        }
      },
      (error: any) => {
        this.logs.push({
          'msg': 'Error processing spreadsheet. Error' + error.message,
          'status': 'alert-danger',
          'tdCode': 'N/A'
        });
      }
    );
  }

  /*Import function*/
  async upload() {
    const fileContents: string = await getBase64EncodedFileContents(this.file);
    this.serverProcessUpload(fileContents);
  }

  closeUpload() {
    this.progress = -1;
    this.showImportDialog = false;
  }

  /*Import function*/
  /*dateTimeout: any;*/
  @ViewChild('dt', {static: true})
  private table: Table;

  onClick() {
    this.table.filteredValue = null;
    this.table.first = 0;
    this.findField = '';
    this.selectedstatus = [];
    this.selectedBrand = [];

    // grab a copy of the p-columnFilter values as if these are removed completly it upsets the controls.
    const tempPColumnFilters: { [s: string]: FilterMetadata | FilterMetadata[]; } = {};
    for (const filterName of Object.keys(this.table.filters)) {
      const colDef: Column|undefined = this.cols.find((column: Column) => column.field == filterName);
      if (colDef && colDef.usePColumnFilter) {
        tempPColumnFilters[filterName] = {
          value: null,
          matchMode: colDef.filterMatchMode? colDef.filterMatchMode: 'startsWith',
        };
      }
    }
    this.table.filters = tempPColumnFilters;
    // clear has removed the filters, so back to original data length
    this.table.totalRecords = this.data.length;
    this.actionDateFilters = null;
    this.actionsInitiatedDateFilters = null;
    this.selectedAction = '';
    this.dateFilters = null;
    this.selectedrenewalType = [];
    this.selectedOrders = {};
    this.numberOfSelectedOrders = 0;
    this.selectAllChecked = false;
    this.selectedMonths = [];
    this.selectedmonitoring = [];
    this.renewalDateFilters = null;
    this.notesDateDateFilters = null;
    this.returnDateDateFilters = null;
    this.cancellationDateFilters = null;
    this.selectedrenewalType = [];
    this.table.sortField = 'created';
    this.table.sortOrder = -1;
    this.table.sortSingle();
  }

  sortByDate(e) {
    if (this.mark == 0) {
      this.mark = 1;
    }
    this.mark *= -1;
    this.table.filteredValue = [
      ...this.data.filter(row => row.outstandingActionsRenewalDateTaken.length != 0).sort((a, b) =>
        this.mark * (a.outstandingActionsRenewalDateTaken[0].getTime() - b.outstandingActionsRenewalDateTaken[0].getTime())
      ),
      ...this.data.filter(row => row.outstandingActionsRenewalDateTaken.length == 0)
    ];
  }

  export(orderExportOption: string) {
    if (!this.excelService.exportAllColumns(
      Object.values(this.selectedOrders),
      this.cols,
      this.userName,
      orderExportOption
    )) {
      this.messageService.add({
        severity: 'error',
        life: 300000,
        summary: 'Data Truncated',
        detail: 'Some of the data exceeded the maximum excel cell length of 32,767 characters. Affected cells have been truncated and have "Truncated..." at the start',
      });
    }
  }

  customExport(orderExportOption: string) {
    this.selectedColumns.sort((a, b) => a.order - b.order);
    if (!this.excelService.exportAllColumns(
      Object.values(this.selectedOrders),
      this.selectedColumns,
      this.userName,
      orderExportOption
    )) {
      this.messageService.add({
        severity: 'error',
        life: 300000,
        summary: 'Data Truncated',
        detail: 'Some of the data exceeded the maximum excel cell length of 32,767 characters. Affected cells have been truncated and have "Truncated..." at the start',
      });
    }
  }

  userCanExportOrders(): boolean {
    return this.userService.userHasPermission('Export Orders');
  }

  userHasImportPermission(): boolean {
    return this.userService.userHasImportPermission();
  }

  async ngOnInit() {
    this.title.setTitle('CRM Home');
    this.first = 0;
    this.updateReceived = false;
    this.updatesWhileLoading = {};
    this.closing = false;
    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      this.locked = data;
    });
    this.filterYearRange = `2014:${moment.tz('Europe/London').add(1, 'year').get('year')}`;
    this.selectedOrders = {};
    this.numberOfSelectedOrders = 0;
    this.selectAllChecked = false;
    this.orderUpdatesSocket.on('updateOrders', this.processUpdateFromSocketIo);
    this.tagsColor = {};
    this.selectedAction = '';
    this.userName = localStorage.getItem('userName');
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
    this.selectedBrandForReview = this.brands[0].value;
    this.actions = getActionConfigs();
    this.actionSelectItems = getActionSelectItems();
    this.actionNameLookup = getActionNameLookup();
    this.importTypes = importTypes.filter(
      (importType: ImportType) => this.userService.userHasPermission(importType.label)
    );
    this.selectedImportType = (this.importTypes.length > 0)? this.importTypes[0]: undefined;

    // Update lock list if page is redisplayed.
    this.locksSocket.emit('getLocked');

    this.progress = -1;
    /*MOMENT JS*/
    this.tagsService.getActiveTags().subscribe((tagsResponse: TagsResponse) => {
      this.tags = tagsResponse["tags"];
      // console.log(this.tags);
      for (let tag of this.tags) {
        this.tagsColor[tag["tagName"]] = tag["color"];
        this.tagSelectItems.push({
          'label': tag.tagName,
          'value': tag.tagName,
        });
      }
    }, err => {
      ////console.log(err);
    });
    this.mark = 0;
    /*End MOMENT JS*/
    this.websiteService.getWebsites()
      .subscribe((websites: WebsitesResponse) => {
        this.websites = websites.websites;
      });
    this.orderService.getOrderPageCount().subscribe(
      async (pageCountResponse: PageCountResponse) => {
        if (!pageCountResponse.success) {
          this.showPageCountError(pageCountResponse.message);
        } else {
          this.paginationLoadingProg = pageCountResponse.pageCount!;
          this.pagesProcessing = 0;
          for (let page: number = pageCountResponse.pageCount!; page > 0; page--) {
            // Limit the max number of pages being updated in parallel which allows other work to complete
            // whilst pages are still loading and allows abort if page navigated away from
            while (this.pagesProcessing >= MAX_PARALLEL) {
              await crmDelay(250);
            }
            // No need to keep loading pages
            if (this.closing) {
              break;
            }
            this.pagesProcessing++;
            this.loadPage(page, PAGE_RETRIES);
          }
        }
      });
    this.sliceOptions = {
      start: 0,
      end: 10,
      default: 10
    };
    this.cancellationReasons = [{
      value: '*blanks', label: ' '
    }];
    // this strips the disabled flag and other properties off the select items
    getCancellationReasonCategories(false).forEach((selectItem: CancellationCategorySelectItem) => {
      this.cancellationReasons.push({
        'label': selectItem.label,
        'value': selectItem.value
      });
    });
    this.pStatus = allEquipStatusSelectItems;
    this.freeMonthsType = [
      {label: '1 month', value: '1'},
      {label: '2 months', value: '2'},
      {label: '3 months', value: '3'},
      {label: '4 months', value: '4'},
      {label: '5 months', value: '5'},
      {label: '6 months', value: '6'},
      {label: '7 months', value: '7'},
      {label: '8 months', value: '8'},
      {label: '9 months', value: '9'},
      {label: '10 months', value: '10'},
      {label: '11 months', value: '11'},
      {label: '12 months', value: '12'},
    ];
    this.monitoringOptions = getMonitoringOptions();

    this.cols = getHomepageColumns();
    this.bigSearch = this.cols.filter((col: Column) =>
        !col.excludeFromGlobalSearch
      ).map ((col: Column) =>
        col.field
      );
    // Add field name of notes array to allow filter to handle
    this.bigSearch.push('notesContent');
    this.defaultColumns = JSON.parse(localStorage.getItem('defaultColumns'));
    if (!this.selectedColumns) {
      // this.selectedColumns = this.cols.filter(col => col.hide != true);
      this.selectedColumns = this.defaultColumns;
    }
    this.savedColumnSets = JSON.parse(localStorage.getItem('savedColumnSets'));

    let _self = this;
    /*Filter column on load by default*/
    this.selectedstatus = [];
    this.selectedBrand = [];
    this.filterService.register('NoDate', (value: any[], filter: any): boolean => {
      for (let i = 0; i < value.length; i++) {

        let vDate = (new Date(value[i]).getTime());
        // console.log(vDate);
        if (vDate == 0) {
          return true;
        }
      }

      return false;
    });
    this.filterService.register('inCollection', (value: any[], filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      // if(filter === undefined || filter === null || )
      if ((filter === undefined) || (filter === null) || (_self.actionDateFilters == null) ||
          (_self.actionDateFilters[0] === undefined) || (_self.actionDateFilters[0] === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.actionDateFilters[0].getTime();
      let e;
      if (_self.actionDateFilters[1]) {
        e = _self.actionDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      for (let i = 0; i < value.length; i++) {

        let vDate = (new Date(value[i]).getTime());

        if (vDate >= s && vDate <= e) {
          return true;
        }
      }

      return false;
    });
    this.filterService.register('inCollectionActionsInitiatedDateFilter', (value: any[], filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.actionsInitiatedDateFilters === null) ||
          (_self.actionsInitiatedDateFilters[0] === undefined) || (_self.actionsInitiatedDateFilters[0] === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.actionsInitiatedDateFilters[0].getTime();
      let e;
      if (_self.actionsInitiatedDateFilters[1]) {
        e = _self.actionsInitiatedDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      for (let i = 0; i < value.length; i++) {

        let vDate = (new Date(value[i]).getTime());

        if (vDate >= s && vDate <= e) {
          return true;
        }
      }

      return false;
    });
    this.filterService.register('inCollectionReturnDate', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.returnDateDateFilters === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.returnDateDateFilters[0].getTime();
      let e;
      if (_self.returnDateDateFilters[1]) {
        e = _self.returnDateDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      let vDate = (new Date(value).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
    this.filterService.register('inCollectionCancellationDate', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if (filter === undefined || filter === null || _self.cancellationDateFilters === null || _self.cancellationDateFilters.length === 0 || _self.cancellationDateFilters[0] === undefined || _self.cancellationDateFilters[0] === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      let s = _self.cancellationDateFilters[0].getTime();
      let e;
      if (_self.cancellationDateFilters[1]) {
        e = _self.cancellationDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      let vDate = (new Date(value).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
    this.filterService.register('inCollectionNoteDate', (noteTimes: number[], filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter == null) || (_self.notesDateDateFilters == null)) {
        return true;
      }
      if ((noteTimes == null) || (noteTimes.length == 0)) {
        return false;
      }
      const startTime: number = _self.notesDateDateFilters[0].getTime();
      let endTime: number;
      if (_self.notesDateDateFilters[1]) {
        endTime = _self.notesDateDateFilters[1].getTime() + 86400000 - 1;
      } else {
        endTime = startTime + 86400000 - 1;
      }
      for (let i = 0; i < noteTimes.length; i++) {
        if (!noteTimes[i]) {
          continue;
        }
        if ((noteTimes[i] >= startTime) && (noteTimes[i] <= endTime)) {
          return true;
        }
      }
      return false;
    });
    this.filterService.register('inCreatedCollection', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.dateFilters === null) || (!_self.dateFilters[0])) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.dateFilters[0].getTime();
      let e;
      if (_self.dateFilters[1]) {
        e = _self.dateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      let vDate = (new Date(value).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
    this.filterService.register('inrenewalDateFilters', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.renewalDateFilters === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.renewalDateFilters[0].getTime();
      let e;
      if (_self.renewalDateFilters[1]) {
        e = _self.renewalDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      // let vDate = (new Date(value).getTime());
      let vDate = (new Date(_self.myMoment(value,'DD/MM/YYYY').toDate()).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
  }

  loadColumnSet(columns: Column[]) {
    this.selectedColumns = columns;
  }

  processUpdateFromSocketIo: (orderUpdateData: OrderUpdate) => void = (newData: OrderUpdate) => {
    // Hold off applying updates whilst the data is loading else end up with duplicates
    if (this.paginationLoadingProg > 0) {
      this.updatesWhileLoading[newData.order._id] = newData.order;
    } else {
      this.updateReceived = true;
      this.first = this.table.first;
      const neworder: HomepageFormatOrder = convertOrderToData(newData.order);
      let tmpData = this.data;
      this.data = [];
      if (newData.order.deleted == false) {
        this.data = [...[neworder], ...tmpData.filter(item => item._id != neworder["_id"])];
      } else {
        this.data = [...tmpData.filter(item => item._id != neworder["_id"])];
      }
      this.selectAllChecked = false;
    }
  }

  loadPage(page: number, retryCount: number) {
    // pageCount gives number of pages, but get by page uses zero offset
    this.orderService
      .getOrdersByPage(page - 1)
      .subscribe({
        'next': (orderResponse: OrderPageResponse) => {
          if ((!orderResponse.success) || (!orderResponse.orders)) {
            console.log(`Error loading page ${page}. Error: ${orderResponse.message}`);
            if (retryCount > 0) {
              this.loadPage(page, retryCount - 1);
              return;
            }
            // Only decrement count if we are not retrying page
            this.pagesProcessing--;
            return;
          }
          this.pagesProcessing--;
          this.data = [...this.data, ...orderResponse.orders.map((order: Order) => {
            return convertOrderToData(order);
          })];
          this.All = this.data.length;
          this.selectAllChecked = false;
          this.decrementPagesRemaining();
        },
        'error': (err: any) => {
          console.log(`Error loading page ${page}. Error ${err.message}`);
          if (retryCount > 0) {
            this.loadPage(page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.pagesProcessing--;
        }
      });
  }

  decrementPagesRemaining(): void {
    this.paginationLoadingProg--;
    if (this.paginationLoadingProg == 0) {
      // Now apply updates received whilst pages loading
      this.updateReceived = true;
      this.first = this.table.first;
      let tmpData = this.data;
      this.data = [];
      tmpData.forEach(item => {
        if (this.updatesWhileLoading[item._id]) {
          const order: Order = this.updatesWhileLoading[item._id];
          // Don't included orders now deleted
          if (!order.deleted) {
            // Remove the order off so updates so we are left with just new records
            delete this.updatesWhileLoading[item._id];
            this.data.push(convertOrderToData(order));
          }
        } else {
          // The order hasn't changed
          this.data.push(item);
        }
      });
      if (Object.keys(this.updatesWhileLoading).length > 0) {
        Object.keys(this.updatesWhileLoading).forEach((orderId: string) => {
          this.data.push(convertOrderToData(this.updatesWhileLoading[orderId]));
          delete this.updatesWhileLoading[orderId];
        });
        this.selectAllChecked = false;
      }

    }
  }

  /*Filter column on load by default*/
  goBack() {
    this.location.back();
  }

  onColumnChange(event, _dt) {
    const isTitleExist: boolean = event.value.some((col: Column) => col.field == 'title');
    const isTdCodeExist: boolean = event.value.some((col: Column) => col.field == 'tdCode');
    const isCustomerNameExist: boolean = event.value.some((col: Column) => col.field == 'customerName');
    // Have to push the current column definition, else it doesn't work correctly
    if (!isTitleExist) {
      this.selectedColumns.push(orderSpreadsheetCols.find((defaultCfg: Column) => defaultCfg.field == 'title'));
    }
    if (!isTdCodeExist) {
      this.selectedColumns.push(orderSpreadsheetCols.find((defaultCfg: Column) => defaultCfg.field == 'tdCode'));
    }
    if (!isCustomerNameExist) {
      this.selectedColumns.push(orderSpreadsheetCols.find((defaultCfg: Column) => defaultCfg.field == 'customerName'));
    }
    this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  isColumnVisible(column: string): boolean {
    return this.selectedColumns? this.selectedColumns.some((col: Column) => (col.field === column)): false;
  }

  unlock(_id) {
    ////console.log("unlock");
    this.confirmationService.confirm({
      message: this.orderLockedBy(_id) +
        ' is currently editing this order, do you want to have full access permission? Warning: Any unsaved changes made by ' +
        this.orderLockedBy(_id) + ' will be lost.',
      header: 'Warning',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.locksSocket.emit("unlocking", {'orderId': _id, 'user': this.userName});
        this.router.navigate(["/order/" + _id]);
      },
      reject: () => {
      }
    });
  }

  customSort(event: SortEvent) {
    let arrayToSort: any[] = this.table.value;
    if (this.table.filteredValue != null) {
      arrayToSort = this.table.filteredValue;
    }
    arrayToSort = arrayToSort.map(order => {
      for (let k = 0; k < order.outstandingActionsRenewalDateTaken.length; k++) {
        for (let i = 0; i < order.outstandingActionsRenewalDateTaken.length - 1; i++) {
          if (event.order == -1 && (order.outstandingActionsRenewalDateTaken[i] < order.outstandingActionsRenewalDateTaken[i + 1])) {
            let x = order.outstandingActionsRenewalDateTaken[i];
            order.outstandingActionsRenewalDateTaken[i] = order.outstandingActionsRenewalDateTaken[i + 1];
            order.outstandingActionsRenewalDateTaken[i + 1] = x;
            x = order.outstandingActionsName[i];
            order.outstandingActionsName[i] = order.outstandingActionsName[i + 1];
            order.outstandingActionsName[i + 1] = x;
            x = order.outstandingActionsInitiatedDate[i];
            order.outstandingActionsInitiatedDate[i] = order.outstandingActionsInitiatedDate[i + 1];
            order.outstandingActionsInitiatedDate[i + 1] = x;
          }
          if (event.order == 1 && (order.outstandingActionsRenewalDateTaken[i] > order.outstandingActionsRenewalDateTaken[i + 1] || new Date(order.outstandingActionsRenewalDateTaken[i]).getTime() == 0)) {
            let x = order.outstandingActionsRenewalDateTaken[i];
            ////console.log(order.outstandingActionsRenewalDateTaken[i])
            order.outstandingActionsRenewalDateTaken[i] = order.outstandingActionsRenewalDateTaken[i + 1];
            order.outstandingActionsRenewalDateTaken[i + 1] = x;
            x = order.outstandingActionsName[i];
            order.outstandingActionsName[i] = order.outstandingActionsName[i + 1];
            order.outstandingActionsName[i + 1] = x;
            x = order.outstandingActionsInitiatedDate[i];
            order.outstandingActionsInitiatedDate[i] = order.outstandingActionsInitiatedDate[i + 1];
            order.outstandingActionsInitiatedDate[i + 1] = x;
          }
        }
      }
      return order;
    });

    event.data.sort((data1, data2) => {
      const datesFields: string[] = ['renewalDate', 'dob', 'paymentDueDate', 'dispatchDate'];
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      if (event.field == "outstandingActionsRenewalDateTaken") {
        let p1 = 0;
        let p2 = 0;
        if (this.selectedAction != '') {
          p1 = data1["outstandingActionsName"].indexOf(this.selectedAction);
          p2 = data2["outstandingActionsName"].indexOf(this.selectedAction);

        }
        value1 = moment(data1[event.field][p1]).format('YYYY-MM-DD');
        value2 = moment(data2[event.field][p2]).format('YYYY-MM-DD');

        if (event.order == -1) {
          if (value1 == "Invalid date" || p1 == -1)
            value1 = '1999-01-01';
          if (value2 == "Invalid date" || p2 == -1)
            value2 = '1999-01-01';
        }
        if (event.order == 1) {
          if (value1 == "Invalid date" || p1 == -1)
            value1 = '2999-12-30';
          if (value2 == "Invalid date" || p2 == -1)
            value2 = '2999-12-30';
        }
      } else if (datesFields.includes(event.field)) {
        value1 = value1 ? moment(value1, 'DD/MM/YYYY').toDate() : value1;
        value2 = value2 ? moment(value2, 'DD/MM/YYYY').toDate() : value2;
      } else if (event.field == 'notesContent') {
        value1 = data1.notesContent[0]? data1.notesContent[0]: '';
        value2 = data2.notesContent[0]? data2.notesContent[0]: '';
      } else if (event.field == 'noteCategory') {
        value1 = data1.noteCategory[0]? data1.noteCategory[0]: '';
        value2 = data2.noteCategory[0]? data2.noteCategory[0]: '';
      } else if (event.field == 'notesUserName') {
        value1 = data1.notesUserName[0]? data1.notesUserName[0]: '';
        value2 = data2.notesUserName[0]? data2.notesUserName[0]: '';
      } else if (event.field == 'notesWithDate') {
        // notesWithDate starts with the note username then has note date then categories
        value1 = data1.notesUserName[0]? `${data1.notesUserName[0]} ${data1.noteTime[0]} ${data1.noteCategory[0]}`: '';
        value2 = data2.notesUserName[0]? `${data2.notesUserName[0]} ${data2.noteTime[0]} ${data2.noteCategory[0]}`: '';
      } else if (event.field == 'notesDate') {
        value1 = data1.noteTime[0]? data1.noteTime[0]: '';
        value2 = data2.noteTime[0]? data2.noteTime[0]: '';
      }
      // ////console.log(value1+"|"+value2);
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);

    });

  }

  customFilter(event: TableFilterEvent) {
    if (this.table.filteredValue == null) {
      this.table.filteredValue = this.table.value;
    }
    if (this.selectedAction && this.actionDateFilters && this.actionDateFilters.length != 0) {
      this.table.filteredValue = this.table.filteredValue.filter(e => {
        const selectedActionIndex: number = e.outstandingActionsName.indexOf(this.selectedAction);
        let date1: any = new Date();
        const date2 = moment(this.actionDateFilters[0]).format('YYYY/MM/DD');
        if (selectedActionIndex == -1) {
          return false;
        } else {
          date1 = moment(e.outstandingActionsRenewalDateTaken[selectedActionIndex]).format('YYYY/MM/DD');
        }
        if (this.actionDateFilters[0] && this.actionDateFilters[1]) {
          const date3 = moment(this.actionDateFilters[1]).format('YYYY/MM/DD');
          return Date.parse(date2) <= Date.parse(date1) && Date.parse(date1) <= Date.parse(date3);
        } else {
          return Date.parse(date1) == Date.parse(date2);
        }
      });
      this.table.totalRecords = this.table.filteredValue.length;
    }

    if (this.selectedAction && this.actionsInitiatedDateFilters && this.actionsInitiatedDateFilters.length != 0) {
      this.table.filteredValue = this.table.filteredValue.filter(e => {
        const selectedActionIndex: number = e.outstandingActionsName.indexOf(this.selectedAction);
        let date1: any = new Date();
        const date2 = moment(this.actionsInitiatedDateFilters[0]).format('YYYY/MM/DD');
        if (selectedActionIndex == -1)
          return false;
        else
          date1 = moment(e.outstandingActionsInitiatedDate[selectedActionIndex]).format('YYYY/MM/DD');
        if (this.actionsInitiatedDateFilters[0] && this.actionsInitiatedDateFilters[1]) {
          const date3 = moment(this.actionsInitiatedDateFilters[1]).format('YYYY/MM/DD');
          return Date.parse(date2) <= Date.parse(date1) && Date.parse(date1) <= Date.parse(date3);
        } else {
          return Date.parse(date1) == Date.parse(date2);
        }
      });
      this.table.totalRecords = this.table.filteredValue.length;
    }
    // If the re-run of the filter is triggered by an update want to go back to the page we were on
    if (this.updateReceived) {
      this.table.first = Math.min(this.first, this.table.totalRecords);
      // reset the flag
      this.updateReceived = false;
    }
    if (this.Object.keys(this.selectedOrders).length < this.table.filteredValue.length) {
      // Can't possibly be all selected
      this.selectAllChecked = false;
    } else {
      // have to check all selected
      this.selectAllChecked = !this.table.filteredValue.some((order: HomepageFormatOrder) => (this.selectedOrders[order._id] == null));
    }
  }

  showDialog(data) {
    ////console.log("showDialog");
    this.vimModalDisplay = true;
    this.vimsForDialog = data.vim;
    this.titleDialog = data.tdCode + ", " + data.customerName;
  }

  refresh() {
    location.reload();
  }

  isOrderLocked(_id: string) {
    return this.locked.filter((lock: OrderLockData) => lock.orderId == _id).length > 0
  }

  orderLockedBy(_id: string) {
    return this.locked.filter((lock: OrderLockData) => lock.orderId == _id)[0].user
  }

  actionDateCustomFilter($event: MouseEvent, number: number) {

    // //console.log(this.actionDateFilters);
    this.actionDateFilters = [];
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    this.actionDateFilters.push(start);
    // console.log("Action date filter 1 : ", this.actionDateFilters);
    if (number > 0) {

      this.actionDateFilters.push(moment(start).add(number, 'days').toDate());
    } else {
      this.actionDateFilters.unshift(moment(start).add(number, 'days').toDate())
    }
// console.log(this.table.filters)
    // console.log("Action date filter 2 : ", this.actionDateFilters)

  }

  createdDateCustomFilter($event: MouseEvent, number: number) {

    // //console.log(this.actionDateFilters);
    this.dateFilters = [];
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    this.dateFilters.push(start);
    // console.log("Action date filter 1 : ", this.actionDateFilters);
    if (number > 0) {

      this.dateFilters.push(moment(start).add(number, 'days').toDate());
    } else {
      this.dateFilters.unshift(moment(start).add(number, 'days').toDate())
    }
// console.log(this.table.filters)
    // console.log("Action date filter 2 : ", this.actionDateFilters)

  }

  lastCalenderMonth($event: MouseEvent, startMonth: number, endMonth: number) {

    // //console.log(this.actionDateFilters);
    this.dateFilters = [];
    let prevMonthFirstDay = moment().subtract(startMonth, 'months').startOf('month').toDate();
    // The filter adds 1 ms short of a day to the end date, so need to be at the start of the end date, else go into the following day
    let nextMonthLastDay = moment().subtract(endMonth, 'months').endOf('month').startOf('day').toDate();
    this.dateFilters.push(prevMonthFirstDay, nextMonthLastDay);
  }

  clearDateFilters(filterName: string) {
    const filterDefinition: FilterMetadata = this.table.filters[filterName] as FilterMetadata;
    if (filterDefinition) {
      this.table.filter(null, filterName, filterDefinition.matchMode);
    }
  }

  ngOnDestroy() {
    this.closing = true;
    this.orderUpdatesSocket.removeAllListeners();
    this.locksSocket.removeAllListeners();
  }

  globalFilter($event: Event, filterType: string): void {
    this.table.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.table.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  getFilterValue(field: string): string {
    if (!this.table.filters[field]) {
      return '';
    }
    if (this.table.filters[field] instanceof Array) {
      const filterMetadataArray: FilterMetadata[] = (this.table.filters[field] as FilterMetadata[]);
      if (filterMetadataArray.length > 0){
        return filterMetadataArray[0].value;
      }
      return '';
    }
    return (this.table.filters[field] as FilterMetadata).value;
  }

  promptForSetName() {
    this.showColumSetDialog = true;
  }

  closeColumnSetDialog() {
    const colSet: SelectItem<Column[]> = this.savedColumnSets.find((colSet: SelectItem<Column[]>) =>
      colSet.label == this.columnSetName
    );
    if (colSet) {
      this.showErrorPrompt('Name must be unique', 'You must specify a name you have not already used');
    } else {
      this.saveColumns(this.columnSetName, true);
      this.showColumSetDialog = false;
    }
  }

  saveColumns(setName: string, newName: boolean) {
    this.isSaveColumnDisabled = true;
    // Update local storage with their new choices
    if (setName == 'Default') {
      localStorage.setItem('defaultColumns', JSON.stringify(this.selectedColumns));
      this.defaultColumns = this.selectedColumns;
    } else {
      if (newName) {
        this.savedColumnSets.push({
          label: setName,
          value: this.selectedColumns
        });
        this.savedColumnSets.sort((setA: SelectItem<Column[]>, setB: SelectItem<Column[]>) =>
          setA.label.localeCompare(setB.label)
        );
      } else {
        const colSet: SelectItem<Column[]> = this.savedColumnSets.find((colSet: SelectItem<Column[]>) =>
          colSet.label == setName
        );
        if (colSet) {
          colSet.value = this.selectedColumns;
        }
      }
      localStorage.setItem('savedColumnSets', JSON.stringify(this.savedColumnSets));
    }

    this.userService.saveColumns({
      userId: localStorage.getItem('userId'),
      defaultColumns: this.defaultColumns,
      savedColumnSets: this.savedColumnSets.map<SelectItem<string[]>>((colSet: SelectItem<Column[]>) => {
        return {
          label: colSet.label,
          value: colSet.value.map((col: Column) => col.field)
        }
      }),
    }).subscribe({
      'next': (response: SimpleResponse) => {
        this.isSaveColumnDisabled = false;
        if (response.success) {
          this.showSuccess();
        } else {
          this.showErrorPrompt('Error saving column sets', `Error saving column sets. ${response.message}`);
          console.log('Error on saving columns sets. Error:', response.message);
        }
      }, 
      'error': (err: Error) => {
        this.isSaveColumnDisabled = false;
        this.showErrorPrompt('Error saving column sets', `Error saving column sets. Error: ${err.message}`);
        console.log('Error on saving columns sets. Error: ', err);
      }
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: "Changes Successfuly Applied"
    });
  }

  showErrorPrompt(header: string, message: string) {
    this.confirmationService.confirm({
      header: header,
      message: message,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'OK',
      rejectVisible: false,
      accept: () => {},
    });
  }

  toggleSelectAll() {
    this.selectedOrders = {};
    this.numberOfSelectedOrders = 0;
    if (this.selectAllChecked) {
      const ordersToSelect: HomepageFormatOrder[] = (this.table.filteredValue == null)? this.table.value: this.table.filteredValue;
      ordersToSelect.forEach((order: HomepageFormatOrder) => {
        this.numberOfSelectedOrders++;
        this.selectedOrders[order._id] = order;
      });
    }
  }
  
  toggleSelect(order: HomepageFormatOrder) {
    // Check box change has already updated selected orders, so this looks like it's the wrong way around, but it isn't
    if (!this.selectedOrders[order._id]) {
      this.selectAllChecked = false;
      this.numberOfSelectedOrders--;
      delete this.selectedOrders[order._id];
    } else {
      this.numberOfSelectedOrders++;
      const filteredOrders: HomepageFormatOrder[] = (this.table.filteredValue == null)? this.table.value: this.table.filteredValue;
      if (filteredOrders.length <= this.numberOfSelectedOrders) {
        // have to check all selected
        this.selectAllChecked = !filteredOrders.some((order: HomepageFormatOrder) => (this.selectedOrders[order._id] == null));
      }
    }
  }
}
