import {ActivatedRoute} from '@angular/router';
import {HomeOutboundService} from './../home-outbound.service';
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {BrandCfg, getBrandConfigs, getBrandColour, getBrandBackgroundColour} from '../../lookups/brands';
import {getStatusColour, getStatusBackgroundColour, statusSelectItems, statusConfigs, StatusCfg} from '../../lookups/statuses';
import {getActionColour, getActionBackgroundColour, getActionConfigs, getAllowDueDateFilterOnCC} from '../../lookups/actions';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {OrderUpdatesSocketService} from '../../sockets/order-updates-socket.service';
import {OrderUpdate} from '../../models/socket-io/orderUpdate.model';
import {FilterService, SelectItem, SortMeta} from 'primeng/api';
import moment from 'moment-timezone';
import {Order, OrderTag} from '../../models/order.model';
import {ActionListPageEntry} from '../../models/actionListPageEntry.model';
import {OutstandingAction} from '../../models/outstandingAction.model';
import {ActionListResponse} from '../../models/responses/actionListResponse.model';
import {Table} from 'primeng/table';
import {Title} from '@angular/platform-browser';
import {OrderLockData} from '../../models/socket-io/orderLockData.model';
import {Column} from '../../models/column.model';
import {dateRangeFilters} from '../../helpers/tableDateRangeFilters';
import {ExcelExportService} from '../../post-order/excel-export.service';

enum ExportOptions {
  ALL = 'All',
  FILTERED = 'Filtered',
}

@Component({
  selector: 'app-outstanding-actions-list',
  templateUrl: './outstanding-actions-list.component.html',
  styleUrls: ['./outstanding-actions-list.component.scss']
})
export class OutstandingActionsListComponent implements OnInit, OnDestroy{

  constructor(
    private homeOutboundService: HomeOutboundService,
    private route: ActivatedRoute,
    private locksSocket: LocksSocketService,
    private orderUpdatesSocket: OrderUpdatesSocketService,
    private title: Title,
    private filterService: FilterService,
    private excelService: ExcelExportService,
  ) { 
  }
  ExportOptions = ExportOptions;
  cols: Column[] = [
    { field: 'actionDueDate', header: 'ACTION DATE' },
    { field: 'actionInitiatedDate', header: 'ACTION INITIATED' },
    { field: 'brand', header: 'BRAND' },
    { field: 'brandOrder', header: 'BRAND ORDER', hide: true },
    { field: 'orderDate', header: 'ORDER DATE' },
    { field: 'planType', header: 'PLAN TYPE' },
    { field: 'tdCode', header: 'TD CODE' },
    { field: 'status', header: 'STATUS' },
    { field: 'customerName', header: 'CUSTOMER NAME' },
    { field: 'backgroundAutoTestCall', header: 'BACKGROUND AUTOMATED TEST CALL' },
    { field: 'lastManualActivationTime', header: 'LAST MANUAL ACTIVATION' },
    { field: 'tagNames', header: 'TAGS' }
  ];
  searchableFields = this.cols.map((element: Column) => element.field);
  outstandingActions: ActionListPageEntry[] = [];
  brands: SelectItem<string>[] = [];
  selectedBrands: string[] = [];
  locked: OrderLockData[] = [];
  brandConfigs: BrandCfg;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  getActionColour = getActionColour;
  getActionBackgroundColour = getActionBackgroundColour;
  getBrandColour = getBrandColour;
  getBrandBackgroundColour = getBrandBackgroundColour;
  loading: boolean = true;
  actionType: string = "";
  shift: string = "";
  startTime: number;
  endTime: number;
  multiSortMeta: SortMeta[];
  @ViewChild('outstandingActionsTableRef', {static: false})
  outstandingActionTable: Table;
  first: number;
  updateReceived: boolean;
  statuses: SelectItem<string>[] = statusSelectItems;
  selectedStatus: string[] = [];
  readonly statusConfigs: StatusCfg = statusConfigs;
  displayOtherOutstandingActions: ActionListPageEntry = null;
  otherOutstandingActions = [];
  showStatus: boolean;
  dueDateFilterAllowed: boolean;
  dueDateFilters: Date[];
  filterYearRange: string;

  ngOnInit() {
    this.first = 0;
    this.updateReceived = false;
    this.multiSortMeta = [];
    this.multiSortMeta.push({field: 'brandOrder', order: 1});
    this.multiSortMeta.push({field: 'actionDueDate', order: 1});
    this.multiSortMeta.push({field: 'actionInitiatedDate', order: 1});
    this.filterYearRange = `2014:${moment.tz('Europe/London').add(1, 'year').get('year')}`;
    this.outstandingActions = [];
    this.brandConfigs = getBrandConfigs();
    this.actionType = this.route.snapshot.paramMap.get('actionType');
    this.shift = this.route.snapshot.paramMap.get('shift');
    this.dueDateFilterAllowed = getAllowDueDateFilterOnCC(this.actionType);
    const statusLabel: string = 
      getActionConfigs()[this.actionType].statusLabel? getActionConfigs()[this.actionType].statusLabel.toLocaleLowerCase() : '';
    this.showStatus = statusLabel.includes('type') || statusLabel.includes('status');
    this.preparePageData();
    this.orderUpdatesSocket.on('updateOrders', this.processUpdateFromSocketIo);
    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      this.locked = data;
    });
    this.locksSocket.emit('getLocked');
    this.title.setTitle('CRM ' + this.actionType);
    let self = this;
    this.filterService.register('actionDueDate', (value: any, filter: any): boolean => {
      return dateRangeFilters(value, filter, self.dueDateFilters);
    });
  }

  ngOnDestroy() {
    this.orderUpdatesSocket.removeAllListeners();
    this.locksSocket.removeAllListeners();
  }

  get showOtherOutstandingActions(): boolean {
    return (this.displayOtherOutstandingActions != null);
  }

  clearDueDateFilters() {
    delete this.outstandingActionTable.filters['dateLogged'];
    let filters: any = { ...this.outstandingActionTable.filters };
    this.outstandingActionTable.reset();
    for (const key in filters) {
      this.outstandingActionTable.filter(filters[key].value,key,filters[key].matchMode)
    }
  }

  processUpdateFromSocketIo: (orderUpdateData: OrderUpdate) => void = (orderUpdate: OrderUpdate) => {
    const existingRowRecord: ActionListPageEntry = this.outstandingActions.find((row: ActionListPageEntry): boolean => 
      row.orderId === orderUpdate.order._id
    );
    const actionOnOrder: OutstandingAction = orderUpdate.order.outstandingActions.find((action: OutstandingAction) => 
      action.outstandingName === this.actionType
    );
    let orderShouldBeOnPage: boolean = true;
    if (!actionOnOrder) {
      orderShouldBeOnPage = false;
    } else if (this.shift === 'total') { 
      // If shift is total all actions of this type should be shown
      orderShouldBeOnPage = true;
    } else  {
      const actionDue: number = new Date(actionOnOrder.renewalDateTaken).getTime();
      if (actionDue > this.endTime) {
        // Not valid if the due date is after the end time
        orderShouldBeOnPage = false;
      } else if ((!!this.startTime) && (actionDue < this.startTime)) {
        // Overdue has no start time, so only check if startTime set
        orderShouldBeOnPage = false;
      }
    }

    if (existingRowRecord) {
      if (!orderShouldBeOnPage) {
        this.updateReceived = true;
        this.first = this.outstandingActionTable.first;
        // Order should no longer be on this page, so filter it out
        this.outstandingActions = this.outstandingActions.filter((row: ActionListPageEntry): boolean => 
          row.orderId !== orderUpdate.order._id
        );
      } else {
        this.updateReceived = true;
        this.first = this.outstandingActionTable.first;
        // Update the details on the page
        this.outstandingActions = this.outstandingActions.map((row: ActionListPageEntry): ActionListPageEntry => {
          if (row.orderId === orderUpdate.order._id) {
            return this.getRowDataFromOrder(orderUpdate.order, actionOnOrder);
          } else {
            return row;
          }
        });
      }
    } else if (!actionOnOrder) {
      // Nothing to do, order is not already on this page and shouldn't be
      return;
    } else if (orderShouldBeOnPage) {
      this.updateReceived = true;
      this.first = this.outstandingActionTable.first;
      this.outstandingActions = this.outstandingActions.concat([this.getRowDataFromOrder(orderUpdate.order, actionOnOrder)]);
    }
  }

  getRowDataFromOrder(order: Order, actionData: OutstandingAction): ActionListPageEntry {
    let lastManualActivationTimeDays: number = null
    let backgroundAutoTestCallDays: number = null
    let lastManualActivationTimeColor: string = "white";
    let backgroundAutoTestCallColor: string = "white"; 
    let lastManualActivationMoment: moment.Moment = undefined;
    const todayDate: moment.Moment = moment();
    if (order.status) {
      if (order.status.lastBoxActivation) {
        lastManualActivationMoment = moment(order.status.lastBoxActivation);
      }
      if (order.status.pendantOneLastActivation) {
        const lastActivation = moment(order.status.pendantOneLastActivation);
        if (!lastManualActivationMoment || (lastManualActivationMoment.isBefore(lastActivation))) {
          lastManualActivationMoment = lastActivation;
        }
      }
      if (order.status.pendantTwoLastActivation) {
        const lastActivation = moment(order.status.pendantTwoLastActivation);
        if (!lastManualActivationMoment || (lastManualActivationMoment.isBefore(lastActivation))) {
          lastManualActivationMoment = lastActivation;
        }
      }
      if (!!lastManualActivationMoment) {
        lastManualActivationTimeDays = todayDate.diff(lastManualActivationMoment, 'days');
      } 
      if (order.status.backgroundAutoTestCall) {
        const backgroundAutoTestCallDate = moment(order.status.backgroundAutoTestCall);
        backgroundAutoTestCallDays = todayDate.diff(backgroundAutoTestCallDate, 'days');
      }
    }
    const data: ActionListPageEntry = {
      'orderId': order._id,
      'actionDueDate': actionData.renewalDateTaken,
      'actionInitiatedDate': actionData.actionInitiatedDate,
      'actionStatus': actionData.status,
      'tdCode': order.alarmUserDetails.tdCode,
      'status': order.status.status,
      'customerName': order.alarmUserDetails.firstName + ' ' + order.alarmUserDetails.lastName,
      'backgroundAutoTestCall': order.status? order.status.backgroundAutoTestCall: null,
      'lastManualActivationTime': lastManualActivationMoment? lastManualActivationMoment.toISOString(): null,
      'lastManualActivationTimeColor': 'white',
      'backgroundAutoTestCallColor': 'white',
      'brand': order.website.title,
      'brandOrder': order.website.order,
      'outstandingColor': actionData.outstandingColor,
      'orderDate': order.created,
      'planType': order.accountDetails.planType,
      'tags': order.tags.map((tag: OrderTag)  => {
        return {
          'tagName': tag.tagID.tagName,
          'color': tag.tagID.color
        }
      }),
      'tagNames': order.tags.map((tag: OrderTag) => tag.tagID.tagName),
    };
    if (lastManualActivationTimeDays > 60 && lastManualActivationTimeDays <= 90) {
      lastManualActivationTimeColor = "orange";
    } else if (lastManualActivationTimeDays > 90) {
      lastManualActivationTimeColor="red"
    }

    if (backgroundAutoTestCallDays > 60 && backgroundAutoTestCallDays <= 90) {
      backgroundAutoTestCallColor = "orange"
    } else if (backgroundAutoTestCallDays > 90) {
      backgroundAutoTestCallColor = "red"
    }
    data['lastManualActivationTimeColor'] = lastManualActivationTimeColor;
    data['backgroundAutoTestCallColor'] = backgroundAutoTestCallColor;
    return data;
  }

  preparePageData() {
    this.homeOutboundService.getDataByActionAndShift({ actionType: this.actionType, shift: this.shift })
      .subscribe((response: ActionListResponse) => {
        this.loading = false;
        if (response.success) {
          this.startTime = !!response.data.start? new Date(response.data.start).getTime(): null;
          this.endTime = !!response.data.end? new Date(response.data.end).getTime(): null;
          this.outstandingActions = response.data.actionOrderData;
          this.brands = response.data.brands;
        }
      });
  }

  getOtherOutstandingActions(rowData: ActionListPageEntry) {
    // console.log("ROW DATA ::", rowData);
    this.homeOutboundService.getOutstandingActionOnSameOrder({
      orderId: rowData.orderId,
      actionName: this.actionType
    }).subscribe((response: any) => {
      // console.log("Response :: ", response);
      if (response.data) {
        this.displayOtherOutstandingActions = rowData;
        this.otherOutstandingActions = response.data.outstandingActions
      } else {
        window.open('/order/'+rowData.orderId,'_blank');
      }
    }, (err: Error) => {
      console.log("ERRor::",err);
    })
  }

  navigateToPostOrder() {
    const orderId = this.displayOtherOutstandingActions.orderId;
    this.displayOtherOutstandingActions = null;
    window.open('/order/'+orderId,'_blank');
  }

  isOrderLocked(_id: string) {
    return this.locked.filter(lock => lock.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string) {
    return this.locked.filter(lock => lock.orderId == _id)[0].user;
  }

  onFilter($event: any): void {
    // If the re-run of the filter is triggered by an update want to go back to the page we were on
    if (this.updateReceived) {
      this.outstandingActionTable.first = Math.min(this.first, $event.filteredValue.length);
      // reset the flag
      this.updateReceived = false;
    }
  }

  globalFilter($event: Event, filterType: string): void {
    this.outstandingActionTable.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  export(exportOption: ExportOptions): void {
    let recordsToExport: ActionListPageEntry[];
    if (ExportOptions.ALL == exportOption) {
      recordsToExport = this.outstandingActions;
    } else if (ExportOptions.FILTERED == exportOption) {
      recordsToExport = this.outstandingActionTable.filteredValue? this.outstandingActionTable.filteredValue: this.outstandingActions;
    }
    const formattedForExport: any[] = recordsToExport.map((actionListPageEntry: ActionListPageEntry) => {
      return {
        'Action': this.actionType,
        'Action Due Date': actionListPageEntry.actionDueDate? moment.tz(actionListPageEntry.actionDueDate, 'Europe/London').toDate(): '',
        'Action Initiated Date': actionListPageEntry.actionInitiatedDate? moment.tz(actionListPageEntry.actionInitiatedDate, 'Europe/London').toDate(): '',
        'Action Status': actionListPageEntry.actionStatus? actionListPageEntry.actionStatus: '',
        'Brand': actionListPageEntry.brand,
        'Order Date': moment.tz(actionListPageEntry.orderDate, 'Europe/London').startOf('day').toDate(),
        'Plan Type': actionListPageEntry.planType,
        'Td Code': actionListPageEntry.tdCode,
        'Order Status': actionListPageEntry.status,
        'Customer Name': actionListPageEntry.customerName,
        'Last Background Auto Test': actionListPageEntry.backgroundAutoTestCall?
          moment.tz(actionListPageEntry.backgroundAutoTestCall, 'Europe/London').toDate(): '',
        'Last Manual Activation': actionListPageEntry.lastManualActivationTime?
          moment.tz(actionListPageEntry.lastManualActivationTime, 'Europe/London').toDate(): '',
        'Tags': actionListPageEntry.tagNames.join('|'),        
      }
    });
    this.excelService.exportAsExcelFile({'actions': formattedForExport}, `${exportOption}_${this.actionType}`);
  }
}
