import {SelectItem} from 'primeng/api';

const renewalTypeSelectItems: SelectItem<string>[] = [
  {label: '', value: ''},
  {label: 'Direct Debit', value: 'directDebit', disabled: true},
  {label: 'Recurring Billing', value: 'recurringBilling'},
  {label: 'Go Cardless', value: 'goCardless', disabled: true},
  {label: 'goCardless - No Subscription', value: 'goCardless - No Subscription'},
  {label: 'goCardless - Subscription', value: 'goCardless - Subscription'},
  {label: 'Standing Order', value: 'standing order'},
];

// This strips off the disabled flag, so all items can be selected on filters
const renewalTypeForFilters: SelectItem<string>[] = renewalTypeSelectItems.map((select: SelectItem<string>) => {
  return {
    label: select.label,
    value: select.value,
  }
});

// This is to convert the DB value to display value
const renewalTypeDisplayLookup: {[lookupName: string]: string} = {};

renewalTypeSelectItems.forEach((renewalType: SelectItem<string>) => {
  renewalTypeDisplayLookup[renewalType.value] = renewalType.label;
});

export {
  renewalTypeSelectItems,
  renewalTypeDisplayLookup,
  renewalTypeForFilters,
}
