import moment from 'moment-timezone';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseOrderInformation} from '../../models/cseOrder/baseOrderInformation.model';
import {BusinessGrowthCustomer} from '../../models/businessGrowthCustomer.model';
import {ConfirmationService, OverlayOptions, SelectItem} from 'primeng/api';
import {numberOnly, phoneNumberOnly} from '../../helpers/keyboardHelpers';
import {ProcessingSteps} from '../../models/cseOrder/processingSteps.model';
import {ProductsService} from '../../setup/products/products.service';
import {CouponsService} from '../../setup/coupons/coupons.service';
import {OrderService} from '../../post-order/order.service';
import {NotificationService} from '../../notifications/notification.service';
import {Router} from '@angular/router';
import {shopifyVatStatuses} from '../../lookups/vatStatuses';
import {OrderVatStatus} from '../../lookups/cseOrder/orderVatStatus';
import {getHowHeardOptions} from '../../lookups/howHeard';
import {getShopifyProcessingSteps} from '../../lookups/cseOrder/shopify/processingSteps';
import {SHOPIFY_PRODUCT_CATEGORY_OPTS} from '../../lookups/productCategories';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {PopulatedShopifyVariant, RawShopifyProduct, RawShopifyVariant, ShopifyVariantComponent} from '../../models/shopifyProduct.model';
import {ShopifyDiscount} from '../../models/shopifyDiscount.model';
import {CalculationMethod, OrderType} from '../../lookups/cseOrder/cseOrderTypes';
import {ShopifyBasketItem} from '../../models/cseOrder/shopify/shopifyBasketItem.model';
import {ShopifyRestCustomer} from '../../models/cseOrder/shopify/shopifyCustomer.model';
import {CustomerEmailMarketingState, CustomerMarketingOptInLevel, CustomerSmsMarketingState, shopifyEmailOptInSelects, shopifySmsOptInSelects} from '../../lookups/cseOrder/shopify/marketing';
import {OrderMetadata} from '../../models/cseOrder/shopify/orderMedatadata.model';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {Address, AlarmUserAddress} from '../../models/address.model';
import {environment} from '../../../environments/environment';
import {getLookupFromGetAddressResult, validateAddress} from '../../helpers/getAddressHelper';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {ShopifyRestOrder} from '../../models/cseOrder/shopify/shopifyRestOrder.model';
import {ProcessingError} from '../../lookups/cseOrder/processingError';
import {crmDelay, EX_TO_INC_VAT_MULTIPLIER, floorToTwoDecimalPlaces, getTitleFromProductAndVariant, roundToTwoDecimalPlaces, VAT_MULTIPLIER} from '../../helpers/helperFunctions';
import {formatPhoneNumberInE164Format, isValidAnyCountryPhoneNumber} from '../../validators/phone-number.validator';
import {AdditionalOrReplacementEquipment, KeySafe, Order, Plan} from '../../models/order.model';
import {loadStripe, PaymentMethodResult, Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeCardElementOptions, StripeElements, StripeError} from '@stripe/stripe-js';
import ServerSideStripe from 'stripe';
import {isValidEmailAddress} from '../../validators/email.validator';
import {CseOrder, CseOrderExistingActiveItems} from '../../models/reporting/cseOrder.model';
import {Website} from '../../models/website.model';
import {StringIndexedObject} from '../../models/utility/stringIndexedObject.model';
import {CreatePaymentIntentRequest} from '../../models/requests/createPaymentIntentRequest.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {STRIPE_ACCOUNTS} from '../../lookups/stripeAccounts';
import {ExternalId} from '../../models/externalId.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {ShopifyRestOrderLine} from '../../models/cseOrder/shopify/shopifyRestOrderLine.model';
import {OrderResponse} from '../../models/responses/orderResponse.model';
import {convertHardwareToSets, getPlanCodeAndPrice, getPriceForOrderDate, PRICE_REGEX} from '../../helpers/getPlanCodeAndPrice';
import {HardwareService} from '../../setup/hardware.service';
import {Hardware} from '../../models/hardware.model';
import {HardwareSet} from '../../models/hardwareSet.model';
import {HardwareAndSets} from '../../models/responses/functionReturns/hardwareAndSets.model';
import {FindOrderResponse} from '../../models/responses/findOrderResponse.model';
import {DD_GUARANTEE} from '../../lookups/cseOrder/ddGuarantee';
import {hardwareStatusConfig} from '../../lookups/statuses';
import {getHardwareNameFromSerial} from '../../lookups/hardwareBrandsOrTypes';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';
import {PlanCodeAndPrice} from '../../models/planCodeAndPrice.model';

@Component({
  selector: 'app-shopify-order[baseOrderInformation][partnerships][partnershipByVoucherCode][updateShowPlaceOrder]',
  standalone: false,
  templateUrl: './shopify-order.component.html',
  styleUrls: ['../cse-order.component.scss', './shopify-order.component.scss'],
})
export class ShopifyOrderComponent implements OnInit, AfterViewInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Input() baseOrderInformation: BaseOrderInformation;
  @Input() partnerships: SelectItem<BusinessGrowthCustomer>[];
  @Input() partnershipByVoucherCode: {[code: string]: BusinessGrowthCustomer};
  @Output() updateShowPlaceOrder: EventEmitter<boolean> = new EventEmitter<boolean>();
  Object = Object;
  numberOnly = numberOnly;
  phoneNumber = phoneNumberOnly;
  OrderVatStatusEnum = OrderVatStatus;
  readonly ORDER_CREATION_STEP: string = 'Creating Order on Alarm Website';
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;
  vatOptions: SelectItem<boolean>[];
  vatSelected: boolean;
  category: string;
  categories: SelectItem<string>[];
  notExemptCategories: SelectItem<string>[];
  exemptCategories: SelectItem<string>[];
  cseProducts: RawShopifyProduct[];
  productMap: StringIndexedObject<RawShopifyProduct>;
  variantMap: StringIndexedObject<PopulatedShopifyVariant>;
  setupFees: RawShopifyProduct[];
  freePostLabelProduct: RawShopifyProduct;
  paperNcfProduct: RawShopifyProduct;
  paperNcfRequired: string;
  hardwareMapById: StringIndexedObject<Hardware>;
  hardwareMapByName: StringIndexedObject<Hardware>;
  hardwareSelectList: SelectItem<string>[];
  hardwareSets: HardwareSet[];
  productsFiltered: RawShopifyProduct[];
  selectedProduct: RawShopifyProduct;
  variantsFiltered: RawShopifyVariant[];
  selectedVariant: RawShopifyVariant;
  orderVatStatus: OrderVatStatus;
  orderRenewalPeriod: string;
  orderBeingCreated: boolean;
  shopifyItems: ShopifyBasketItem[];
  userName: string;
  howHeardOptions: SelectItem<string>[];
  vatTotalPreDiscount: number;
  vatTotalAfterDiscount: number;
  orderTotal: number;
  totalBeforeDiscountAndOverride: number;
  discountAmount: number;
  hasRentalItems: boolean;
  discountCode: string;
  discountCodeError: string;
  calculationErrors: string[];
  replacementEquipmentErrors: string[];
  totalOverridden: boolean;
  lifetimeLineNeedsOverride: boolean;
  currencySymbol: string;
  currencyCode: string;
  discounts: ShopifyDiscount[];
  discount: Partial<ShopifyDiscount>;
  selectedPartnership: BusinessGrowthCustomer;
  validationErrors: string[];
  shopifyCustomer: ShopifyRestCustomer;
  shopifyOrder: ShopifyRestOrder;
  alarmUserAddress: AlarmUserAddress;
  alarmUserAddressOption: string;
  alarmUserNameOption: string;
  alarmUserPhoneOption: string;
  orderMetadata: OrderMetadata;
  ddMessage: string;
  getAddrClient: getAddressClient;
  billingSearchPostCode: string;
  deliverySearchPostCode: string;
  alarmUserSearchPostCode: string;
  billingAddressResults: SelectItem<Address>[];
  deliveryAddressResults: SelectItem<Address>[];
  alarmUserAddressResults: SelectItem<Address>[];
  allowBillingAddressManualEntry: boolean;
  allowDeliveryAddressManualEntry: boolean;
  allowAlarmUserAddressManualEntry: boolean;
  billingSearchError: string;
  deliverySearchError: string;
  alarmUserSearchError: string;
  differentDeliver: boolean;
  emailOptInSelects: SelectItem<CustomerEmailMarketingState>[];
  smsOptInSelects: SelectItem<CustomerSmsMarketingState>[];
  processingSteps: ProcessingSteps;
  showProgressBar: boolean;
  currentStep: string;
  orderCreationSuccess: boolean;
  processingError: ProcessingError;
  displayErrorDetails: boolean;
  orderLink: string;
  crmOrderLink: string;
  orderDateYMD: string;
  recentOrder: boolean;
  stripeBrand: string;
  stripe: Stripe;
  cardElement: StripeCardElement;
  cardHandler = this.onChange.bind(this);
  stripeCardError: string;
  baseUnitTypes: string[];
  referralTdFound: boolean;
  referralTDCode: string;
  referralTdSearched: boolean;
  alarmUserAddressRequired: boolean;

  constructor(
    private productsService: ProductsService,
    private couponsService: CouponsService,
    private changeDetector: ChangeDetectorRef,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private router: Router,
    private hardwareService: HardwareService,
  ) {

  }

  ngOnInit(): void {
    this.paperNcfRequired = 'Yes';
    this.ddMessage = '';
    this.vatOptions = shopifyVatStatuses;
    this.emailOptInSelects = shopifyEmailOptInSelects;
    this.smsOptInSelects = shopifySmsOptInSelects;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.showProgressBar = false;
    this.processingError = null;
    this.displayErrorDetails = false;
    this.vatSelected = null;
    this.category = null;
    this.categories = [];
    this.cseProducts = [];
    this.productMap = {};
    this.variantMap = {};
    this.hardwareMapById = {};
    this.hardwareMapByName = {};
    this.hardwareSelectList = [];
    this.hardwareSets = [];
    this.productsFiltered = [];
    this.variantsFiltered = [];
    this.selectedProduct = null;
    this.selectedVariant = null;
    this.orderRenewalPeriod = null;
    this.orderBeingCreated = false;
    this.shopifyItems = [];
    this.vatTotalPreDiscount = 0;
    this.vatTotalAfterDiscount = 0;
    this.orderTotal = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.discountAmount = 0;
    this.hasRentalItems = false;
    this.discountCode = '';
    this.discountCodeError = '';
    this.calculationErrors = [];
    this.replacementEquipmentErrors = [];
    this.totalOverridden = false;
    this.lifetimeLineNeedsOverride = false;
    this.processingSteps = getShopifyProcessingSteps();
    this.orderCreationSuccess = false;
    this.notExemptCategories = SHOPIFY_PRODUCT_CATEGORY_OPTS;
    this.exemptCategories = this.notExemptCategories.filter((selectItem: SelectItem<string>) =>
      (selectItem.value != 'Key Safes')
    );
    this.userName = localStorage.getItem('userName');
    this.howHeardOptions = getHowHeardOptions();
    this.selectedPartnership = undefined;
    this.validationErrors = [];
    this.orderLink = '';
    this.crmOrderLink = '';
    this.referralTdFound = false;
    this.referralTDCode = '';
    this.referralTdSearched = false;
    this.alarmUserAddressRequired = false;
    this.shopifyCustomer = {
      'first_name': '',
      'last_name': '',
      'phone': '',
      'email': '',
      'send_email_invite': false,
      'emailMarketingConsent': {
        'marketingOptInLevel': CustomerMarketingOptInLevel.SINGLE_OPT_IN,
        'marketingState': null,
        'consentUpdatedAt': this.baseOrderInformation.currentMoment.toDate(),
      },
      'smsMarketingConsent': {
        'marketingOptInLevel': CustomerMarketingOptInLevel.SINGLE_OPT_IN,
        'marketingState': null,
        'consentUpdatedAt': this.baseOrderInformation.currentMoment.toDate(),
      },
      'addresses': [],
    };
    this.alarmUserAddress = {
      'addressOne': '',
      'addressTwo': '',
      'city': '',
      'county': '',
      'postcode': '',
      'validated': false,
      'unknown': false,
    };
    this.orderMetadata = {
      'billingAddressValidated': false,
      'shippingAddressValidated': false,
      'alarmUserFirstName': '',
      'alarmUserLastName': '',
      'alarmUserMobile': '',
      'alarmUserPhone': '',
      'paymentMethod': '',
      'nameOnCard': '',
      'renewalMethod': 'N/A',
    };
    this.shopifyOrder = {
      'customer': {
        'id': '',
      },
      'billing_address': {
        'first_name': '',
        'last_name': '',
        'address1': '',
        'address2': '',
        'city': '',
        'province': '',
        'zip': '',
        'country_code': 'GB',
      },
      'shipping_address': {
        'first_name': '',
        'last_name': '',
        'address1': '',
        'address2': '',
        'city': '',
        'province': '',
        'zip': '',
        'country_code': 'GB',
      },
      'phone': '',
      'send_receipt': false,
      'line_items': [],
      'note_attributes': [],
      'discount_codes': [],
      'note': '',
      'financial_status': 'pending',
      'transactions': [],
      'total_tax': 0.00,
      'taxes_included': true,
      'tags': 'CRM',
    };
    this.differentDeliver = false;

    if (this.selectedWebsite.currencyCode) {
      this.currencyCode = this.selectedWebsite.currencyCode;
    } else {
      this.currencyCode = 'GBP';
    }
    if (this.currencyCode == 'GBP') {
      this.currencySymbol = '\u00A3';
    } else if (this.currencyCode == 'EUR') {
      this.currencySymbol = '\u20AC';
    }
    if (this.selectedWebsite.title == 'LLIE') {
      this.shopifyOrder.billing_address.country_code = 'IE';
      this.shopifyOrder.shipping_address.country_code = 'IE';
    }
    this.stripeBrand = this.selectedWebsite.title;
    if (this.order && (this.order.legalCompany == 'Lifeline24 Ltd')) {
      this.stripeBrand = `${this.selectedWebsite.title}_OLD`
    }
    this.discounts = [];
    this.discount = undefined;
    this.billingSearchPostCode = '';
    this.deliverySearchPostCode = '';
    this.alarmUserSearchPostCode = '';
    this.billingAddressResults = [];
    this.deliveryAddressResults = [];
    this.alarmUserAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.allowDeliveryAddressManualEntry = false;
    this.allowAlarmUserAddressManualEntry = false;
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    this.setupFees = [];

    this.productsService.getAllShopifyProductsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<RawShopifyProduct>) => {
        if (!response.success) {
          console.error('Error getting product data');
        } else {
          response.data.forEach((rawProduct: RawShopifyProduct) => {
            if (rawProduct.status == 'archived') {
              return;
            }
            if (rawProduct.displayOnCseOrderPage == 'yes') {
              this.cseProducts.push(rawProduct);
            }
            if (rawProduct.crmCategory == 'Setup Fees') {
              this.setupFees.push(rawProduct);
            }
            if (rawProduct.tags.includes('paper-ncf')) {
              this.paperNcfProduct = rawProduct;
            }
            if (rawProduct.tags.includes('free-post-label')) {
              this.freePostLabelProduct = rawProduct;
            }
            this.productMap[rawProduct.shopifyProductId] = rawProduct;
          });
        }
      },
      error: (err: any) => {
        console.error('Error getting product data', err);
      }
    });
    this.productsService.getAllShopifyVariantsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<PopulatedShopifyVariant>) => {
        if (!response.success) {
          console.error('Error getting variant data');
        } else {
          response.data.forEach((populatedVariant: PopulatedShopifyVariant) => {
            this.variantMap[populatedVariant.shopifyVariantId] = populatedVariant;
          });
        }
      },
      error: (err: any) => {
        console.error('Error getting variant data', err);
      }
    });

    this.hardwareService.getHardwareForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<Hardware>) => {
        if (!response.success) {
          console.error('Error getting hardware data');
        } else {
          response.data.forEach((hardware: Hardware) => {
            this.hardwareMapById[hardware._id] = hardware;
            this.hardwareMapByName[hardware.title] = hardware;
            if (!['Monitoring Only'].includes(hardware.category)) {
              this.hardwareSelectList.push({
                'label': hardware.title,
                'value': hardware._id,
              });
            }
          });
          this.initialiseActiveEquipment();
        }
      },
      error: (err: any) => {
        console.error('Error getting hardware data', err);
      }
    });

    this.hardwareService.getHardwareSetsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<HardwareSet>) => {
        if (response.success) {
          this.hardwareSets = response.data;
        }
      },
      error: (err: any) => {
        console.error('Error getting hardware set data', err);
      }
    });

    this.couponsService.getShopifyDiscounts(this.selectedWebsite._id).subscribe({
      next: (discountResponse: MultiRecordResponse<ShopifyDiscount>) => {
        this.discounts = discountResponse.data;
      },
      error: (err: any) => {
        console.error('Error getting discount data', err);
      }
    });
    this.baseUnitTypes = [];
    this.initialiseFromNavigationData();
    this.initialiseFromExistingOrder();
    this.showPlaceOrder = true;
  }

  onChange(event: StripeCardElementChangeEvent) {
    if (event.error) {
      this.stripeCardError = event.error.message;
    } else {
      this.stripeCardError = null;
    }
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    const stripeCardElementOptions: StripeCardElementOptions = {
      'style': {
        'base': {
          'fontFamily': 'monospace',
          'fontSmoothing': 'antialiased',
          'fontSize': '19px',
          '::placeholder': {
            'color': 'purple'
          }
        }
      }
    };
    loadStripe(environment.stripeKeys[this.stripeBrand]).then((tmpStripe: Stripe) => {
      this.stripe = tmpStripe;
      const stripeElements: StripeElements = this.stripe.elements();
      this.cardElement = stripeElements.create('card', stripeCardElementOptions);
      this.cardElement.mount(this.cardInfo.nativeElement);
      this.cardElement.on('change', this.cardHandler);
    }).catch((error: any) => {
      this.showErrorPopUp('Error Loading Stripe Card Entry',
        `Error loading the Stripe card entry please refresh the page to try again. Reason: ${error.message? error.message: 'unknown error'}`
      );
    });
  }

  initialiseFromNavigationData() {
    if (!this.baseOrderInformation.cseOrderNavigationData) {
      return;
    }
    this.alarmUserNameOption = 'other';
    this.alarmUserPhoneOption = 'other';
    this.alarmUserAddressOption = 'other';
    this.orderMetadata.alarmUserFirstName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.firstName;
    this.orderMetadata.alarmUserLastName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.lastName;
    this.orderMetadata.alarmUserPhone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone;
    this.orderMetadata.alarmUserMobile = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile;
    this.alarmUserAddress = {
      'addressOne': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne,
      'addressTwo': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo,
      'city': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.city,
      'county': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.county,
      'postcode': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.postcode,
      'validated': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.validated,
      'unknown': false,
    };
    this.customerFirstName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.firstName;
    this.customerLastName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.lastName;
    this.shopifyCustomer.email = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.email;
    if (this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile) {
      this.customerPhone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile;
    } else if (this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone) {
      this.customerPhone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone;
    }
    this.shopifyOrder.billing_address.address1 = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne;
    this.shopifyOrder.billing_address.address2 = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo;
    this.shopifyOrder.billing_address.city = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.city;
    this.shopifyOrder.billing_address.province = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.county;
    this.shopifyOrder.billing_address.zip = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.postcode;
    this.orderMetadata.billingAddressValidated = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.validated;
    this.validateBillingAddress(true);
  }

  getHardwareIdFromSerial(serial: string): string|undefined {
    const hardwareName: string = getHardwareNameFromSerial(serial);
    if (!hardwareName) {
      return undefined;
    }
    const hardware: Hardware|undefined = this.hardwareMapByName[hardwareName];
    if (hardware) {
      return hardware._id;
    }
    return undefined;
  }

  get activeItems(): CseOrderExistingActiveItems[] {
    return this.baseOrderInformation.cseOrder.existingActiveItems;
  }

  updatePlanCodeAndNewRenewalPrice(recalculatePrice: boolean) {
    this.replacementEquipmentErrors = [];
    if (!this.selectedOrderType || ![CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
      return;
    }
    const hardwareList: Hardware[] = [];
    const vatStatus: string = (this.orderVatStatus == OrderVatStatus.VATABLE)? 'not exempt': 'exempt';
    for (const currentItem of this.shopifyItems) {
      if (currentItem.shopifyProduct.isBundle) {
        currentItem.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
          const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
          // Use the hardware name as less likely to have been changed
          if (variant && variant.hardware) {
            const hardware: Hardware = this.hardwareMapById[variant.hardware._id];
            if (!hardware) {
              this.replacementEquipmentErrors.push(`Hardware not found for variant ${variant.crmVariantTitle}`);
            } else {
              hardwareList.push(hardware);
            }
          }
        });
      } else if (currentItem.shopifyVariant.hardware && currentItem.shopifyVariant.hardware._id) {
        const hardware: Hardware = this.hardwareMapById[currentItem.shopifyVariant.hardware._id];
        if (!hardware) {
          this.replacementEquipmentErrors.push(`nHardware not found for variant ${currentItem.shopifyVariant.crmVariantTitle}`);
        } else {
          hardwareList.push(hardware);
        }
      }
    }
    if ([CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
      const keptHardwareList: Hardware[] = [];
      // Need to add any kept items to the list of hardware
      for (const activeItem of this.activeItems) {
        if (activeItem.hardwareId && !activeItem.replacing) {
          const hardware: Hardware = this.hardwareMapById[activeItem.hardwareId];
          if (!hardware) {
            this.replacementEquipmentErrors.push(`Hardware not found for existing order item ${activeItem.nameOnOrder}`);
          } else {
            hardwareList.push(hardware);
            keptHardwareList.push(hardware);
          }
        }
      }
      const hardwareAndSets: HardwareAndSets = convertHardwareToSets(keptHardwareList, this.hardwareSets);
      // TODO add account services in if we launch F&F. For now deliberately want empty array as some live accounts have F&F set up for testing
      const planCodeAndPrice: PlanCodeAndPrice =
        getPlanCodeAndPrice(this.planType, vatStatus, this.orderDateYMD, hardwareAndSets.hardwareSets, hardwareAndSets.hardware, this.order.renewalDiscounts, []);
      // Need to update the discount based on proRataCurrentPrice and pro-rata value of what is being retained
      if (planCodeAndPrice.errors.length == 0) {
        const keptRenewalPrice: number = roundToTwoDecimalPlaces(Number(planCodeAndPrice.renewalPrice) * this.baseOrderInformation.proRataMultiplier);
        this.discount = {
          'code': 'Pro-rata rebate',
          'valueType': 'amount',
          'discountType': 'ORDER',
          'productIds': [],
          'variantProductIds': [],
          'collectionIds': [],
          'discount': roundToTwoDecimalPlaces(this.baseOrderInformation.cseOrder.proRataCurrentPrice - keptRenewalPrice),
          'oncePerOrder': false,
          'minimumOrderValue': 0,
          'shortSummary': 'Pro-Rata existing Plan Price (subtracted from new price)',
        };
      }
    } 
    
    const hardwareAndSets: HardwareAndSets = convertHardwareToSets(hardwareList, this.hardwareSets);
    // TODO add account services in if we launch F&F. For now deliberately want empty array as some live accounts have F&F set up for testing
    const planCodeAndPrice: PlanCodeAndPrice =
      getPlanCodeAndPrice(this.planType, vatStatus, this.orderDateYMD, hardwareAndSets.hardwareSets, hardwareAndSets.hardware, this.order.renewalDiscounts, []);
    // Need to update the discount based on proRataCurrentPrice and pro-rata value of what is being retained
    if (planCodeAndPrice.errors.length > 0) {
      this.replacementEquipmentErrors.push(`Error calculating new plan code and price. Errors: ${planCodeAndPrice.errors.join('; ')}`);
    } else if ([CalculationMethod.ADDITIONAL].includes(this.selectedOrderType.calculationMethod)) {
      let planCodeToAdd: string = planCodeAndPrice.planCode;
      // If existing plan code contains a V remove from the added code so we don't have multiple Vs
      if (this.baseOrderInformation.cseOrder.currentPlan.includes('V')) {
        planCodeToAdd = planCodeToAdd.replace(/V/g, '');
      }
      // Calculated plan code and price need adding to existing
      this.baseOrderInformation.cseOrder.newPlan = `${this.baseOrderInformation.cseOrder.currentPlan}+${planCodeToAdd}`;
      // Use baseRenewalPrice as it excludes discounts which are already factored into the renewal price being added to 
      this.baseOrderInformation.cseOrder.newRenewalPrice = 
        roundToTwoDecimalPlaces(this.baseOrderInformation.cseOrder.currentRenewalPrice + Number(planCodeAndPrice.baseRenewalPrice));
    } else {
      // New plan and renewal price come entirely from planCodeAndPrice
      this.baseOrderInformation.cseOrder.newPlan = planCodeAndPrice.planCode;
      this.baseOrderInformation.cseOrder.newRenewalPrice = Number(planCodeAndPrice.renewalPrice);
    }
    // Only need to recalculate price if this method was called due to change in equipment being replaced and the calculation method is DIFFERENCE
    // as replaced equipment changes affect the pro-rata discount applied for DIFFERENCE orders
    if ([CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod) && recalculatePrice) {
      this.priceupdate();
    }
  }

  initialiseActiveEquipment() {
    if (!this.order) {
      return;
    }
    this.baseOrderInformation.cseOrder.existingActiveItems = [];
    // Not generating existing active items for 'Additional' as no need to staff to select what is being kept
    if (!this.selectedOrderType ||
        ![CalculationMethod.FREE, CalculationMethod.LOST, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
      return;
    }
    if (this.order.plans && (this.order.plans.length > 0)) {
      this.order.plans.forEach((plan: Plan) => {
        if ((hardwareStatusConfig[plan.status].displaySection == 'active') ||
            ((CalculationMethod.LOST == this.selectedOrderType.calculationMethod) && (plan.status == 'Lost - Chasing'))) {
          let hardwareId: string = plan.baseUnitHardwareId;
          if (!hardwareId && plan.serial) {
            hardwareId = this.getHardwareIdFromSerial(plan.serial);
          }
          this.baseOrderInformation.cseOrder.existingActiveItems.push({
            'equipId': plan._id,
            'nameOnOrder': plan.equipment,
            'serial': plan.serial,
            'hardwareId': hardwareId,
            'source': 'planBase',
            'replacing': false,
          });
        }
        if ((hardwareStatusConfig[plan.serialStatus].displaySection == 'active') ||
            ((CalculationMethod.LOST == this.selectedOrderType.calculationMethod) && (plan.serialStatus == 'Lost - Chasing'))) {
          let hardwareId: string = plan.pendantHardwareId;
          if (!hardwareId && plan.serialPendant) {
            hardwareId = this.getHardwareIdFromSerial(plan.serialPendant);
          }
          this.baseOrderInformation.cseOrder.existingActiveItems.push({
            'equipId': plan._id,
            'nameOnOrder': plan.typePendant,
            'serial': plan.serialPendant,
            'hardwareId': hardwareId,
            'source': 'planPendant',
            'replacing': false,
          });
        }
      });
    }
    if (this.order.additionalEquipment && (this.order.additionalEquipment.length > 0)) {
      this.order.additionalEquipment.forEach((additionalEquipment: AdditionalOrReplacementEquipment) => {
        if ((hardwareStatusConfig[additionalEquipment.status].displaySection == 'active') ||
            ((CalculationMethod.LOST == this.selectedOrderType.calculationMethod) && (additionalEquipment.status == 'Lost - Chasing'))) {
          let hardwareId: string = additionalEquipment.hardwareId;
          if (!hardwareId && additionalEquipment.serial) {
            hardwareId = this.getHardwareIdFromSerial(additionalEquipment.serial);
          }
          this.baseOrderInformation.cseOrder.existingActiveItems.push({
            'equipId': additionalEquipment._id,
            'nameOnOrder': additionalEquipment.equipment,
            'serial': additionalEquipment.serial,
            'hardwareId': hardwareId,
            'source': 'additionalEquipment',
            'replacing': false,
          });
        }
      });
    }
    if (this.order.replacementEquipment && (this.order.replacementEquipment.length > 0)) {
      this.order.replacementEquipment.forEach((replacementEquipment: AdditionalOrReplacementEquipment) => {
        if ((hardwareStatusConfig[replacementEquipment.status].displaySection == 'active') ||
            ((CalculationMethod.LOST == this.selectedOrderType.calculationMethod) && (replacementEquipment.status == 'Lost - Chasing'))) {
          let hardwareId: string = replacementEquipment.hardwareId;
          if (!hardwareId && replacementEquipment.serial) {
            hardwareId = this.getHardwareIdFromSerial(replacementEquipment.serial);
          }
          this.baseOrderInformation.cseOrder.existingActiveItems.push({
            'equipId': replacementEquipment._id,
            'nameOnOrder': replacementEquipment.equipment,
            'serial': replacementEquipment.serial,
            'hardwareId': hardwareId,
            'source': 'replacementEquipment',
            'replacing': false,
          });
        }
      });
    }
    // Key Safes can be replaced due to missing/lost/faulty, but not for upgrades/downgrades
    if (![CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
      if (this.order.keySafes && (this.order.keySafes.length > 0)) {
        this.order.keySafes.forEach((keySafe: KeySafe) => {
          if (keySafe.status == 'Bought') {
            this.baseOrderInformation.cseOrder.existingActiveItems.push({
              'equipId': keySafe._id,
              'nameOnOrder': keySafe.equipment,
              'serial': '',
              'hardwareId': undefined,
              'source': 'keySafes',
              'replacing': false,
            });
          }
        });
      }
    }
  }

  initialiseFromExistingOrder() {
    if (!this.order) {
      return;
    }
    this.paperNcfRequired = 'No';
    this.baseOrderInformation.cseOrder.orderId = this.order._id;
    this.baseOrderInformation.cseOrder.tdCode = this.order.alarmUserDetails.tdCode;
    this.baseOrderInformation.cseOrder.currentPlan = this.order.accountDetails.plan;
    if (PRICE_REGEX.test(this.order.renewalInformation.renewalPrice)) {
      this.baseOrderInformation.cseOrder.currentRenewalPrice = roundToTwoDecimalPlaces(Number(this.order.renewalInformation.renewalPrice));
    }
    this.crmOrderLink = '/order/' + this.order['_id'];
    this.shopifyCustomer.email = this.order.alarmUserDetails.email;
    this.customerPhone = this.order.alarmUserDetails.mobile? this.order.alarmUserDetails.mobile: this.order.alarmUserDetails.telephone;
    this.customerFirstName = this.order.alarmUserDetails.firstName;
    this.customerLastName = this.order.alarmUserDetails.lastName;
    this.shopifyOrder.billing_address.address1 = this.order.alarmUserDetails.userAddress.addressOne;
    this.shopifyOrder.billing_address.address2 = this.order.alarmUserDetails.userAddress.addressTwo;
    this.shopifyOrder.billing_address.city = this.order.alarmUserDetails.userAddress.city;
    this.shopifyOrder.billing_address.province = this.order.alarmUserDetails.userAddress.county;
    this.shopifyOrder.billing_address.zip = this.order.alarmUserDetails.userAddress.postcode;
    this.orderMetadata.billingAddressValidated = this.order.alarmUserDetails.userAddress.validated;
    const createdMoment: moment.Moment = moment.tz(this.order.created, 'Europe/London');
    this.orderDateYMD = createdMoment.format('YYYY-MM-DD');
    this.recentOrder = (this.baseOrderInformation.currentMoment.diff(createdMoment, 'months') <= 11);
    this.orderRenewalPeriod = this.planType;
    // We don't want to create new EVO or GoCardless forms
    this.shopifyOrder.tags = `${this.shopifyOrder.tags}, SKIP_ATTR`;
    if ((this.baseOrderInformation.cseOrder.proRataCurrentPrice > 0) && this.selectedOrderType &&
        (this.selectedOrderType.calculationMethod === CalculationMethod.DIFFERENCE)) {
      this.discount = {
        'code': 'Pro-rata rebate',
        'valueType': 'amount',
        'discountType': 'ORDER',
        'productIds': [],
        'variantProductIds': [],
        'collectionIds': [],
        'discount': this.baseOrderInformation.cseOrder.proRataCurrentPrice,
        'oncePerOrder': false,
        'minimumOrderValue': 0,
        'shortSummary': 'Pro-Rata existing Plan Price (subtracted from new price)',
      };
    }
    if (this.plan) {
      this.populateBaseUnitTypesFromPlan(this.plan);
      if (this.plan.includes('V')) {
        this.vatSelected = true;
        this.orderVatStatus = OrderVatStatus.VATABLE;
      } else {
        this.vatSelected = false;
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      this.setVat();
    }
    this.validateBillingAddress(false);
    this.orderMetadata.renewalMethod = 'N/A';
    if (!this.baseOrderInformation.hasBulkTag && (this.orderRenewalPeriod != 'lifetime')) {
      this.ddMessage = 'You are being prompted to take payment details because:';
      if (!this.order.renewalInformation ||
            !['directDebit', 'goCardless', 'goCardless - No Subscription', 'goCardless - Subscription', 'recurringBilling', 'standing order']
            .includes(this.order.renewalInformation.renewalType)) {
        this.ddMessage = `${this.ddMessage}\n The customer does not currently have an automated payment set up. ` +
          'Please check if they have recently set one up in case the CRM has not been updated yet.';
        this.orderMetadata.renewalMethod = 'goCardless';
      }
    }
    this.alarmUserAddress = this.order.alarmUserDetails.userAddress;
  }

  emailBlur(): void {
    if (this.shopifyCustomer.email) {
      this.paperNcfRequired = 'No';
    }
  }

  set showPlaceOrder(newValue: boolean) {
    this.orderBeingCreated = !newValue;
    this.updateShowPlaceOrder.emit(newValue);
  }

  get selectedWebsite(): Website {
    return (this.baseOrderInformation.cseOrder.websiteId as Website);
  }

  get showPlaceOrder(): boolean {
    return !this.orderBeingCreated;
  }

  get order(): Order {
    return this.baseOrderInformation.existingOrder;
  }

  set order(newValue: Order) {
    this.baseOrderInformation.existingOrder = newValue;
  }

  get planType(): string {
    return (this.order && this.order.accountDetails)? this.order.accountDetails.planType: '';
  }

  get plan(): string {
    return (this.order && this.order.accountDetails)? this.order.accountDetails.plan: '';
  }

  get selectedOrderType(): OrderType {
    return this.baseOrderInformation.orderType;
  }

  get validationErrorsExists(): boolean {
    return this.validationErrors.length > 0;
  }

  set customerFirstName(newValue: string) {
    this.shopifyCustomer.first_name = newValue;
    this.shopifyOrder.billing_address.first_name = newValue;
  }

  get customerFirstName(): string {
    return this.shopifyCustomer.first_name;
  }

  set customerLastName(newValue: string) {
    this.shopifyCustomer.last_name = newValue;
    this.shopifyOrder.billing_address.last_name = newValue;
  }

  get customerLastName(): string {
    return this.shopifyCustomer.last_name;
  }

  set customerPhone(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.shopifyCustomer.phone = e164FormatNumber;
    this.shopifyOrder.phone = e164FormatNumber;
  }

  get customerPhone(): string {
    return this.shopifyCustomer.phone;
  }

  set alarmUserPhone(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.orderMetadata.alarmUserPhone = e164FormatNumber;
  }

  get alarmUserPhone(): string {
    return this.orderMetadata.alarmUserPhone;
  }

  set alarmUserMobile(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.orderMetadata.alarmUserMobile = e164FormatNumber;
  }

  get alarmUserMobile(): string {
    return this.orderMetadata.alarmUserMobile;
  }

  get overrideTotal(): boolean {
    return this.totalOverridden;
  }

  set overrideTotal(overridden: boolean) {
    // Total was overridden, but turned off, so reset prices
    if (!overridden && this.totalOverridden) {
      for (const shopifyItem of this.shopifyItems) {
        shopifyItem.unitSubtotalIncVat = shopifyItem.originalPrice;
      }
    }
    this.totalOverridden = overridden;
    this.priceupdate();
  }

  isExistingCustomer(): boolean {
    return !this.selectedOrderType.isNewOrder;
  }

  isAdditional(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.ADDITIONAL);
  }

  isReplacementDueToFault(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.title == 'Faulty');
  }

  isAllowedDiscontinued(): boolean {
    return this.selectedOrderType &&
      ([CalculationMethod.FREE, CalculationMethod.LOST].includes(this.selectedOrderType.calculationMethod));
  }

  isDeleteAllowed(item: ShopifyBasketItem): boolean {
    return (item.shopifyProduct.crmCategory != 'Setup Fees');
  }

  isOverrideAllowed(item: ShopifyBasketItem): boolean {
    if (item.shopifyProduct.tags.includes('paper-ncf') || item.shopifyProduct.tags.includes('free-post-label')) {
      return false;
    }
    if (!item.shopifyProduct.tags.includes('no-price-override')) {
      return true;
    }
    // Allow override on lifetime upgrades/downgrades as we don't have defined price process
    return [CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod) &&
      ['lifetime'].includes(item.shopifyVariant.rentalPeriod);
  }

  populateBaseUnitTypesFromPlan(planCode: string) {
    // Only care about existing alarm base unit type for Additional, Lost or Free order types
    if ([CalculationMethod.ADDITIONAL, CalculationMethod.FREE, CalculationMethod.LOST].includes(this.selectedOrderType.calculationMethod)) {
      if (/1B/.test(planCode)) {
        this.baseUnitTypes.push('Pebbell GPS');
      }
      if (/1T/.test(planCode)) {
        this.baseUnitTypes.push('Chiptech GO GPS');
      }
      if (/1P/.test(planCode)) {
        this.baseUnitTypes.push('Tynetec IP');
      }
      if (/1G/.test(planCode)) {
        this.baseUnitTypes.push('Tunstall GSM');
      }
      if (/1E/.test(planCode)) {
        this.baseUnitTypes.push('EVA GSM');
      }
      if (/17/.test(planCode)) {
        this.baseUnitTypes.push('SEVEN GSM');
      }
      if (/1Z/.test(planCode)) {
        this.baseUnitTypes.push('SmartLife');
      }
      if (/1([^BTPGE7Z]|$)/.test(planCode)) {
        this.baseUnitTypes.push('Lifeline VI');
      }
    }
  }

  setVat() {
    this.productsFiltered = this.cseProducts.filter((product: RawShopifyProduct) => {
      if (product.shopifyCollectionNames.includes('Gift Cards/Vouchers')) {
        return true;
      }
      return product.shopifyVariants.some((shopifyVariant: RawShopifyVariant) =>
        shopifyVariant.taxable === this.vatSelected,
      );
    });
    if (this.vatSelected == false) {
      if (this.orderVatStatus == OrderVatStatus.VATABLE) {
        // They can't choose anything from this category with VATable items in their basket
        this.categories = [];
      } else {
        this.categories = this.exemptCategories; // Ex keysafe
      }
    } else if (this.vatSelected == true) {
      if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
        // They have VAT exempt items in the order, so can only add keysafes from the VATable category
        // Or installation of a keysafe
        this.categories = [{
          'label': 'Key Safes',
          'value': 'Key Safes'
        },{
          'label': 'Installations',
          'value': 'Installations',
        }];
      } else {
        this.categories = this.notExemptCategories; // Inc keysafe
      }
    }
    this.categories = this.categories.filter((categorySelect: SelectItem<string>) => {
      if (['Discontinued', 'Base Unit', 'Cables/Chargers', 'GPS Alarm'].includes(categorySelect.value)) {
        return (this.isAllowedDiscontinued());
      }
      return true;
    });
  }

  setCategory() {
    this.setVat();
    this.productsFiltered = this.productsFiltered.filter((product: RawShopifyProduct) => {
      if (product.crmCategory != this.category) {
        return false;
      }
      if ([CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
        if (product.tags.includes('block-for-upgrades')) {
          return false;
        }
      }
      // If the product is a linked accessory only allow it if it relates to an alarm in the order
      if (product.shopifyCollectionNames.some((collectionName: string) => collectionName.includes('Linked Accessories'))) {
        let allowedProduct: boolean = false;
        for (const baseUnitType of this.baseUnitTypes) {
          if (product.shopifyCollectionNames.includes(`${baseUnitType} Linked Accessories`)) {
            allowedProduct = true;
            break;
          }
        }
        if (!allowedProduct) {
          return false;
        }
      }
      if (this.orderRenewalPeriod) {
        return product.shopifyVariants.some((shopifyVariant: RawShopifyVariant) => {
          if (shopifyVariant.rentalPeriod == this.orderRenewalPeriod) {
            return true;
          }
          if (['none', ''].includes(shopifyVariant.rentalPeriod)) {
            return true;
          }
          return false;
        });
      }
      return true;
    });
    this.selectedProduct = null;
    this.variantsFiltered = [];
  }

  onOptionsSelected() {
    if (!this.selectedProduct) {
      this.selectedVariant = null;
      this.variantsFiltered = [];
      return;
    }
    this.variantsFiltered = this.selectedProduct.shopifyVariants.filter((variant: RawShopifyVariant) => {
      if (this.selectedProduct.shopifyCollectionNames.includes('Gift Cards/Vouchers')) {
        return true;
      }
      if (variant.taxable != this.vatSelected) {
        return false;
      }
      if (this.orderRenewalPeriod && !['', 'none'].includes(variant.rentalPeriod)) {
        return variant.rentalPeriod == this.orderRenewalPeriod;
      }
      return true;
    });
    if (this.variantsFiltered.length == 1) {
      this.selectedVariant = this.variantsFiltered[0];
    } else {
      this.selectedVariant = null;
    }
  }

  updateFilters() {
    // Update the category list for changes to the VAT status and frequency of products in the basket
    if (this.category) {
      // This calls setVat, so no need to call that if category is set
      this.setCategory();
    } else if (this.vatSelected) {
      this.setVat();
    }
  }

  addToInvoice() {
    const selectedProd: ShopifyBasketItem = {
      'basketTitle': getTitleFromProductAndVariant(this.selectedProduct, this.selectedVariant),
      'shopifyProduct': this.selectedProduct,
      'shopifyVariant': this.variantMap[this.selectedVariant.shopifyVariantId],
      'quantity': 1,
      'priceCalculated': false,
      'unitSubtotalIncVat': this.selectedVariant.basketPrice,
      'unitSubtotalVat': 0,
      'originalPrice': this.selectedVariant.basketPrice,
      'unitTotalIncVat': this.selectedVariant.basketPrice,
      'lineTotalVat': 0
    };
    if (this.selectedProduct.shopifyCollectionNames.includes('Gift Cards/Vouchers')) {
      selectedProd.unitSubtotalIncVat = 0; 
      selectedProd.originalPrice = 0;
    }
    this.variantsFiltered = [];

    const pos: number = this.shopifyItems.findIndex((shopifyItem: ShopifyBasketItem) =>
      selectedProd.shopifyVariant.shopifyVariantId == shopifyItem.shopifyVariant.shopifyVariantId
    );
    if (pos == -1) {
      this.shopifyItems.push(selectedProd);
    } else {
      this.shopifyItems[pos].quantity += 1;
    }

    this.selectedVariant = null;
    this.selectedProduct = null;
    this.updatePlanCodeAndNewRenewalPrice(false);
    this.priceupdate();
    this.updateFilters();
  }

  priceupdate() {
    this.vatTotalPreDiscount = 0;
    this.vatTotalAfterDiscount = 0;
    this.orderTotal = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.calculationErrors = [];
    this.lifetimeLineNeedsOverride = false;
    this.hasRentalItems = false;
    if (this.baseOrderInformation.hasBulkTag) {
      this.freeOrderPriceUpdate();
    } else {
      switch (this.selectedOrderType.calculationMethod) {
        case CalculationMethod.FULL:
          this.newOrderPriceUpdate();
          break;
        case CalculationMethod.ADDITIONAL:
          this.proRataPriceUpdate(false);
          break;
        case CalculationMethod.DIFFERENCE:
          this.proRataPriceUpdate(true);
          break;
        case CalculationMethod.FREE:
          this.freeOrderPriceUpdate();
          break;
        case CalculationMethod.LOST:
          this.lostItemPriceUpdate();
          break;
        default:
          break;
      }
    }
    // Calculate the VAT total now we've finished allocating any discounts out
    for (const basketItem of this.shopifyItems) {
      this.vatTotalAfterDiscount += roundToTwoDecimalPlaces(basketItem.lineTotalVat);
    }
    if (!this.isExistingCustomer()) {
      if (!this.hasRentalItems) {
        this.orderMetadata.renewalMethod = 'N/A';
        this.paperNcfRequired = 'No';
      } else {
        this.paperNcfRequired = this.shopifyCustomer.email? 'No': 'Yes';
      }
    }
    if (this.orderTotal == 0) {
      this.orderMetadata.paymentMethod = 'No Payment Required';
    } else if ((this.orderTotal > 0) && (this.orderMetadata.paymentMethod == 'No Payment Required')) {
      this.orderMetadata.paymentMethod = '';
    }
  }

  setOrderFiltersAndFlags(item: ShopifyBasketItem) {
    if (!['', 'none'].includes(item.shopifyVariant.rentalPeriod)) {
      this.orderRenewalPeriod = item.shopifyVariant.rentalPeriod;
      this.hasRentalItems = true;
      if (!this.isExistingCustomer() && (this.orderMetadata.renewalMethod == 'N/A') && !['lifetime'].includes(item.shopifyVariant.rentalPeriod)) {
        this.orderMetadata.renewalMethod = 'goCardless';
      }
    }
    if (item.shopifyProduct.isBundle) {
      item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
        const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
        // Use the hardware name as less likely to have been changed
        if (variant && variant.hardware && ['Base Unit', 'GPS Alarm'].includes(variant.hardware.category)) {
          if (!this.baseUnitTypes.includes(variant.hardware.title)) {
            this.baseUnitTypes.push(variant.hardware.title);
          }
        }
      });
    } else if (item.shopifyVariant.hardware && ['Base Unit', 'GPS Alarm'].includes(item.shopifyVariant.hardware.category)) {
      if (!this.baseUnitTypes.includes(item.shopifyVariant.hardware.title)) {
        this.baseUnitTypes.push(item.shopifyVariant.hardware.title);
      }
    }
    if (item.shopifyProduct.tags.includes('alarm-user-address-required')) {
      this.alarmUserAddressRequired = true;
    }
  }

  freeOrderPriceUpdate() {
    this.alarmUserAddressRequired = false;
    this.baseUnitTypes = [];
    this.populateBaseUnitTypesFromPlan(this.plan);
    let discountCode: string = this.selectedOrderType.title;
    let shortSummary: string = `Discount as no payment due for "${discountCode}" orders`;
    if (this.baseOrderInformation.hasBulkTag) {
      discountCode = 'Order billed to Bulk account';
      shortSummary = 'Discount to customer as Bulk account pays';
    }
    this.discount = {
      'code': discountCode,
      'valueType': 'percent',
      'discountType': 'ORDER',
      'productIds': [],
      'variantProductIds': [],
      'collectionIds': [],
      'discount': 1,
      'oncePerOrder': false,
      'minimumOrderValue': 0,
      'shortSummary': shortSummary,
    };
    // Free Item pricing is done by applying 100% discount, but starting price is based of price of items on Shopify
    for (const currentItem of this.shopifyItems) {
      let unitVat: number = 0;
      if (!currentItem.priceCalculated) {
        if (!currentItem.shopifyProduct.isBundle) {
          currentItem.unitSubtotalIncVat = currentItem.shopifyVariant.basketPrice;
          currentItem.originalPrice = currentItem.shopifyVariant.basketPrice;
          currentItem.priceCalculated = true;
        } else {
          let lineUnitTotal: number = 0;
          currentItem.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
            const product: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
            const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
            const isSubscription: boolean = product.crmCategory == 'Subscriptions';
            if (!isSubscription) {
              lineUnitTotal += variant.basketPrice;
            }
          });
          currentItem.unitSubtotalIncVat = lineUnitTotal;
          currentItem.originalPrice = lineUnitTotal;
          currentItem.priceCalculated = true;
        }
      }
      this.orderTotal += currentItem.unitSubtotalIncVat * currentItem.quantity;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * currentItem.unitSubtotalIncVat / EX_TO_INC_VAT_MULTIPLIER);
        this.vatTotalPreDiscount += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      currentItem.unitSubtotalVat = unitVat;
      currentItem.unitTotalIncVat = currentItem.unitSubtotalIncVat;
      currentItem.lineTotalVat = unitVat * currentItem.quantity;
      this.setOrderFiltersAndFlags(currentItem);
    }
    const totalBeforeDiscount: number = this.orderTotal;
    this.applyPercentDiscount();
    // Coupon discount is what the value was before, minus the value now
    this.discountAmount = totalBeforeDiscount - this.orderTotal;
  }

  /*
   * Original freeOrderPriceUpdate in case it's decided to put it back
   freeOrderPriceUpdate() {
    this.baseUnitTypes = [];
    this.populateBaseUnitTypesFromPlan(this.plan);
    // Update appropriate prices to zero
    // Calculations still happen in case price on any items has been overridden to be non-zero
    for (const currentItem of this.shopifyItems) {
      let unitVat: number = 0;
      if (!currentItem.priceCalculated) {
        currentItem.unitSubtotalIncVat = 0.00;
        currentItem.originalPrice = 0.00;
        currentItem.priceCalculated = true;
      }
      this.orderTotal += currentItem.unitSubtotalIncVat * currentItem.quantity;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * currentItem.unitSubtotalIncVat / EX_TO_INC_VAT_MULTIPLIER);
        this.vatTotalPreDiscount += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      currentItem.unitSubtotalVat = unitVat;
      currentItem.unitTotalIncVat = currentItem.unitSubtotalIncVat;
      currentItem.lineTotalVat = unitVat * currentItem.quantity;
      this.setOrderFiltersAndFlags(currentItem);
    }
  }
   */

  newOrderPriceUpdate() {
    // For new orders only want to filter based on what's in the basket
    this.baseUnitTypes = [];
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalPeriod = null;
    let setupFeeRequired: boolean = false;
    let hasSetupFee: boolean = false;
    this.alarmUserAddressRequired = false;

    // Calculate undiscounted value for use in discount checks
    for (const currentItem of this.shopifyItems) {
      // These will include VAT for VATable items
      let unitVat: number = 0;
      this.orderTotal += currentItem.unitSubtotalIncVat * currentItem.quantity;
      if (currentItem.shopifyProduct.crmCategory == 'Plans') {
        if (!currentItem.shopifyProduct.isBundle) {
          if (!currentItem.shopifyProduct.tags.includes('sold-item')) {
            setupFeeRequired = true;
          }
        } else {
          let hasSoldItem: boolean = false;
          currentItem.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
            const svcProduct: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
            if (svcProduct.tags.includes('sold-item')) {
              hasSoldItem = true;
            }
          });
          if (!hasSoldItem) {
            setupFeeRequired = true;
          }
        }
      } else if (currentItem.shopifyProduct.crmCategory == 'Setup Fees') {
        hasSetupFee = true;
      }
      this.totalBeforeDiscountAndOverride += currentItem.originalPrice * currentItem.quantity;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * currentItem.unitSubtotalIncVat / EX_TO_INC_VAT_MULTIPLIER);
        this.vatTotalPreDiscount += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
        if (!['Gift Cards/Vouchers', 'Installations', 'Key Safes'].includes(currentItem.shopifyProduct.crmCategory)) {
          // Key safes are never exempt, so don't define the order status
          // also installation of keysafe is never exempt, so same applies
          this.orderVatStatus = OrderVatStatus.VATABLE;
        }
      } else {
        // If they have at least 1 exempt item treat them as exempt (key safes are never exempt)
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      currentItem.unitSubtotalVat = unitVat;
      currentItem.unitTotalIncVat = currentItem.unitSubtotalIncVat;
      currentItem.lineTotalVat = unitVat * currentItem.quantity;
      this.setOrderFiltersAndFlags(currentItem);
    }

    if (setupFeeRequired != hasSetupFee) {
      // Exit this call of priceupdate as fee was updated and will call this method again it once the the fee is updated
      this.updateSetupFee(setupFeeRequired);
      return;
    }

    console.log('Price before discount', this.orderTotal);
    console.log('VAT before discount', this.vatTotalPreDiscount);
    // Capture the order total before discount so we can calculate the discount amount
    const totalBeforeDiscount: number = this.orderTotal;
    // TODO check for all types of discount
    if (this.discountCode && this.isDiscountValid()) {
      switch (this.discount.valueType) {
        /*
          Percent amount off
        */
        case 'percent':
          this.applyPercentDiscount();
          break;
        /*
          Currency amount off
        */
        case 'amount':
          if (this.discount.discountType == 'ORDER') {
            this.applyWholeOrderFixedDiscount();
          } else if (this.discount.discountType == 'PRODUCT') {
            this.applyProductFixedDiscount();
          }
          break;
      }
    }
    // Coupon discount is what the value was before, minus the value now
    this.discountAmount = totalBeforeDiscount - this.orderTotal;
  }

  /**
   * Add or remove the setup fee from the order as required
   * @param {boolean} setupFeeRequired whether the order needs the setup fee
   */
  updateSetupFee(setupFeeRequired: boolean) {
    this.setVat();
    if (setupFeeRequired) {
      if (this.setupFees.length == 0) {
        console.error('Setup Fee not found - not loaded');
        this.calculationErrors.push('Setup Fee required, but setup fee product not found when loading products');
        return;
      }
      let setupFeeVariant: RawShopifyVariant = undefined;
      const setupFeeProduct: RawShopifyProduct = this.setupFees.find((product: RawShopifyProduct) => {
        const variantMatch: RawShopifyVariant = product.shopifyVariants.find((variant: RawShopifyVariant) => {
          return (variant.taxable == this.vatSelected);
        });
        if (variantMatch) {
          setupFeeVariant = variantMatch;
          return true;
        }
        return false;
      });

      if (!setupFeeProduct) {
        console.error('Setup Fee not found - no match for tax status');
        this.calculationErrors.push('Setup Fee required, but setup fee product not found with correct tax status');
        return;
      }
      const selectedProd: ShopifyBasketItem = {
        'basketTitle': getTitleFromProductAndVariant(setupFeeProduct, setupFeeVariant),
        'shopifyProduct': setupFeeProduct,
        'shopifyVariant': this.variantMap[setupFeeVariant.shopifyVariantId],
        'quantity': 1,
        'priceCalculated': false,
        'unitSubtotalIncVat': setupFeeVariant.basketPrice,
        'unitSubtotalVat': 0,
        'originalPrice': setupFeeVariant.basketPrice,
        'unitTotalIncVat': setupFeeVariant.basketPrice,
        'lineTotalVat': 0
      };
      this.shopifyItems.push(selectedProd);
    } else {
      this.shopifyItems = this.shopifyItems.filter((item: ShopifyBasketItem) => {
        return (item.shopifyProduct.crmCategory != 'Setup Fees');
      });
    }
    // Fees were added/removed, so need to recalculate price
    this.priceupdate();
  }

  getProRataPriceForHardware(product: RawShopifyProduct, variant: PopulatedShopifyVariant): number {
    let tempPrice: number = 0;
    let soldItemsPrice: number = 0;
    const hardwareList: Hardware[] = [];
    if (!product.isBundle) {
      if (product.tags.includes('sold-item')) {
        // Sold items have to be paid for fully as well as the pro-rata sub amount
        soldItemsPrice += variant.basketPrice;
      }
      if (variant.hardware && variant.hardware._id) {
        const hardware: Hardware = this.hardwareMapById[variant.hardware._id];
        if (!hardware) {
          this.calculationErrors.push(`Hardware not found for variant ${variant.crmVariantTitle}`);
        } else {
          hardwareList.push(hardware);
        }
      }
    } else {
      variant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
        const svcProduct: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
        const svcVariant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
        if (svcProduct.tags.includes('sold-item')) {
          // Sold items have to be paid for fully as well as the pro-rata sub amount
          soldItemsPrice += svcVariant.basketPrice;
        }
        if (svcVariant.hardware && svcVariant.hardware._id) {
          const hardware: Hardware = this.hardwareMapById[svcVariant.hardware._id];
          if (!hardware) {
            this.calculationErrors.push(`Hardware not found for variant ${variant.crmVariantTitle}`);
          } else {
            hardwareList.push(hardware);
          }
        }
      });
    }
    if (hardwareList.length > 0) {
      const hardwareAndSets: HardwareAndSets = convertHardwareToSets(hardwareList, this.hardwareSets);
      hardwareAndSets.hardwareSets.forEach((set: HardwareSet) => {
        const setPrice: number|undefined = getPriceForOrderDate(this.recentOrder, this.planType, this.orderDateYMD,
          set.overridePricesExVat, set.overrideRecentQuarterlyPrice, set.overrideRecentAnnualPrice);
        if (!setPrice) {
          this.calculationErrors.push(`No price for order date for ${set.title}`);
        } else {
          tempPrice += setPrice;
        }
      });
      hardwareAndSets.hardware.forEach((hardware: Hardware) => {
        const hardwarePrice: number|undefined = getPriceForOrderDate(this.recentOrder, this.planType, this.orderDateYMD,
          hardware.pricesExVat, hardware.recentQuarterlyPrice, hardware.recentAnnualPrice);
        if (!hardwarePrice) {
          this.calculationErrors.push(`nNo price for order date for ${hardware.title}`);
        } else {
          tempPrice += hardwarePrice;
        }
      });
      if (this.planType == 'monthly') {
        tempPrice = tempPrice / 3.00;
      }
      if (variant.taxable) {
        tempPrice = tempPrice * EX_TO_INC_VAT_MULTIPLIER;
      }
    }
    // soldItemsPrice already includes VAT and don't need pro-rating or adjusting for monthly, so just add
    tempPrice = roundToTwoDecimalPlaces((tempPrice * this.baseOrderInformation.proRataMultiplier) + soldItemsPrice);
    return tempPrice;
  }

  proRataPriceUpdate(removeCurrentPrice: boolean) {
    this.alarmUserAddressRequired = false;
    this.baseUnitTypes = [];
    // If we're removing the current price it's because they are replacing equipment so we need to filter based only on products in the basket,
    // otherwise restrictions are based on current order equipment
    if (!removeCurrentPrice) {
      this.populateBaseUnitTypesFromPlan(this.plan);
    }

    // Calculate the un-prorated value
    for (const currentItem of this.shopifyItems) {
      // These will include VAT for VATable items
      let unitVat: number = 0;
      let rental: boolean = false;
      if (currentItem.shopifyProduct.isBundle) {
        rental = !['', 'none'].includes(currentItem.shopifyVariant.rentalPeriod);
      } else {
        rental = !!currentItem.shopifyVariant.hardware;
      }
      // Adding equipment to lifetime should charge the current lifetime price for what's being added
      if (rental && !((this.planType == 'lifetime') && this.isAdditional())) {
        if ((this.planType == 'lifetime') && (currentItem.unitSubtotalIncVat == currentItem.originalPrice)) {
          // We can't calculate for lifetime, but flag price hasn't been overridden
          this.lifetimeLineNeedsOverride = true;
        } else if (!currentItem.priceCalculated) {
          const tempPrice: number = this.getProRataPriceForHardware(currentItem.shopifyProduct, currentItem.shopifyVariant);
          // Set the original price too, so if override is turned off can set back without recalculating
          currentItem.unitSubtotalIncVat = tempPrice;
          currentItem.originalPrice = tempPrice;
          currentItem.priceCalculated = true;
        }
      }
      this.orderTotal += currentItem.unitSubtotalIncVat * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += currentItem.originalPrice * currentItem.quantity;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * currentItem.unitSubtotalIncVat / EX_TO_INC_VAT_MULTIPLIER);
        this.vatTotalPreDiscount += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      currentItem.unitSubtotalVat = unitVat;
      currentItem.unitTotalIncVat = currentItem.unitSubtotalIncVat;
      currentItem.lineTotalVat = unitVat * currentItem.quantity;
      this.setOrderFiltersAndFlags(currentItem);
    }

    console.log('Price before discount', this.orderTotal);
    console.log('VAT before discount', this.vatTotalPreDiscount);
    if (removeCurrentPrice && (this.orderTotal >= 0) && (this.baseOrderInformation.cseOrder.proRataCurrentPrice >= 0)) {
      this.totalBeforeDiscountAndOverride =
        roundToTwoDecimalPlaces(this.totalBeforeDiscountAndOverride - this.baseOrderInformation.cseOrder.proRataCurrentPrice);
      if (this.totalBeforeDiscountAndOverride < 0) {
        this.totalBeforeDiscountAndOverride = 0;
      }
      const totalBeforeDiscount: number = this.orderTotal;
      // Pro-rata rebate set up as a discount code
      this.applyWholeOrderFixedDiscount();
      // Coupon discount is what the value was before, minus the value now
      this.discountAmount = totalBeforeDiscount - this.orderTotal;
    }
  }

  discountValidForProduct(basketItem: ShopifyBasketItem): boolean {
    if (basketItem.unitTotalIncVat <= 0) {
      return false;
    }
    // Order coupons once valid to apply apply to all non-zero priced items
    if (this.isOrderLevelDiscount()) {
      // Stop "Limited Discount Plans" from getting order level discounts unless it's due to a missing/not dispatched or faulty
      // Product level discounts should be appropriately set whether to include this or not
      if (basketItem.shopifyProduct.shopifyCollectionNames.includes('Limited Discount Plans') &&
          ![CalculationMethod.FREE].includes(this.selectedOrderType.calculationMethod)) {
        return false;
      }
      return true;
    }
    if (this.discount.productIds.includes(basketItem.shopifyProduct.shopifyProductId) ||
      this.discount.variantProductIds.includes(basketItem.shopifyVariant.shopifyVariantId)) {
      return true;
    }
    if (this.discount.collectionIds.some((collectionId: string) => basketItem.shopifyProduct.shopifyCollectionIds.includes(collectionId))) {
      return true;
    }
    return false;
  }

  applyPercentDiscount() {
    // Reset the order total as need to calculate with discount applied
    this.orderTotal = 0;
    const discountMultiplier: number = 1 - this.discount.discount;
    for (const basketItem of this.shopifyItems) {
      let applyDiscount: boolean = false;
      applyDiscount = this.discountValidForProduct(basketItem);
      //Shopify floors discounts
      if (applyDiscount) {
        basketItem.unitTotalIncVat = floorToTwoDecimalPlaces(basketItem.unitTotalIncVat * discountMultiplier);
        // If it is VATable recalculate the VAT
        if (basketItem.unitSubtotalVat > 0) {
          basketItem.lineTotalVat = floorToTwoDecimalPlaces(basketItem.unitSubtotalVat * basketItem.quantity * discountMultiplier);
        }
      }
      this.orderTotal += roundToTwoDecimalPlaces(basketItem.unitTotalIncVat * basketItem.quantity);
    }
  }

  applyWholeOrderFixedDiscount() {
    //TODO need to chagne discountToGive if we don't want fixed discount to be more than the non James watch price
    // don't allow more discount than the order value
    const discountToGive: number = this.discount.discount > this.orderTotal? this.orderTotal: this.discount.discount;
    // Reset the order total as need to calculate with discount applied
    this.orderTotal = 0;
    const itemsToDiscount: ShopifyBasketItem[] = [];
    // Need to split the discount in the ratio of the item's value to the value of the items that can be discounted
    let discountableItemTotal: number = 0;
    let discountApplied: number = 0;

    for (const basketItem of this.shopifyItems) {
      if (this.discountValidForProduct(basketItem)) {
        itemsToDiscount.push(basketItem);
        // Need to include the VAT when determining the ratio
        discountableItemTotal += roundToTwoDecimalPlaces(basketItem.unitSubtotalIncVat);
      }
    }
    for (const itemToDiscount of itemsToDiscount) {
      // Floor the discount here, if necessary the logic in the next step will add pennies back in until the correct discount is given
      // This stops us possibly giving a pennies too much
      const discountIncVat: number = floorToTwoDecimalPlaces(discountToGive * (itemToDiscount.unitSubtotalIncVat / discountableItemTotal));
      itemToDiscount.unitTotalIncVat = roundToTwoDecimalPlaces(Math.max(0, itemToDiscount.unitTotalIncVat - discountIncVat));
      // If it is VATable recalculate the VAT
      if (itemToDiscount.unitSubtotalVat > 0) {
        itemToDiscount.lineTotalVat =
          roundToTwoDecimalPlaces(VAT_MULTIPLIER * itemToDiscount.unitTotalIncVat * itemToDiscount.quantity / EX_TO_INC_VAT_MULTIPLIER);
      }
      // We recalculate the discount applied as the code might have applied less discount if it would have taken the price negative
      discountApplied += roundToTwoDecimalPlaces(itemToDiscount.unitSubtotalIncVat - itemToDiscount.unitTotalIncVat);
    }
    // If we have a remainder due to capping the discount at the line value take pennies/cents of eligible
    // lines until we get to the appropriate applied discount.
    while (discountApplied < discountToGive) {
      let eligibleLineFound: boolean = false;
      for (const itemToDiscount of itemsToDiscount) {
        // Cannot substract any value from this line
        if (itemToDiscount.unitTotalIncVat < 0.01) {
          continue;
        }
        eligibleLineFound = true;
        const discountBefore: number = roundToTwoDecimalPlaces(itemToDiscount.unitSubtotalIncVat - itemToDiscount.unitTotalIncVat);
        itemToDiscount.unitTotalIncVat -= 0.01;
        // If it is VATable recalculate the VAT
        if (itemToDiscount.unitSubtotalVat > 0) {
          itemToDiscount.lineTotalVat =
            roundToTwoDecimalPlaces(VAT_MULTIPLIER * itemToDiscount.unitTotalIncVat * itemToDiscount.quantity / EX_TO_INC_VAT_MULTIPLIER);
        }
        const discountAfter: number = roundToTwoDecimalPlaces(itemToDiscount.unitSubtotalIncVat - itemToDiscount.unitTotalIncVat);
        discountApplied += (discountAfter - discountBefore);
        if (discountApplied >= discountToGive) {
          break;
        }
      }
      // Avoid getting stuck in loop if there are no more lines we can discount
      if (!eligibleLineFound) {
        break;
      }
    }
    // Calculate the totals now we've finished allocating the discount out
    for (const basketItem of this.shopifyItems) {
      this.orderTotal += roundToTwoDecimalPlaces(basketItem.unitTotalIncVat * basketItem.quantity);
    }
  }

  applyProductFixedDiscount() {
    // Reset the order total as need to calculate with discount applied
    this.orderTotal = 0;
    const discountToGive: number = this.discount.discount;
    let discountApplied: boolean = false;
    for (const basketItem of this.shopifyItems) {
      let applyDiscount: boolean = false;
      if (this.discount.oncePerOrder && discountApplied) {
        applyDiscount = false;
      } else {
        applyDiscount = this.discountValidForProduct(basketItem);
      }
      if (applyDiscount) {
        discountApplied = true;
        let discountToApplyToLine: number = discountToGive;
        //TODO change here needed if we don't want to apply fixed product discount to James watch part of line
        if (this.discount.oncePerOrder) {
          // Don't allow discount to take the value negative
          if (discountToApplyToLine > (basketItem.unitTotalIncVat * basketItem.quantity)) {
            discountToApplyToLine = basketItem.unitTotalIncVat * basketItem.quantity;
          }
          basketItem.unitTotalIncVat = roundToTwoDecimalPlaces(basketItem.unitTotalIncVat - (discountToGive / basketItem.quantity));
        } else {
          // Don't allow discount to take the value negative
          if (discountToApplyToLine > basketItem.unitTotalIncVat) {
            discountToApplyToLine = basketItem.unitTotalIncVat;
          }
          basketItem.unitTotalIncVat = roundToTwoDecimalPlaces(basketItem.unitTotalIncVat - discountToGive);
        }
        // If it is VATable recalculate the VAT
        if (basketItem.unitSubtotalVat > 0) {
          basketItem.lineTotalVat =
            roundToTwoDecimalPlaces(VAT_MULTIPLIER * basketItem.unitTotalIncVat * basketItem.quantity / EX_TO_INC_VAT_MULTIPLIER);
        }
      }
      this.orderTotal += roundToTwoDecimalPlaces(basketItem.unitTotalIncVat * basketItem.quantity);
    }
  }

  getLostItemPriceForHardware(product: RawShopifyProduct, variant: PopulatedShopifyVariant): number {
    let tempPrice: number = 0;
    let soldItemsPrice: number = 0;
    const hardwareList: Hardware[] = [];
    if (!product.isBundle) {
      if (product.tags.includes('sold-item')) {
        // Sold items price for replacement will be whatever the basket price is currently
        soldItemsPrice += variant.basketPrice;
      } else {
        if (variant.hardware && variant.hardware._id) {
          const hardware: Hardware = this.hardwareMapById[variant.hardware._id];
          if (!hardware) {
            this.calculationErrors.push(`Hardware not found for variant ${variant.crmVariantTitle}`);
          } else {
            hardwareList.push(hardware);
          }
        }
      }
    } else {
      variant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
        const svcProduct: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
        const svcVariant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
        if (svcProduct.tags.includes('sold-item')) {
          // Sold items price for replacement will be whatever the basket price is currently
          soldItemsPrice += svcVariant.basketPrice;
        } else {
          if (svcVariant.hardware && svcVariant.hardware._id) {
            const hardware: Hardware = this.hardwareMapById[svcVariant.hardware._id];
            if (!hardware) {
              this.calculationErrors.push(`Hardware not found for variant ${svcVariant.crmVariantTitle}`);
            } else {
              hardwareList.push(hardware);
            }
          }
        }
      });
    }
    if (hardwareList.length > 0) {
      const hardwareAndSets: HardwareAndSets = convertHardwareToSets(hardwareList, this.hardwareSets);
      hardwareAndSets.hardwareSets.forEach((set: HardwareSet) => {
        tempPrice += set.overrideReplacementPrice;
      });
      hardwareAndSets.hardware.forEach((hardware: Hardware) => {
        tempPrice += hardware.replacementPrice;
      });
      if (variant.taxable) {
        tempPrice = tempPrice * EX_TO_INC_VAT_MULTIPLIER;
      }
    }
    // soldItemsPrice already includes VAT, so don't need to add again
    tempPrice = roundToTwoDecimalPlaces(tempPrice + soldItemsPrice);
    return tempPrice
  }

  lostItemPriceUpdate() {
    this.alarmUserAddressRequired = false;
    // Update appropriate prices to the replacement price
    for (const currentItem of this.shopifyItems) {
      let unitVat: number = 0;
      let rental: boolean = false;
      if (currentItem.shopifyProduct.isBundle) {
        rental = !['', 'none'].includes(currentItem.shopifyVariant.rentalPeriod);
      } else {
        rental = !!currentItem.shopifyVariant.hardware;
      }
      if (rental && !currentItem.priceCalculated) {
        const tempPrice: number = this.getLostItemPriceForHardware(currentItem.shopifyProduct, currentItem.shopifyVariant);
        currentItem.unitSubtotalIncVat = tempPrice;
        currentItem.originalPrice = tempPrice;
        currentItem.priceCalculated = true;
      }
      this.orderTotal += currentItem.unitSubtotalIncVat * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += currentItem.originalPrice * currentItem.quantity;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * currentItem.unitSubtotalIncVat / EX_TO_INC_VAT_MULTIPLIER);
        this.vatTotalPreDiscount += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      currentItem.unitSubtotalVat = unitVat;
      currentItem.unitTotalIncVat = currentItem.unitSubtotalIncVat;
      currentItem.lineTotalVat = unitVat * currentItem.quantity;
      this.setOrderFiltersAndFlags(currentItem);
    }
    console.log('Price before discount', this.orderTotal);
    console.log('VAT before discount', this.vatTotalPreDiscount);
  }

  delete(i: number) {
    this.shopifyItems.splice(i, 1);
    this.updatePlanCodeAndNewRenewalPrice(false);
    this.priceupdate();
    this.updateFilters();
  }

  searchDiscount() {
    if (!this.discountCode) {
      this.discountCodeError = '';
      this.discount = undefined;
    }
    let matchingDiscount: ShopifyDiscount|undefined = undefined;
    for (const discount of this.discounts) {
      if (this.discountCode.toLocaleLowerCase() == discount.code.toLocaleLowerCase()) {
        matchingDiscount = discount;
        if (this.partnershipByVoucherCode[this.discountCode]) {
          this.baseOrderInformation.cseOrder.howHeard = 'Partnership';
          this.selectedPartnership = this.partnershipByVoucherCode[this.discountCode];
        }
      }
    }
    this.discount = matchingDiscount;
    this.priceupdate();
  }

  billingAddressSearch() {
    if (!this.billingSearchPostCode) {
      return;
    }
    this.billingAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.billingSearchError = '';
    this.getAddrClient.find(this.billingSearchPostCode).then((addressResult: Result<FindSuccess, FindFailed>) => {
      this.billingAddressResults = getLookupFromGetAddressResult(this.billingSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.billingSearchError = addressResult.toFailed().message;
        console.error('Billing Address search failed. Error:', this.billingSearchError);
      } else if (this.billingAddressResults.length <= 2) {
        this.billingSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Billing Address search failed. Error:', error);
    });
  }

  setBillingAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowBillingAddressManualEntry = true;
      if (!this.shopifyOrder.billing_address.address1 && !this.shopifyOrder.billing_address.zip) {
        this.shopifyOrder.billing_address.zip = this.billingSearchPostCode;
      }
      this.orderMetadata.billingAddressValidated = false;
      return;
    }
    this.allowBillingAddressManualEntry = false;
    // Don't want to copy Role
    this.shopifyOrder.billing_address.address1 = selectedAddress.addressOne;
    this.shopifyOrder.billing_address.address2 = selectedAddress.addressTwo;
    this.shopifyOrder.billing_address.city = selectedAddress.city;
    this.shopifyOrder.billing_address.province = selectedAddress.county;
    this.shopifyOrder.billing_address.zip = selectedAddress.postcode;
    this.orderMetadata.billingAddressValidated = selectedAddress.validated;
  }

  deliveryAddressSearch() {
    if (!this.deliverySearchPostCode) {
      return;
    }
    this.deliveryAddressResults = [];
    this.allowDeliveryAddressManualEntry = false;
    this.deliverySearchError = '';
    this.getAddrClient.find(this.deliverySearchPostCode).then((addressResult: Result<FindSuccess, FindFailed>) => {
      this.deliveryAddressResults = getLookupFromGetAddressResult(this.deliverySearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.deliverySearchError = addressResult.toFailed().message;
        console.error('Delivery Address search failed. Error:', this.deliverySearchError);
      } else if (this.deliveryAddressResults.length <= 2) {
        this.deliverySearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Delivery Address search failed. Error:', error);
    });
  }

  setDeliveryAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowDeliveryAddressManualEntry = true;
      if (!this.shopifyOrder.shipping_address.address1 && !this.shopifyOrder.shipping_address.zip) {
        this.shopifyOrder.shipping_address.zip = this.deliverySearchPostCode;
      }
      this.orderMetadata.shippingAddressValidated = false;
      return;
    }
    this.allowDeliveryAddressManualEntry = false;
    // Don't want to copy Role
    this.shopifyOrder.shipping_address.address1 = selectedAddress.addressOne;
    this.shopifyOrder.shipping_address.address2 = selectedAddress.addressTwo;
    this.shopifyOrder.shipping_address.city = selectedAddress.city;
    this.shopifyOrder.shipping_address.province = selectedAddress.county;
    this.shopifyOrder.shipping_address.zip = selectedAddress.postcode;
    this.orderMetadata.shippingAddressValidated = selectedAddress.validated;
  }

  alarmUserAddressSearch() {
    if (!this.alarmUserSearchPostCode) {
      return;
    }
    this.alarmUserAddressResults = [];
    this.allowAlarmUserAddressManualEntry = false;
    this.alarmUserSearchError = '';
    this.getAddrClient.find(this.alarmUserSearchPostCode).then((addressResult: Result<FindSuccess, FindFailed>) => {
      this.alarmUserAddressResults = getLookupFromGetAddressResult(this.alarmUserSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.alarmUserSearchError = addressResult.toFailed().message;
        console.error('Alarm User Address search failed. Error:', this.alarmUserSearchError);
      } else if (this.alarmUserAddressResults.length <= 2) {
        this.alarmUserSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Alarm User Address search failed. Error:', error);
    });
  }

  setAlarmUserAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAlarmUserAddressManualEntry = true;
      if (!this.alarmUserAddress.addressOne && !this.alarmUserAddress.postcode) {
        this.alarmUserAddress.postcode = this.alarmUserSearchPostCode;
      }
      this.alarmUserAddress.validated = false;
      return;
    }
    this.allowAlarmUserAddressManualEntry = false;
    // Don't want to copy Role
    this.alarmUserAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
      'unknown': false,
    };
  }

  validateBillingAddress(fromPassedData: boolean) {
    // If fromPassedData then alarm user address set from the same data, so unlock it too if there's a problem
    this.allowBillingAddressManualEntry = false;
    if (fromPassedData) {
      this.allowAlarmUserAddressManualEntry = false;
    }
    if (this.orderMetadata.billingAddressValidated) {
      return;
    }
    const addressToValidate: Address = {
      'addressOne': this.shopifyOrder.billing_address.address1,
      'addressTwo': this.shopifyOrder.billing_address.address2,
      'city': this.shopifyOrder.billing_address.city,
      'county': this.shopifyOrder.billing_address.province,
      'postcode': this.shopifyOrder.billing_address.zip,
      'validated': this.orderMetadata.billingAddressValidated,
    };
    validateAddress(this.getAddrClient, addressToValidate).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.orderMetadata.billingAddressValidated = true;
          if (fromPassedData) {
            this.alarmUserAddress.validated = true;
          }
          return;
        }
        this.billingSearchError = addressValResponse.message;
        this.billingSearchPostCode = this.shopifyOrder.billing_address? this.shopifyOrder.billing_address.zip: '';
        this.orderMetadata.billingAddressValidated = false;
        if (fromPassedData) {
          this.alarmUserSearchError = addressValResponse.message;
          this.alarmUserSearchPostCode = this.shopifyOrder.billing_address? this.shopifyOrder.billing_address.zip: '';
          this.alarmUserAddress.validated = false;
        }
        if (!addressValResponse.data) {
          this.showErrorPopUp('Error Validating Address',
            `Error validating the existing address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`
          );
        } else {
          this.billingAddressResults = addressValResponse.data;
          if (fromPassedData) {
            this.alarmUserAddressResults = addressValResponse.data;
          }
          this.showInfoPopUp('Possible Invalid Address',
            `The existing address could not be validated please check it is correct. Reason: ${addressValResponse.message}`
          );
        }
      }
    );
  }

  changeHowHeard(): void {
    if (this.baseOrderInformation.cseOrder.howHeard != 'Partnership') {
      this.selectedPartnership = undefined;
    }
  }

  changePartnership(): void {
    if (this.discountCode || !this.selectedPartnership) {
      return;
    }
    const partnerDiscountCode: string = this.selectedPartnership.bgcCouponCode;
    if (partnerDiscountCode) {
      this.confirmationService.confirm({
        'key': 'general',
        'header': 'Partnership Coupon',
        'message':
          `${this.selectedPartnership.bgcName} has a coupon code ${partnerDiscountCode} configured. Would you like to apply it now?`,
        'rejectVisible': true,
        'acceptLabel': 'Yes',
        'rejectLabel': 'No',
        'icon': 'pi pi-question-circle',
        'accept': () => {
          this.discountCode = partnerDiscountCode;
          this.searchDiscount();
        },
        'reject': () => {}
      });
    }
  }

  manuallyCompleteStep(event: Event) {
    this.processingSteps[this.currentStep].completed = (event.target as HTMLInputElement).checked;
  }

  get isPlaceOrderDisabled(): boolean {
    const tmpItems: ShopifyBasketItem[] = this.shopifyItems.filter((shopifyItem: ShopifyBasketItem) =>
      shopifyItem.quantity && (shopifyItem.quantity > 0)
    );
    if (tmpItems.length == 0) {
      return true;
    }
    if (!this.orderMetadata.paymentMethod || (this.orderMetadata.paymentMethod.trim() == '')) {
      return true;
    }
    if ((this.orderMetadata.paymentMethod == 'Secured Debit/Credit Card') &&
      (!this.orderMetadata.nameOnCard || (this.orderMetadata.nameOnCard.trim() == ''))) {
      return true;
    }
    if (isNaN(this.orderTotal) || (this.orderTotal < 0)) {
      return true;
    }
    if (this.overrideTotal) {
      if (!this.baseOrderInformation.cseOrder.overrideReason ||
        ((this.baseOrderInformation.cseOrder.overrideReason == 'Other') && !this.baseOrderInformation.cseOrder.overrideReasonOther)) {
        return true;
      }
    }
    return false;
  }

  createCardPaidOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to required
    this.processingSteps['Creating Payment Method on Stripe'].required = true;
    this.processingSteps['Creating Payment Intent on Stripe'].required = true;
    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validateOrder()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[this.currentStep].completed = true;
    }
    this.createPaymentMethod();
  }

  createOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to not required
    this.processingSteps['Creating Payment Method on Stripe'].required = false;
    this.processingSteps['Creating Payment Intent on Stripe'].required = false;
    // Set Whether the Direct Debit Form is required
    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validateOrder()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[stepName].completed = true;
    }
    this.captureOrderDetailsForReporting();
  }

  validateOrder(): boolean {
    this.validationErrors = [];
    this.changeDetector.detectChanges();
    try {
      if (this.shopifyItems.length === 0) {
        this.validationErrors.push('No Product was added to the basket');
      } else {
        const hasZeroQtyItems: boolean = this.shopifyItems.some((item: ShopifyBasketItem) =>
          !item.quantity || (item.quantity <= 0)
        );
        if (hasZeroQtyItems) {
          this.validationErrors.push('Products must have a quantity of 1 or more. Please remove any items not required.');
        }
      }
      if (this.customerFirstName.trim() === '' || this.customerLastName.trim() === '' || this.customerPhone.trim() === '' ||
        this.shopifyOrder.billing_address.address1.trim() === '' || this.shopifyOrder.billing_address.city.trim() === '' ||
        this.shopifyOrder.billing_address.zip.trim() === '') {
        this.validationErrors.push('Missing required Billing fields');
      }
      if (!isValidAnyCountryPhoneNumber(this.customerPhone)) {
        this.validationErrors.push('Missing or Invalid Customer Phone number');
      }
      this.shopifyCustomer.email = this.shopifyCustomer.email.trim();
      if (this.shopifyCustomer.email && !isValidEmailAddress(this.shopifyCustomer.email)) {
        this.validationErrors.push('Billing Email is invalid');
      }
      this.validateDifferentDelivery();
      if (!this.selectedOrderType) {
        this.validationErrors.push('Missing required Order Type');
      }
      if ((this.selectedOrderType.title == 'Phone Order') && !this.baseOrderInformation.withheldNumber &&
        ((this.baseOrderInformation.cseOrder.calledFrom == '') || !isValidAnyCountryPhoneNumber(this.baseOrderInformation.cseOrder.calledFrom))) {
        this.validationErrors.push('Missing or Invalid required Customer Called From');
      }
      if (!this.orderMetadata.paymentMethod || (this.orderMetadata.paymentMethod.trim() === '')) {
        this.validationErrors.push('Missing required Payment Method');
      }
      if (this.isExistingCustomer()) {
        if (!this.order && !this.baseOrderInformation.allowReplacementWithoutTd) {
          this.validationErrors.push(
            `You must enter an existing order's TD code or tick "No existing CRM Account" for ${this.baseOrderInformation.orderType.title} orders`);
        }
        if (this.lifetimeLineNeedsOverride) {
          this.validationErrors.push('You must override the price for all rental items on Lifetime orders as the CRM cannot calculate the values');
        }
        if (this.hasRentalItems && this.baseOrderInformation.allowReplacementWithoutTd) {
          this.validationErrors.push(`You cannot add rental items unless you have provided a TD Code for ${this.baseOrderInformation.orderType.title} orders`);
        }
        if (this.isReplacementDueToFault() && (!this.baseOrderInformation.cseOrder.fault ||
          ((this.baseOrderInformation.cseOrder.fault == 'Other') && !this.baseOrderInformation.cseOrder.otherFault))) {
          this.validationErrors.push('You must enter details of the fault.');
        }
        this.validateActiveEquipment();
      } else {
        if (!this.isDiscountValid()) {
          this.validationErrors.push('Coupon is invalid - please remove it');
        }
        this.validateAlarmUserDetails();
      }
      if (this.calculationErrors.length > 0 ) {
        this.validationErrors.push(`Error calculating basket value: ${this.calculationErrors.join('; ')}`);
      }
      if (this.replacementEquipmentErrors.length > 0) {
        this.validationErrors.push(`Error with products being retained/replaced: ${this.replacementEquipmentErrors.join('; ')}`);
      }
      if (!this.paperNcfRequired || (this.paperNcfRequired.trim() === '')) {
        this.validationErrors.push('Missing required Paper NCF field');
      } else if (this.paperNcfRequired == 'Yes') {
        if (!this.paperNcfProduct) {
          this.validationErrors.push('Paper NCF required, but Paper NCF product not found');
        } else {
          this.addPostProductIfNeeded(this.paperNcfProduct);
        }
      } else if (this.paperNcfProduct) {
        // If it's now set to not needed remove it if necessary
        this.removePostProductIfNeeded(this.paperNcfProduct);
      }
      if (this.selectedOrderType.isFplRequired) {
        if (!this.freePostLabelProduct) {
          this.validationErrors.push('FPL required, but FPL product not found');
        } else {
          this.addPostProductIfNeeded(this.freePostLabelProduct);
        }
      } else if (this.freePostLabelProduct) {
        // If it's now set to not needed remove it if necessary
        this.removePostProductIfNeeded(this.freePostLabelProduct);

      }
      if (this.validationErrors.length > 0) {
        console.error('Validation errors found', this.validationErrors);
        return true;
      }
    } catch (error: any) {
      this.validationErrors.push(`Error validating order. Error: ${error.message? error.message: 'unknown error'}`);
      return true;
    }
    try {
      this.prepareShopifyData();
    } catch (error: any) {
      this.validationErrors.push(`Error preparing Shopify data. Error: ${error.message? error.message: 'unknown error'}`);
      return true;
    }
    return false;
  }

  addPostProductIfNeeded(product: RawShopifyProduct) {
    if (this.shopifyItems.some((basketItem: ShopifyBasketItem) => basketItem.shopifyProduct.shopifyProductId == product.shopifyProductId)) {
      return;
    }
    // Only handle free post items at the moment (else have to call re-calc at this point)
    // So VAT status of variant doesn't matter
    const variantMatch: RawShopifyVariant = product.shopifyVariants[0];
    const selectedProd: ShopifyBasketItem = {
      'basketTitle': getTitleFromProductAndVariant(product, variantMatch),
      'shopifyProduct': product,
      'shopifyVariant': this.variantMap[variantMatch.shopifyVariantId],
      'quantity': 1,
      'priceCalculated': false,
      'unitSubtotalIncVat': 0,
      'unitSubtotalVat': 0,
      'originalPrice': 0,
      'unitTotalIncVat': 0,
      'lineTotalVat': 0
    };
    this.shopifyItems.push(selectedProd);
  }

  removePostProductIfNeeded(product: RawShopifyProduct) {
    const index: number = this.shopifyItems.findIndex((basketItem: ShopifyBasketItem) => 
      basketItem.shopifyProduct.shopifyProductId == product.shopifyProductId
    );
    if (index > -1) {
      this.shopifyItems.splice(index, 1);
    }
  }

  isOrderLevelDiscount(): boolean {
    if (!this.discount) {
      return false;
    }
    if (['ORDER'].includes(this.discount.discountType)) {
      return true;
    }
    return false;
  }

  isDiscountValid(): boolean {
    this.discountCodeError = '';
    if (!this.discountCode) {
      return true;
    }
    if (this.discountCode && (!this.discount || !this.discount.code)) {
      this.discountCodeError = 'Discount code not found';
    }
    if (!this.discount || !this.discount.code) {
      return false;
    }
    if (!['ORDER', 'PRODUCT'].includes(this.discount.discountType)) {
      this.discountCodeError = 'Invalid discount type';
      return false;
    }
    if (!this.isDiscountDateValid()) {
      return false;
    }
    if (!this.isOrderValueValidForDiscount()) {
      return false;
    }
    // On Shopify only product discounts can be restricted on items
    if (['PRODUCT'].includes(this.discount.discountType)) {
      if (!this.areProductsValidForDiscount()) {
        return false;
      }
    }
    return true;
  }

  isDiscountDateValid(): boolean {
    if (this.discount) {
      if (this.discount.expiryDate && this.baseOrderInformation.currentMoment.isAfter(this.discount.expiryDate)) {
        this.discountCodeError = 'Discount has expired';
        return false;
      }
      if (this.discount.startDate && this.baseOrderInformation.currentMoment.isBefore(this.discount.startDate)) {
        this.discountCodeError = 'Discount is not yet active';
        return false;
      }
    }
    return true;
  }

  isOrderValueValidForDiscount(): boolean {
    if (!this.discount) {
      return true;
    }
    const minAllowed: number = Number(this.discount.minimumOrderValue);
    if (!!minAllowed && !isNaN(minAllowed) && (minAllowed > 0) && (minAllowed > this.orderTotal)) {
      this.discountCodeError = 'Order does not meet the minimum value for the discount';
      return false;
    }
    return true;
  }

  areProductsValidForDiscount(): boolean {
    if (this.shopifyItems.length == 0) {
      return false;
    }

    let containsAllowedItems: boolean = false;
    // TODO discuss whether items in bundle should be checked, or just the bundle
    for (const item of this.shopifyItems) {
      if (this.discount.productIds.includes(item.shopifyProduct.shopifyProductId) || this.discount.variantProductIds.includes(item.shopifyVariant.shopifyVariantId)) {
        containsAllowedItems = true;
        break;
      }
      if (this.discount.collectionIds.some((collectionId: string) => item.shopifyProduct.shopifyCollectionIds.includes(collectionId))) {
        containsAllowedItems = true;
        break;
      }
    }
    if (!containsAllowedItems) {
      this.discountCodeError = 'Order does not contain required items for coupon';
      return false;
    }
    return true;
  }

  validateDifferentDelivery() {
    if (!this.differentDeliver) {
      return;
    }
    if (this.shopifyOrder.shipping_address.first_name.trim() === '' || this.shopifyOrder.shipping_address.last_name.trim() === '' ||
      this.shopifyOrder.shipping_address.address1.trim() === '' || this.shopifyOrder.shipping_address.city.trim() === '' ||
      this.shopifyOrder.shipping_address.zip.trim() === '') {
      this.validationErrors.push('Missing required Delivery fields');
    }
  }

  validateActiveEquipment() {
    if (![CalculationMethod.FREE, CalculationMethod.LOST, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
      return;
    }
    if (this.activeItems.length == 0) {
      this.validationErrors.push("There is no active equipment on the customer's record to replace");
      return;
    }
    let notAllItemsIdentified: boolean = false;
    const hardwareIdsBeingReplaced: string[] = [];
    let numberOfKeySafesBeingReplaced: number = 0;
    this.activeItems.forEach((activeItem: CseOrderExistingActiveItems) => {
      if (activeItem.source == 'keySafes') {
        if (activeItem.replacing) {
          numberOfKeySafesBeingReplaced++;
        }
      } else if (!activeItem.hardwareId) {
        notAllItemsIdentified = true;
      } else if (activeItem.replacing) {
        hardwareIdsBeingReplaced.push(activeItem.hardwareId);
      }
    });
    if (notAllItemsIdentified) {
      this.validationErrors.push("You must set the hardware type for all existing items on the order");
    }
    // For upgrades/downgrades the new items won't be the same types as items being replaced
    if ([CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
      // For Lost/Faulty it might be things like cables being replaced, so no existing item on the order would be flagged as being replaced
      if (hardwareIdsBeingReplaced.length == 0) {
        this.validationErrors.push("You must select one or more existing items to replace");
      }
      return;
    }
    // For free replacements (missing/faulty) or lost all items ordered should be items flagged as being replaced
    // Non-hardware/key safe items can be ignored (e.g. replacement cables) as they don't exist as separate items on the existing order
    let numberOfReplacmentKeySafes: number = 0;
    const replacementsNotMarkedAsReplacing: string[] = [];
    for (const item of this.shopifyItems) {
      if (!item.shopifyProduct.isBundle) {
        if (item.shopifyProduct.crmCategory == 'Key Safes') {
          numberOfReplacmentKeySafes++;
        } else if (item.shopifyVariant.hardware) {
          const replacementIdx: number = hardwareIdsBeingReplaced.findIndex((hardwareId: string) => hardwareId == item.shopifyVariant.hardware._id);
          if (replacementIdx == -1) {
            replacementsNotMarkedAsReplacing.push(item.shopifyVariant.hardware.title);
          } else {
            hardwareIdsBeingReplaced.splice(replacementIdx, 1);
          }
        }
      } else {
        item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
          const product: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
          const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
          if (product.crmCategory == 'Key Safes') {
            numberOfReplacmentKeySafes++;
          } else if (variant.hardware) {
            const replacementIdx: number = hardwareIdsBeingReplaced.findIndex((hardwareId: string) => hardwareId == variant.hardware._id);
            if (replacementIdx == -1) {
              replacementsNotMarkedAsReplacing.push(variant.hardware.title);
            } else {
              hardwareIdsBeingReplaced.splice(replacementIdx, 1);
            }
          }
        });
      }
    }
    if (numberOfReplacmentKeySafes < numberOfKeySafesBeingReplaced) {
      this.validationErrors.push("Replacements have not been selected for all key safes marked as being replaced");
    } else if (numberOfReplacmentKeySafes > numberOfKeySafesBeingReplaced) {
      this.validationErrors.push("More replacement key safes have been selected than marked as being replaced");
    }
    if (replacementsNotMarkedAsReplacing.length > 0) {
      this.validationErrors.push(`The order contains ${replacementsNotMarkedAsReplacing.join('; ')} which ` +
      `${replacementsNotMarkedAsReplacing.length == 1? 'is': 'are'} not marked as being replaced`);
    }
    if (hardwareIdsBeingReplaced.length > 0) {
      const unreplacedHardwareNames: string[] = [];
      hardwareIdsBeingReplaced.forEach((hardwareId: string) => {
        unreplacedHardwareNames.push(this.hardwareMapById[hardwareId].title);
      });
      this.validationErrors.push(
        `${unreplacedHardwareNames.join('; ')} marked as being replaced, but ${hardwareIdsBeingReplaced.length == 1? 'is': 'are'} not on the order`);
    }
  }

  validateAlarmUserDetails() {
    if (!this.alarmUserNameOption) {
      this.validationErrors.push("You must select an option for the Alarm User's name");
    } else if ((this.alarmUserNameOption == 'other') &&
      (!this.orderMetadata.alarmUserLastName || (this.orderMetadata.alarmUserLastName.trim() === '') ||
        !this.orderMetadata.alarmUserFirstName || (this.orderMetadata.alarmUserFirstName.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Name fields");
    }
    if (!this.alarmUserPhoneOption) {
      this.validationErrors.push("You must select an option for the Alarm User's phone number");
    } else if ((this.alarmUserPhoneOption == 'other') &&
      (!this.orderMetadata.alarmUserPhone || (this.orderMetadata.alarmUserPhone.trim() === '')) &&
      (!this.orderMetadata.alarmUserMobile || (this.orderMetadata.alarmUserMobile.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Phone number or Mobile");
    }
    if (!this.alarmUserAddressOption) {
      this.validationErrors.push("You must select an option for the Alarm User's address");
    } else if ((this.alarmUserAddressOption == 'other') &&
      ((!this.alarmUserAddress.addressOne || this.alarmUserAddress.addressOne.trim() === '') || (!this.alarmUserAddress.city || this.alarmUserAddress.city.trim() === '') ||
        (!this.alarmUserAddress.postcode || this.alarmUserAddress.postcode.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Address fields");
    }
  }

  prepareShopifyData() {
    // If no payment is due should treat as paid already
    if (['Referral – Customer will pay later', 'No Payment Required'].includes(this.orderMetadata.paymentMethod)) {
      this.shopifyOrder.financial_status = 'paid';
    }
    // In case this is run again
    this.shopifyOrder.note_attributes = [];

    this.shopifyOrder.note_attributes.push({
      'name': 'CRM PAYMENT METHOD',
      'value': this.orderMetadata.paymentMethod,
    });
    if (this.order && (this.order.legalCompany == 'Lifeline24 Ltd')) {
      this.shopifyOrder.note_attributes.push({
        'name': 'STRIPE ACCOUNT ID',
        'value': STRIPE_ACCOUNTS[this.order.legalCompany][this.selectedWebsite.title].accountId,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'LEGAL ENTITY',
        'value': this.order.legalCompany,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'BRAND CODE',
        'value': this.selectedWebsite.businessCentralLLBrandCode,
      });
    } else {
      this.shopifyOrder.note_attributes.push({
        'name': 'STRIPE ACCOUNT ID',
        'value': STRIPE_ACCOUNTS['Careline365 Ltd'][this.selectedWebsite.title].accountId,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'LEGAL ENTITY',
        'value': 'Careline365 Ltd',
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'BRAND CODE',
        'value': this.selectedWebsite.businessCentralCLBrandCode,
      });
    }

    this.shopifyOrder.note_attributes.push({
      'name': 'ORDER TYPE',
      'value': this.selectedOrderType.title,
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'SHOPIFY ORDER TYPE',
      'value': this.isExistingCustomer()? 'REPLACEMENT': 'NEW',
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'GEN PROD POSTING GROUP',
      'value': this.selectedOrderType.genProdPostingGroup,
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'VAT EXEMPT',
      'value': (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) ? 'true' : 'false',
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'ORDER TAKER',
      'value': this.userName,
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'email_marketing',
      'value': (this.shopifyCustomer.emailMarketingConsent.marketingState == CustomerEmailMarketingState.SUBSCRIBED)? 'Opted In': 'Opted Out',
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'phone_marketing',
      'value': (this.shopifyCustomer.smsMarketingConsent.marketingState == CustomerSmsMarketingState.SUBSCRIBED)? 'Opted In': 'Opted Out',
    });

    if (!['N/A/'].includes(this.orderMetadata.renewalMethod)) {
      this.shopifyOrder.note_attributes.push({
        'name': 'renewal_method',
        'value': this.orderMetadata.renewalMethod,
      });
    }

    this.shopifyOrder.total_tax = this.vatTotalAfterDiscount;
    let subscriptionPeriod: string = '';
    if (this.hasRentalItems) {
      // Only want a subscription period set if it is a new sub, or change in sub
      if (this.orderRenewalPeriod && !['', 'none'].includes(this.orderRenewalPeriod) &&
          [CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE, CalculationMethod.FULL].includes(this.selectedOrderType.calculationMethod)) {
        switch (this.orderRenewalPeriod) {
          case 'annual':
            subscriptionPeriod = '1Y';
            break;
          case 'quarterly':
            subscriptionPeriod = '1Q';
            break;
          case 'monthly':
            subscriptionPeriod = '1M';
            break;
          case 'lifetime':
            subscriptionPeriod = 'LIFE';
            break;
        }
      }
    }
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderMetadata.renewalMethod == 'goCardless');
    if (subscriptionPeriod) {
      this.shopifyOrder.note_attributes.push({
        'name': 'SUBSCRIPTION PERIOD',
        'value': subscriptionPeriod,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'GEN BUS POSTING GROUP',
        'value': 'RENTAL',
      });
    } else if (this.isExistingCustomer()) {
      this.shopifyOrder.note_attributes.push({
        'name': 'GEN BUS POSTING GROUP',
        'value': 'RENTAL',
      });
    } else {
      this.shopifyOrder.note_attributes.push({
        'name': 'GEN BUS POSTING GROUP',
        'value': 'TRADE',
      });
    }

    if (!this.differentDeliver) {
      this.shopifyOrder.shipping_address = this.shopifyOrder.billing_address;
      this.orderMetadata.shippingAddressValidated = this.orderMetadata.billingAddressValidated;
    }

    if (this.isExistingCustomer()) {
      // Set this on the order for dispatch's use
      if (this.alarmUserAddressRequired) {
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_address',
          'value': JSON.stringify(this.alarmUserAddress),
        });
      }
    } else {
      if (this.alarmUserNameOption == 'billing') {
        this.orderMetadata.alarmUserFirstName = this.shopifyOrder.billing_address.first_name;
        this.orderMetadata.alarmUserLastName = this.shopifyOrder.billing_address.last_name;
      } else if (this.alarmUserNameOption == 'delivery') {
        this.orderMetadata.alarmUserFirstName = this.shopifyOrder.shipping_address.first_name;
        this.orderMetadata.alarmUserLastName = this.shopifyOrder.shipping_address.last_name;
      }
      if (this.alarmUserPhoneOption == 'billing') {
        this.orderMetadata.alarmUserPhone = this.shopifyCustomer.phone;
        // We only capture one billing phone, so put it in the correct field
        if (this.orderMetadata.alarmUserPhone.startsWith('+447')) {
          this.shopifyOrder.note_attributes.push({
            'name': 'alarm_user_mobile',
            'value': this.orderMetadata.alarmUserPhone,
          });
        } else {
          this.shopifyOrder.note_attributes.push({
            'name': 'alarm_user_phone',
            'value': this.orderMetadata.alarmUserPhone,
          });
        }
      } else {
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_phone',
          'value': this.orderMetadata.alarmUserPhone,
        });
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_mobile',
          'value': this.orderMetadata.alarmUserMobile,
        });
      }
      this.shopifyOrder.note_attributes.push({
        'name': 'alarm_user_first_name',
        'value': this.orderMetadata.alarmUserFirstName,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'alarm_user_last_name',
        'value': this.orderMetadata.alarmUserLastName,
      });

      this.shopifyOrder.note_attributes.push({
        'name': 'alarm_user_address_option',
        'value': this.alarmUserAddressOption,
      });

      if (this.alarmUserAddressOption == 'other') {
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_address',
          'value': JSON.stringify(this.alarmUserAddress),
        });
      }
    }

    if (this.shopifyCustomer.email) {
      this.shopifyOrder.send_receipt = true;
      this.shopifyCustomer.send_email_invite = true;
    }

    // In case this is run again
    this.shopifyCustomer.addresses = [];
    this.shopifyCustomer.addresses.push(this.shopifyOrder.billing_address);
    this.shopifyCustomer.addresses.push(this.shopifyOrder.shipping_address);
    this.shopifyOrder.note_attributes.push({
      'name': 'billing_address_validated',
      'value': this.orderMetadata.billingAddressValidated? 'Yes': 'No',
    });
    this.shopifyOrder.note_attributes.push({
      'name': 'shipping_address_validated',
      'value': this.orderMetadata.shippingAddressValidated? 'Yes': 'No',
    });

    if (this.order) {
      this.shopifyOrder.note += `\nExisting Order TD Code: ${this.order.alarmUserDetails.tdCode}\nCompany: ${this.order.legalCompany}`;
      this.shopifyOrder.note_attributes.push({
        'name': 'existing_order_td_code',
        'value': this.order.alarmUserDetails.tdCode,
      });
    }

    if (this.isExistingCustomer()) {
      if (this.isAdditional()) {
        this.shopifyOrder.note += '\nAdditional Equipment, not replacement';
      } else {
        this.shopifyOrder.note += `\nReason for Replacement: ${this.selectedOrderType.title}`;
      }
    }
    if (this.isReplacementDueToFault()) {
      this.shopifyOrder.note += `\nFault: ${this.baseOrderInformation.cseOrder.fault} ${this.baseOrderInformation.cseOrder.otherFault}`;
    }

    // In case this is run again
    this.shopifyOrder.discount_codes = [];
    if (this.discount && this.discount.code && (this.discountAmount > 0)) {
      // Have to express all discounts as fixed amount as Shopify will apply % discounts to items they don't apply to
      this.shopifyOrder.discount_codes.push({
        'code': this.discount.code,
        'amount': this.discountAmount,
        'type': 'fixed_amount',
      });
    }

    // In case this is run again
    this.shopifyOrder.line_items = [];
    this.baseOrderInformation.cseOrder.cseOrderItems = [];
    this.shopifyItems.forEach((item: ShopifyBasketItem, itemIdx: number) => {
      const originalPrice: number = item.originalPrice * item.quantity;
      // total field is unit price after discounts
      const finalPrice: number = item.unitTotalIncVat * item.quantity;

      if (!item.shopifyProduct.isBundle) {
        let shortProductId: string = item.shopifyProduct.shopifyProductId;
        if (/^gid:\/\/shopify\/Product\//.test(shortProductId)) {
          shortProductId = shortProductId.replace('gid://shopify/Product/', '');
        }
        let shortVariantId: string = item.shopifyVariant.shopifyVariantId;
        if (/^gid:\/\/shopify\/ProductVariant\//.test(shortVariantId)) {
          shortVariantId = shortVariantId.replace('gid://shopify/ProductVariant/', '');
        }
        // Shopify needs the price before discounts - it will apply these as part of order creation
        const shopifyOrderLine: ShopifyRestOrderLine = {
          'product_id': shortProductId,
          'variant_id': shortVariantId,
          'quantity': item.quantity,
          'price': item.unitSubtotalIncVat,
          'tax_lines': [],
        };
        if (item.shopifyVariant.taxable) {
          // But for some reason shopify needs the Tax after discount
          shopifyOrderLine.tax_lines.push({
            'channel_liable': false,
            'rate': VAT_MULTIPLIER,
            'title': 'GB VAT',
            'price': item.lineTotalVat,
          });
        }
        this.shopifyOrder.line_items.push(shopifyOrderLine);
        this.baseOrderInformation.cseOrder.cseOrderItems.push({
          'cseOrderLine': itemIdx + 1,
          'cseOrderLinePart': 1,
          'cseOrderTitle': item.basketTitle,
          'originalPrice': originalPrice,
          'finalPrice': finalPrice,
          'finalVat': item.lineTotalVat,
          'quantity': item.quantity,
          'shopifyProductId': item.shopifyProduct.shopifyProductId,
          'shopifyVariantId': item.shopifyVariant.shopifyVariantId,
          'hardwareId': item.shopifyVariant.hardware? item.shopifyVariant.hardware._id: null,
        });
      } else {
        // Need to get the total of the parts we want to allocate to, so we can allocate price proportionally
        let totalPriceOfItems: number = 0.00;
        let totalPriceOfItemsToAllocateTo: number = 0.00;
        let totalItems: number = 0;
        let totalItemsToAllocateTo: number = 0;
        let partId: number = 1;
        let hasSubscriptionPart: boolean = false;
        item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
          let shortProductId: string = svc.productVariant.product.id;
          if (/^gid:\/\/shopify\/Product\//.test(shortProductId)) {
            shortProductId = shortProductId.replace('gid://shopify/Product/', '');
          }
          let shortVariantId: string = svc.productVariant.id;
          if (/^gid:\/\/shopify\/ProductVariant\//.test(shortVariantId)) {
            shortVariantId = shortVariantId.replace('gid://shopify/ProductVariant/', '');
          }
          const product: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
          const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
          const isSubscription: boolean = (product.crmCategory == 'Subscriptions');
          const isSoldItem: boolean = product.tags.includes('sold-item');
          if (isSubscription) {
            hasSubscriptionPart = true;
          }
          totalPriceOfItems += variant.basketPrice;
          totalItems++;
          // TODO Logic might need changing depending on discussion about discounting the watch
          if (this.isExistingCustomer()) {
            if ([CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
              // All value should be assigned to the subscription(s)/sold items in proportion
              if (isSubscription || isSoldItem) {
                totalPriceOfItemsToAllocateTo += variant.basketPrice;
                totalItemsToAllocateTo++;
              }
            } else if (!isSubscription) {
              // For lost or free (if overridden to have a price) products should instead get the price
              totalPriceOfItemsToAllocateTo += variant.basketPrice;
              totalItemsToAllocateTo++;
            }
          } else if (isSubscription || isSoldItem) {
            totalPriceOfItemsToAllocateTo += variant.basketPrice;
            totalItemsToAllocateTo++;
          }
        });
        let totalLeftToAllocate: number = item.unitSubtotalIncVat;
        // Need to loop through again now we know the total for the proportioning
        item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
          let shortProductId: string = svc.productVariant.product.id;
          if (/^gid:\/\/shopify\/Product\//.test(shortProductId)) {
            shortProductId = shortProductId.replace('gid://shopify/Product/', '');
          }
          let shortVariantId: string = svc.productVariant.id;
          if (/^gid:\/\/shopify\/ProductVariant\//.test(shortVariantId)) {
            shortVariantId = shortVariantId.replace('gid://shopify/ProductVariant/', '');
          }
          const product: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
          const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];

          let priceMultiplier: number = 0;
          if (!hasSubscriptionPart) {
            // no sub part so all parts are products, so use the total of the items
            if (totalPriceOfItems > 0) {
              priceMultiplier = variant.basketPrice / totalPriceOfItems;
            } else {
              // have to split price evenly as have no other data to work with
              priceMultiplier = 1 / totalItems;
            }
          } else {
            const isSoldItem: boolean = product.tags.includes('sold-item');
            const isSubscription: boolean = product.crmCategory == 'Subscriptions';
            if (this.isExistingCustomer()) {
              // no setup fee for existing customers
              if ([CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
                // All value should be assigned to the subscription(s) and sold items
                if (isSubscription || isSoldItem) {
                  // All subs should have value, so shouldn't be able to divide by zero here
                  priceMultiplier = variant.basketPrice / totalPriceOfItemsToAllocateTo;
                }
              } else if (isSubscription) {
                // We don't want to have subscription items for orders that don't affect plan code
                return;
              } else {
                if (totalPriceOfItemsToAllocateTo > 0) {
                  priceMultiplier = variant.basketPrice / totalPriceOfItemsToAllocateTo;
                } else {
                  // have to split price evenly as have no other data to work with
                  priceMultiplier = 1 / totalItemsToAllocateTo;
                }
              }
            } else if (isSubscription || isSoldItem) {
              priceMultiplier = variant.basketPrice / totalPriceOfItemsToAllocateTo;
            }
          }
          // Divide by svc quantity as it will multiple up again as this is a unit price
          let lineTotalIncVat: number = (item.unitSubtotalIncVat * priceMultiplier);
          // We cannot use the index of the item within the array to work out when we get to the last item as the last item might not be one we are 
          // allocating value to. Instead if the amount left to allocate is within a tollerance of the lineTotalIncVat then allocate the remaining 
          // amount to this line to stop a discrepancy in the total
          if (Math.abs(lineTotalIncVat - totalLeftToAllocate) < 0.02) {
            lineTotalIncVat = totalLeftToAllocate;
          }
          const unitPrice: number = roundToTwoDecimalPlaces(lineTotalIncVat / svc.quantity);
          totalLeftToAllocate = totalLeftToAllocate - roundToTwoDecimalPlaces(unitPrice * svc.quantity);
          // Tax is not at unit level
          const totalVat: number = roundToTwoDecimalPlaces(item.lineTotalVat * priceMultiplier);
          const originalPrice: number = roundToTwoDecimalPlaces(item.originalPrice * item.quantity * priceMultiplier);
          const finalPrice: number = roundToTwoDecimalPlaces(item.unitTotalIncVat * item.quantity * priceMultiplier);
          const shopifyOrderLine: ShopifyRestOrderLine = {
            'product_id': shortProductId,
            'variant_id': shortVariantId,
            'quantity': item.quantity * svc.quantity,
            'price': unitPrice,
            'tax_lines': [],
          };
          if ((unitPrice > 0) && item.shopifyVariant.taxable) {
            shopifyOrderLine.tax_lines.push({
              'channel_liable': false,
              'rate': VAT_MULTIPLIER,
              'title': 'GB VAT',
              'price': totalVat,
            });
          }
          this.baseOrderInformation.cseOrder.cseOrderItems.push({
            'cseOrderLine': itemIdx + 1,
            'cseOrderLinePart': partId,
            'cseOrderTitle': getTitleFromProductAndVariant(product, variant),
            'originalPrice': originalPrice,
            'finalPrice': finalPrice,
            'finalVat': totalVat,
            'quantity': item.quantity * svc.quantity,
            'shopifyProductId': product.shopifyProductId,
            'shopifyVariantId': variant.shopifyVariantId,
            'hardwareId': item.shopifyVariant.hardware? item.shopifyVariant.hardware._id: null,
          });
          this.shopifyOrder.line_items.push(shopifyOrderLine);
          partId++;
        });
      }
    });

    // Use this as the indicator to whether the brand is using another brand's shopify site
    if (!this.selectedWebsite.allowNewCustomerOrders) {
      const brandNameParts: string[] = this.selectedWebsite.businessCentralCLBrandCode.split(/\s/);
      if (brandNameParts && (brandNameParts.length > 0)) {
        this.shopifyOrder.tags = `${this.shopifyOrder.tags}, ${brandNameParts[0]}`;
      }
    }
    console.log('Data to be sent to website', this.shopifyOrder);
  }

  async createPaymentMethod() {
    this.showPlaceOrder = false;
    this.showProgressBar = true;
    this.processingError = null;
    /*
      Even if 'Creating Payment Method on Stripe' completed we need to redo it to get the paymentMethod id for
      'Creating Payment Intent on Stripe' if that step has not been completed
    */
    if (this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      this.captureOrderDetailsForReporting();
    } else {
      this.currentStep = 'Creating Payment Method on Stripe';
      const paymentMethodResult: PaymentMethodResult = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });
      try {
        if (paymentMethodResult.error) {
          this.processingError = paymentMethodResult.error;
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = paymentMethodResult.paymentMethod.id;
          this.shopifyOrder.note_attributes.push({
            'name': 'STRIPE PAY METHOD ID',
            'value': paymentMethodResult.paymentMethod.id,
          });
          this.createPaymentIntent();
        }
      } catch (error: any) {
        this.processingError = {'message': error.message};
        console.error('Payment processing error', this.processingError);
        this.showProgressBar = false;
      };
    }
  }

  createPaymentIntent() {
    const custName: string = `${this.shopifyCustomer.first_name} ${this.shopifyCustomer.last_name}`;
    let paymentDescription: string = '';
    let customerDescription: string = custName;
    const metadata: StringIndexedObject<string> = {};
    if (this.order) {
      paymentDescription = `${this.order.alarmUserDetails.tdCode} existing customer order`;
      customerDescription = `${this.order.alarmUserDetails.tdCode} ${custName}`;
      metadata.tdCode = this.order.alarmUserDetails.tdCode;
    } else {
      paymentDescription = `${custName} - CSE Order`;
    }
    const createPaymentIntentRequest: CreatePaymentIntentRequest = {
      'stripeBrand': this.stripeBrand,
      'amount': this.orderTotal.toFixed(2),
      'currency': this.currencyCode.toLocaleLowerCase(),
      'description': paymentDescription,
      'customer_name': this.orderMetadata.nameOnCard,
      'paymentMethod': this.processingSteps['Creating Payment Method on Stripe'].resultData,
      'billing_details': {
        'address': {
          'line1': this.shopifyOrder.billing_address.address1,
          'line2': this.shopifyOrder.billing_address.address2,
          'city': this.shopifyOrder.billing_address.city,
          'postal_code': this.shopifyOrder.billing_address.zip,
        },
        'name': custName,
        'phone': this.shopifyCustomer.phone,
        'description': customerDescription,
        'metadata': metadata,
      },
    };
    if (this.shopifyCustomer.email) {
      createPaymentIntentRequest.billing_details.email = this.shopifyCustomer.email;
    }
    if ((this.order && this.order.accountDetails.stripeCustomerId && this.order.accountDetails.stripeCustomerId.startsWith('cus_'))) {
      createPaymentIntentRequest.customer_id = this.order.accountDetails.stripeCustomerId;
    }
    this.currentStep = 'Creating Payment Intent on Stripe';
    this.orderService.createOrderPaymentIntent(createPaymentIntentRequest).subscribe({
      next: (rsp: SingleRecordResponse<ServerSideStripe.PaymentIntent>) => {
        console.log(rsp);
        if (!rsp.success) {
          const error: StripeError & {raw: any} = rsp.error;
          this.processingError = {'message': rsp.message, 'code': error.code};
          // error.type is StripeCardError typing says it should be card_error, which is what the type on the raw has
          this.processingSteps[this.currentStep].allowToBeSkipped = (error.raw && (error.raw.type != 'card_error'));
          this.showProgressBar = false;
          return;
        } else if (rsp.data.status != 'succeeded') {
          this.processingError = {'message': 'Payment Intent created, but not at "succeeded" status'};
          this.processingSteps[this.currentStep].allowToBeSkipped = false;
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = rsp.data.id;
          this.shopifyOrder.note_attributes.push({
            'name': 'STRIPE CUSTOMER ID',
            'value': (rsp.data.customer as string),
          });
          this.shopifyOrder.note_attributes.push({
            'name': 'STRIPE PAYMENT INTENT ID',
            'value': rsp.data.id,
          });
          this.shopifyOrder.financial_status = 'paid';
          this.shopifyOrder.transactions.push({
            'kind': 'sale',
            'status': 'success',
            'amount': this.orderTotal,
          });
          if (rsp.data.latest_charge) {
            this.shopifyOrder.note_attributes.push({
              'name': 'STRIPE PAYMENT ID',
              'value': (rsp.data.latest_charge as ServerSideStripe.Charge).id,
            });
            if ((rsp.data.latest_charge as ServerSideStripe.Charge).balance_transaction) {
              const balanceTransaction: ServerSideStripe.BalanceTransaction =
                ((rsp.data.latest_charge as ServerSideStripe.Charge).balance_transaction as ServerSideStripe.BalanceTransaction);
              if (balanceTransaction.fee) {
                this.shopifyOrder.note_attributes.push({
                  'name': 'STRIPE FEE',
                  'value': roundToTwoDecimalPlaces(balanceTransaction.fee / 100.00).toFixed(2),
                });
              }
            }
          }
          this.captureOrderDetailsForReporting();
        }
      },
      error: (err: any) => {
        console.error('Error creating payment intent on stripe', err);
        this.processingError = {'message': err.message};
        console.error('Something is wrong: V2', this.processingError);
        this.showProgressBar = false;
      }
    });
  }

  captureOrderDetailsForReporting() {
    this.showProgressBar = true;
    this.showPlaceOrder = false;
    this.processingError = null;
    const stepName: string = 'Capturing Details for Reporting';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.findOrCreateShopifyCustomer();
    } else {
      this.currentStep = stepName;
      this.baseOrderInformation.cseOrder.orderType = this.selectedOrderType.title;
      this.baseOrderInformation.cseOrder.originalOrderTotal = this.totalBeforeDiscountAndOverride;
      this.baseOrderInformation.cseOrder.finalOrderTotal = this.orderTotal;
      this.baseOrderInformation.cseOrder.finalOrderVat = this.vatTotalAfterDiscount;
      this.baseOrderInformation.cseOrder.couponCode = (this.discount)? this.discount.code: '';
      this.baseOrderInformation.cseOrder.couponAmount = this.discountAmount;
      this.baseOrderInformation.cseOrder.partnership = this.selectedPartnership? this.selectedPartnership.bgcName: '',
      this.baseOrderInformation.cseOrder.billingPostcode = this.shopifyOrder.billing_address.zip;
      this.baseOrderInformation.cseOrder.leadId =
        this.baseOrderInformation.cseOrderNavigationData? this.baseOrderInformation.cseOrderNavigationData.leadId: null;
      this.baseOrderInformation.cseOrder.paymentMethod = this.orderMetadata.paymentMethod;
      if (!this.baseOrderInformation.proRataMultiplier || (this.baseOrderInformation.proRataMultiplier <= 0)) {
        if (!this.baseOrderInformation.cseOrder.proRataCurrentPrice || (this.baseOrderInformation.cseOrder.proRataCurrentPrice <= 0) ||
            (this.selectedOrderType.calculationMethod != CalculationMethod.DIFFERENCE)) {
          this.baseOrderInformation.cseOrder.proRataCurrentPrice = 0;
        }
        this.baseOrderInformation.cseOrder.proRataMultiplier = '';
        this.baseOrderInformation.cseOrder.proRataToDate = '';
      } else {
        this.baseOrderInformation.cseOrder.proRataMultiplier = this.baseOrderInformation.proRataMultiplier.toFixed(6);
      }
      if (this.alarmUserAddressOption == 'other') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.alarmUserAddress.postcode;
      } else if (this.alarmUserAddressOption == 'delivery') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.shopifyOrder.shipping_address.zip;
      } else if (this.alarmUserAddressOption == 'billing') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.shopifyOrder.billing_address.zip;
      }
      if (this.selectedOrderType.title == 'Phone Order') {
        if (this.baseOrderInformation.withheldNumber) {
          this.baseOrderInformation.cseOrder.calledFrom = 'Withheld Number';
        }
      } else {
        this.baseOrderInformation.cseOrder.calledFrom = '';
      }
      this.orderService.createRecordOfCseOrder({cseOrder: this.baseOrderInformation.cseOrder}).subscribe({
        next: (cseOrderResponse: SingleRecordResponse<CseOrder>) => {
          if (!cseOrderResponse.success) {
            console.error('Error recording CSE order. Error:', cseOrderResponse.message);
            this.processingError = cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.processingSteps[stepName].resultData = cseOrderResponse.data._id;
            this.processingSteps[stepName].completed = true;
            this.shopifyOrder.note_attributes.push({
              'name': 'cse_order_id',
              'value': cseOrderResponse.data._id,
            });
            this.findOrCreateShopifyCustomer();
          }
        },
        error: (err: any) => {
          console.error('Error recording CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  findOrCreateShopifyCustomer() {
    const stepName: string = 'Finding or Creating Customer on Alarm Website';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.createOrderOnShopify();
    } else {
      this.currentStep = stepName;
      if (this.order) {
        this.orderService.getExternalIdForOrderAndExactName(this.order._id, 'Shopify Customer').subscribe({
          next: (customerIdResponse: SingleRecordResponse<ExternalId>) => {
            if (!customerIdResponse.success) {
              if (customerIdResponse.message == 'External Id not found for order') {
                // No id saved, so do a search for the customer
                this.findShopifyCustomer();
              } else {
                console.error('Error getting external id for customer. Error:', customerIdResponse.message);
                this.processingError = customerIdResponse.error? customerIdResponse.error: {'message': customerIdResponse.message};
                this.showProgressBar = false;
              }
            } else {
              this.processingSteps[this.currentStep].completed = true;
              this.processingSteps[this.currentStep].resultData = customerIdResponse.data.idValue;
              this.createOrderOnShopify();
            }
          },
          error: (error: any) => {
            console.error('Error searching for external id for customer. Error:', error);
            this.processingError = error;
            this.showProgressBar = false;
          }
        });
      } else {
        // No external id, but try to find the customer in case they already exist from another order
        this.findShopifyCustomer();
      }
    }
  }

  createExternalIdForShopifyCustomer(shopifyCustId: string, matchedData: string) {
    // Partial as has no id nor created/updated dates
    const externalId: Partial<ExternalId> = {
      'orderId': this.order._id,
      'idName': 'Shopify Customer',
      'idValue': shopifyCustId,
      'dataUsedToMatchInternal': matchedData,
      'dataUsedToMatchExternal': matchedData,
      'tryCount': 0,
    };
    this.orderService.createExternalId(externalId).subscribe({
      next: (externalIdResponse: SingleRecordResponse<ExternalId>) => {
        if (!externalIdResponse.success) {
          console.error('Error saving external id for customer. Error:', externalIdResponse.message);
          this.processingError = externalIdResponse.error? externalIdResponse.error: {'message': externalIdResponse.message};
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = shopifyCustId;
          this.createOrderOnShopify();
        }
      },
      error: (error: any) => {
        console.error('Error saving external id for customer. Error:', error);
        this.processingError = error;
        this.showProgressBar = false;
      }
    });
  }

  findShopifyCustomer() {
    this.orderService.findShopifyCustomer(this.selectedWebsite._id, this.shopifyCustomer.phone, this.shopifyCustomer.email).subscribe({
      next: (findCustResponse: SingleRecordResponse<number>) => {
        if (!findCustResponse.success) {
          if (findCustResponse.message == 'No match found') {
            // No customer found, so can create one
            this.createShopifyCustomer();
          } else {
            console.error('Error searching for existing Shopify customer. Error:', findCustResponse.message);
            this.processingError = findCustResponse.error? findCustResponse.error: {'message': findCustResponse.message};
            this.showProgressBar = false;
          }
        } else if (this.order) {
          // We have an order, so can save the id
          const matchedData: string = findCustResponse.message.includes('email')? this.shopifyCustomer.email: this.shopifyCustomer.phone;
          this.createExternalIdForShopifyCustomer(`${findCustResponse.data}`, matchedData);
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = `${findCustResponse.data}`;
          this.createOrderOnShopify();
        }
      },
      error: (error: any) => {
        console.error('Error searching for existing Shopify customer. Error:', error);
        this.processingError = error;
        this.showProgressBar = false;
      }
    });
  }

  createShopifyCustomer() {
    this.orderService.createShopifyCustomer(this.selectedWebsite._id, this.shopifyCustomer).subscribe({
      next: (createCustResponse: SingleRecordResponse<number>) => {
        if (!createCustResponse.success) {
          console.error('Error creating Shopify customer. Error:', createCustResponse.message);
          this.processingError = createCustResponse.error? createCustResponse.error: {'message': createCustResponse.message};
          this.showProgressBar = false;
        } else if (this.order) {
          this.createExternalIdForShopifyCustomer(`${createCustResponse.data}`, 'created from order page');
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = `${createCustResponse.data}`;
          this.createOrderOnShopify();
        }
      },
      error: (error: any) => {
        console.error('Error creating Shopify customer. Error:', error);
        this.processingError = error;
        this.showProgressBar = false;
      }
    });
  }

  createOrderOnShopify() {
    if (this.processingSteps[this.ORDER_CREATION_STEP].completed) {
      this.findOrderAndUpdateCseOrderRecord();
    } else {
      this.currentStep = this.ORDER_CREATION_STEP;
      // Populate the customer id we got back from the find/create stage
      this.shopifyOrder.customer.id = this.processingSteps['Finding or Creating Customer on Alarm Website'].resultData;
      this.orderService.createShopifyOrder(this.selectedWebsite._id, this.shopifyOrder).subscribe({
        next: (rsp: SingleRecordResponse<number>) => {
          if (!rsp.success) {
            console.error('Failed to create order on Alarm website', rsp.message);
            this.processingError = !!rsp.error? rsp.error: {'message': rsp.message};
            this.showProgressBar = false;
            return;
          }
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = `${rsp.data}`;
          const storeName: string = this.selectedWebsite.shopify.storeDomain.replace(/^([^.]+)\..*/, '$1');
          this.orderLink = `https://admin.shopify.com/store/${storeName}/orders/${rsp.data}`;
          this.findOrderAndUpdateCseOrderRecord();
        },
        error: (err: any) => {
          console.error('Error creating order on alarm website', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  findOrderAndUpdateCseOrderRecord() {
    const stepName: string = 'Updating Reporting Record';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.captureDdInformation();
    } else {
      this.currentStep = stepName;
      const shopifyOrderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      this.orderService.updateCseOrderRecord({
        cseOrderUpdate: {
          '_id': this.processingSteps['Capturing Details for Reporting'].resultData,
          'websiteOrderId': shopifyOrderId,
        }
      }).subscribe({
        next: (cseOrderResponse: SimpleResponse) => {
          if (!cseOrderResponse.success) {
            console.error('Error updating CSE order. Error:', cseOrderResponse.message);
            this.processingError = !!cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.captureDdInformation();
          }
        },
        error: (err: any) => {
          console.error('Error updating CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  async captureDdInformation() {
    const stepName: string = 'Creating Direct Debit Form';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.showProgressBar = false;
      this.orderCreationSuccess = true;
      this.currentStep = '';
      if (!this.isExistingCustomer()) {
        const shopifyOrderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
        // Delay for a few seconds to allow webhook to have been called
        await crmDelay(5000);
        this.orderService.findOrder({
          'orderId': shopifyOrderId,
          'initialOrderDetails.orderSource': 'Shopify',
          'website': this.selectedWebsite._id,
          'deleted': false
        }).subscribe({
          'next': (findResponse: FindOrderResponse) => {
            if (!findResponse.success || (findResponse.orders.length == 0)) {
              this.processingError = {'message': 'Order not found in CRM'};
              this.showProgressBar = false;
            } else {
              this.router.navigate(['/order/' + findResponse.orders[0]._id]);
            }
          },
          'error': (error: any) => {
            this.processingError = error;
            this.showProgressBar = false;
          }
        });
      }
    } else {
      this.currentStep = stepName;
      const shopifyOrderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      if (this.processingSteps['Creating Direct Debit Form'].resultData && 
          this.processingSteps['Creating Direct Debit Form'].resultData.startsWith('https')) {
        window.location.href = this.processingSteps['Creating Direct Debit Form'].resultData;
      } else {
        this.orderService.getDDForm({
          'shopifyOrderId': shopifyOrderId,
          'brand': this.selectedWebsite.title,
          'company': this.order? this.order.legalCompany: 'Careline365 Ltd',
          'firstName': this.shopifyCustomer.first_name,
          'lastName': this.shopifyCustomer.last_name,
          'email': this.shopifyCustomer.email,
          'currencyCode': this.currencyCode,
          'crmOrderId': this.isExistingCustomer()? this.order['_id']: 'new',
          'paymentStatus': this.shopifyOrder.financial_status,
          'addressLine1': this.shopifyOrder.billing_address.address1,
          'addressLine2': this.shopifyOrder.billing_address.address2,
          'city': this.shopifyOrder.billing_address.city,
          'postCode': this.shopifyOrder.billing_address.zip,
          'countryCode': this.shopifyOrder.billing_address.country_code,
        }).subscribe({
          next: (response: SingleRecordResponse<string>) => {
            if (response.success) {
              this.showPopUp('dd', 'Reminder', `Remember to tell the customer about direct debit guarantee.\n${DD_GUARANTEE}`,
                'pi pi-info-circle', () => {window.location.href = response.message}, () => {window.location.href = response.message}, false
              );
              this.processingSteps['Creating Direct Debit Form'].resultData = response.message;
              this.currentStep = '';
            } else {
              this.processingError = {'message': 'Error preparing GoCardless/Direct Debit Form'};
              this.displayErrorDetails = true;
              this.showProgressBar = false;
            }
          },
          error: (err: any) => {
            console.error(`Thrown Error getting DD form`, err);
            this.processingError = err;
            this.displayErrorDetails = true;
            this.showProgressBar = false;
          }
        });
      }
    }
  }

  checkPaymentReference() {
    this.currentStep = 'Checking User Supplied Payment Reference';
    this.processingSteps['Creating Payment Intent on Stripe'].resultData = this.processingSteps['Creating Payment Intent on Stripe'].resultData.trim();
    const paymentIntentRef: string = this.processingSteps['Creating Payment Intent on Stripe'].resultData;
    if (!paymentIntentRef.startsWith('pi_')) {
      this.processingError = {
        'message': 'Invalid payment reference - it must start with pi_',
      };
      this.showProgressBar = false;
      this.displayErrorDetails = false;
      return;
    }
    this.orderService.getPaymentIntent(this.stripeBrand, paymentIntentRef).subscribe({
      next: (response: SingleRecordResponse<ServerSideStripe.PaymentIntent>) => {
        if (response.success) {
          if (response.data.status != 'succeeded') {
            this.processingError = {
              'message': `Payment Intent ${paymentIntentRef} is not at "succeeded" status. Payment must be successful to proceed`
            };
            this.displayErrorDetails = true;
            this.showProgressBar = false;
          } else {
            this.processingSteps['Creating Payment Intent on Stripe'].completed = true;
            this.captureOrderDetailsForReporting();
          }
        } else {
          console.error(`Error getting payment intent for pi_ reference ${paymentIntentRef}`, response.error);
          const error: StripeError = response.error;
          this.processingError = {'message': response.message, 'code': error.code};
          this.displayErrorDetails = true;
          this.showProgressBar = false;
        }
      },
      error: (err: any) => {
        console.error(`Thrown Error getting payment intent for pi_ reference ${paymentIntentRef}`, err);
        this.processingError = err;
        this.displayErrorDetails = true;
        this.showProgressBar = false;
      }
    });
  }

  checkOrderId() {
    this.currentStep = 'Checking User Supplied OrderId';
    const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
    if (!/^\d{13}$/.test(orderId)) {
      this.processingError = {
        'message': 'Invalid order id - it must be a 13 digit long number',
      };
      this.showProgressBar = false;
      this.displayErrorDetails = false;
      return;
    }
    this.orderService.checkShopifyOrderId(this.selectedWebsite._id, orderId).subscribe({
      next: (rsp: SingleRecordResponse<number>) => {
        if (rsp.success) {
          this.processingSteps[this.ORDER_CREATION_STEP].completed = true;
          const storeName: string = this.selectedWebsite.shopify.storeDomain.replace(/^([^.]+)\..*/, '$1');
          this.orderLink = `https://admin.shopify.com/store/${storeName}/orders/${rsp.data}`;
          this.captureOrderDetailsForReporting();
        } else {
          console.error(`Error checking Shopify order id ${orderId}`, !!rsp.error? rsp.error: rsp.message);
          if (!!rsp.error) {
            this.processingError = rsp.error;
          } else {
            this.processingError = {'message': rsp.message};
          }
          this.showProgressBar = false;
          this.displayErrorDetails = true;
        }
      },
      error: (err: any) => {
        console.error(`Thrown Error checking Shopify order id ${orderId}`, err);
        this.processingError = err;
        this.showProgressBar = false;
        this.displayErrorDetails = true;
      }
    });
  }

  retryOrder() {
    // clear the previous error
    this.processingError = null;
    this.showProgressBar = true;
    this.displayErrorDetails = false;
    if ([this.ORDER_CREATION_STEP, 'Checking User Supplied OrderId'].includes(this.currentStep)) {
      if (this.processingSteps[this.ORDER_CREATION_STEP].resultData) {
        this.checkOrderId();
        return;
      }
    }
    if (this.processingSteps['Creating Payment Intent on Stripe'].required
        && !this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      if (this.processingSteps['Creating Payment Intent on Stripe'].resultData) {
        this.checkPaymentReference();
      } else {
        // Card order, with payment not taken so go down that route
        this.createPaymentMethod();
      }
    } else {
      this.captureOrderDetailsForReporting();
    }
  }

  searchReferral() {
    this.referralTdFound = false;
    this.referralTdSearched = false;
    this.referralTDCode = this.referralTDCode.trim().toLocaleUpperCase();

    if (!this.referralTDCode) {
      return;
    }
    this.orderService.getOrderTdCode(this.referralTDCode, this.selectedWebsite._id).subscribe({
      'next': (response: OrderResponse) => {
        this.referralTdSearched = true;
        if (response.success && !!response.order) {
          this.referralTdFound = true;
          this.baseOrderInformation.cseOrder.referringAccount = this.referralTDCode;
        } else {
          console.error('Order not found');
        }
      },
      'error': (err: any) => {
        this.referralTdSearched = true;
        console.error('Error finding existing order from TD Code', err);
      }
    });
  }

  refresh(): void {
    window.location.reload();
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string, acceptCallback?: () => void, rejectCallback?: () => void,
      isRejectLabelVisible: boolean = false): void {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: isRejectLabelVisible,
      acceptLabel: 'OK',
      icon: icon,
      accept: () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }
}
