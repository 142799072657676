<p-dialog (onHide)="closeDialog()" header="Business Central Data" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '95vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div class="container-fluid" id='businessCentral'>
    <div *ngIf="loading" class="d-flex justify-content-center spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <ng-container *ngIf='bcDataForCustomer'>
      <div class='row'>
        <div class='col-12'>
          <h4>Business Central Customer</h4>
        </div>
        <ng-container *ngIf='bcDataForCustomer.customer.error; else noCustError'>
          <div class='col-12'>
            <div class='alert alert-danger'>
              Error loading customer data.
            </div>
          </div>
        </ng-container>
        <ng-template #noCustError>
          <ng-container *ngIf='!bcDataForCustomer.customer.data; else custFound'>
            <div class='col-12'>
              <div class='alert alert-warn'>
                Customer not found in Business Central.
              </div>
            </div>
          </ng-container>
          <ng-template #custFound>
            <div class='col-4'>
              <label>Customer Name</label>
              {{bcDataForCustomer.customer.data.Name}}
            </div>
            <div class='col-2'>
              <label>Postcode</label>
              {{bcDataForCustomer.customer.data.Post_Code}}
            </div>
            <div class='col-2'>
              <label>Blocked?</label>
              {{bcDataForCustomer.customer.data.Blocked}}
            </div>
            <div class='col-2'>
              <label>Posting Group</label>
              {{bcDataForCustomer.customer.data.Gen_Bus_Posting_Group}}
            </div>
            <div class='col-2'>
              <label>VAT Group</label>
              {{bcDataForCustomer.customer.data.VAT_Bus_Posting_Group}}
            </div>
            <div class='col-2 offset-8'>
              <div class='alert' [ngClass]="{
                'alert-success' : (bcDataForCustomer.customer.data.Balance_LCY === 0),
                'alert-warning' : (bcDataForCustomer.customer.data.Balance_LCY < 0),
                'alert-danger' : (bcDataForCustomer.customer.data.Balance_LCY > 0),
              }">
                <label>Balance</label>
                {{bcDataForCustomer.customer.data.Balance_LCY | number: '1.2-2'}}
              </div>
            </div>
            <div class='col-2'>
              <div class='alert' [ngClass]="{
                'alert-success' : (bcDataForCustomer.customer.data.Balance_Due_LCY === 0),
                'alert-warning' : (bcDataForCustomer.customer.data.Balance_Due_LCY < 0),
                'alert-danger' : (bcDataForCustomer.customer.data.Balance_Due_LCY > 0),
              }">
                <label>Balance Due</label>
                {{bcDataForCustomer.customer.data.Balance_Due_LCY | number: '1.2-2'}}
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <ng-container *ngIf='!bcDataForCustomer.customer.error && bcDataForCustomer.customer.data'>
        <div class='row'>
          <div class='col-12'>
            <h4>Contracts</h4>
          </div>
          <ng-container *ngIf='bcDataForCustomer.contracts.error; else noContractError'>
            <div class='col-12'>
              <div class='alert alert-danger'>
                Error loading contract data.
              </div>
            </div>
          </ng-container>
          <ng-template #noContractError>
            <ng-container *ngIf='!bcDataForCustomer.contracts.data; else contractsFound'>
              <div class='col-12'>
                <div class='alert alert-info'>
                  No contracts found in Business Central.
                </div>
              </div>
            </ng-container>
            <ng-template #contractsFound>
              <div class='col-12'>
                <p-selectButton [options]='contractOptions' [(ngModel)]='selectedContractOption' (onChange)="contractOptionChange()"/>
                <p-table #contractTable [value]='bcDataForCustomer.contracts.data' [paginator]="true" [rows]="5" dataKey="No"
                    [columns]='contractColumns' [sortMode]='"single"' [sortField]="'No'" [sortOrder]=-1 [showCurrentPageReport]="true"
                    currentPageReportTemplate="Page {currentPage} of {totalPages} - Contracts {first} to {last} of {totalRecords}">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th class='toggleColumn'>&nbsp;</th>
                      <ng-container *ngFor="let contractCol of columns">
                        <th [pSortableColumn]="contractCol.field">
                          {{contractCol.header}}
                          <p-sortIcon [field]="contractCol.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order" 
                            ariaLabelAsc="Activate to sort in ascending order">
                          </p-sortIcon>
                        </th>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-contract let-expanded='expanded' let-columns='columns'>
                    <tr>
                      <td>
                        <button type="button" pButton pRipple [pRowToggler]="contract" class="p-button-plain"
                          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                        </button>
                      </td>
                      <ng-container *ngFor="let contractCol of columns">
                        <ng-container [ngSwitch]="contractCol.field">
                          <td *ngSwitchCase="'Active'">{{contract.Active? 'Yes': 'No'}}</td>
                          <td *ngSwitchDefault>
                            {{contract[contractCol.field]}}
                          </td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-contract>
                    <tr>
                      <td>&nbsp;</td>
                      <td [attr.colspan]="contractColumns.length">
                        <p-table #commitmentTable [value]="contract.ServiceCommitments.data" [columns]="commitmentColumns"
                            [sortMode]='"single"' [sortField]="'ServiceObjectNo'" [sortOrder]=-1>
                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <ng-container *ngFor="let commitmentCol of columns">
                                <th [pSortableColumn]="commitmentCol.field">
                                  {{commitmentCol.header}}
                                  <p-sortIcon [field]="commitmentCol.field" ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order" 
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                              </ng-container>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-commitment let-columns='columns'>
                            <tr *ngIf='commitment | commitmentFilter:selectedContractOption:currentDate'>
                              <ng-container *ngFor="let commitmentCol of columns">
                                <ng-container [ngSwitch]="commitmentCol.field">
                                  <td *ngSwitchCase="'ServiceStartDate'">
                                    {{(commitment.ServiceStartDate === '0001-01-01')? '': commitment.ServiceStartDate | date:'dd/MM/yyyy'}}
                                  </td>
                                  <td *ngSwitchCase="'ServiceEndDate'">
                                    {{(commitment.ServiceEndDate === '0001-01-01')? '': commitment.ServiceEndDate | date:'dd/MM/yyyy'}}
                                  </td>
                                  <td *ngSwitchCase="'NextBillingDate'">
                                    {{(commitment.NextBillingDate === '0001-01-01')? '': commitment.NextBillingDate | date:'dd/MM/yyyy'}}
                                  </td>
                                  <td *ngSwitchCase="'Price'" class='number'>
                                    {{commitment.Price | number: '1.2-2'}}
                                  </td>
                                  <td *ngSwitchCase="'CalculationBaseAmount'" class='number'>
                                    {{commitment.CalculationBaseAmount | number: '1.2-2'}}
                                  </td>
                                  <td *ngSwitchCase="'QuantityDecimal'" class='number'>
                                    {{commitment.QuantityDecimal}}
                                  </td>
                                  <td *ngSwitchDefault>
                                    {{commitment[commitmentCol.field]}}
                                  </td>
                                </ng-container>
                              </ng-container>
                            </tr>
                          </ng-template>
                          <ng-template *ngIf='contract.moreMatches' pTemplate="summary">
                            <div class='col-12'>
                              <div class='alert alert-warn'>
                                There are more commitments on this contract, please use Business Central to see them.
                              </div>
                            </div>
                          </ng-template>
                        </p-table>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template *ngIf='bcDataForCustomer.contracts.moreMatches' pTemplate="summary">
                    <div class='col-12'>
                      <div class='alert alert-warn'>
                        There are more contracts for this customer, please use Business Central to see them.
                      </div>
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </ng-template>
          </ng-template>
        </div>
        <div class='row'>
          <div class='col-12'>
            <h4>Ledger Entries</h4>
          </div>
          <ng-container *ngIf='bcDataForCustomer.ledgerEntries.error; else noLedgerError'>
            <div class='col-12'>
              <div class='alert alert-danger'>
                Error loading ledger entry data.
              </div>
            </div>
          </ng-container>
          <ng-template #noLedgerError>
            <ng-container *ngIf='!bcDataForCustomer.ledgerEntries.data; else ledgerEntriesFound'>
              <div class='col-12'>
                <div class='alert alert-info'>
                  No ledger entries found in Business Central.
                </div>
              </div>
            </ng-container>
            <ng-template #ledgerEntriesFound>
              <div class='col-12'>
                <div class='alert alert-info'>
                  Payments made in the last 7 days may not show below.
                </div>
              </div>
              <div class='col-12'>
                <p-table #ledgerTable [value]='bcDataForCustomer.ledgerEntries.data' [paginator]="true" [rows]="10" dataKey="No"
                    [columns]='ledgerColumns' [sortMode]='"single"' [sortField]="'DocumentDate'" [sortOrder]=-1 [showCurrentPageReport]="true"
                    currentPageReportTemplate="Page {currentPage} of {totalPages} - Ledger Entry {first} to {last} of {totalRecords}">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <ng-container *ngFor="let ledgerEntryCol of columns">
                        <th [pSortableColumn]="ledgerEntryCol.field">
                          {{ledgerEntryCol.header}}
                          <p-sortIcon [field]="ledgerEntryCol.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order" 
                            ariaLabelAsc="Activate to sort in ascending order">
                          </p-sortIcon>
                        </th>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-ledgerEntry let-expanded='expanded' let-columns='columns'>
                    <tr>
                      <ng-container *ngFor="let ledgerEntryCol of columns">
                        <ng-container [ngSwitch]="ledgerEntryCol.field">
                          <td *ngSwitchCase="'Open'">{{ledgerEntry.Open? 'Yes': 'No'}}</td>
                          <td *ngSwitchCase="'DocumentDate'">
                            {{(ledgerEntry.DocumentDate === '0001-01-01')? '': ledgerEntry.DocumentDate | date:'dd/MM/yyyy'}}
                          </td>
                          <td *ngSwitchCase="'DueDate'">
                            {{(ledgerEntry.DueDate === '0001-01-01')? '': ledgerEntry.DueDate | date:'dd/MM/yyyy'}}
                          </td>
                          <td *ngSwitchCase="'OriginalAmount'" class='number'>
                            {{ledgerEntry.OriginalAmount | number: '1.2-2'}}
                          </td>
                          <td *ngSwitchCase="'Amount'" class='number'>
                            {{ledgerEntry.Amount | number: '1.2-2'}}
                          </td>
                          <td *ngSwitchCase="'RemaininAmount'" class='number'>
                            {{ledgerEntry.RemaininAmount | number: '1.2-2'}}
                          </td>
                          <td *ngSwitchDefault>
                            {{ledgerEntry[ledgerEntryCol.field]}}
                          </td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template *ngIf='bcDataForCustomer.ledgerEntries.moreMatches' pTemplate="summary">
                    <div class='col-12'>
                      <div class='alert alert-warn'>
                        There are more ledger entries for this customer, please use Business Central to see them.
                      </div>
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</p-dialog>
<p-confirmDialog key="aboveBlockMessage" [baseZIndex]="6000"></p-confirmDialog>