import { Pipe, PipeTransform } from '@angular/core';
import {BcServiceCommitment} from '../../models/microsoft/businessCentral/BcServiceCommitment.model';

@Pipe({
  name: 'commitmentFilter'
})
export class CommitmentFilterPipe implements PipeTransform {

  transform(commitment: BcServiceCommitment, contractOption: string, currentDate: string): boolean {
    if (!commitment) {
      return false;
    }
    switch (contractOption) {
      case 'Past':
        if (commitment.ServiceEndDate == '0001-01-01') {
          return false;
        }
        return (commitment.ServiceEndDate <= currentDate);
      case 'Current':
        if (commitment.ServiceStartDate == '0001-01-01') {
          return false;
        }
        return ((commitment.ServiceStartDate <= currentDate) &&
          ((commitment.ServiceEndDate == '0001-01-01') || (commitment.ServiceEndDate >= currentDate)));
      case 'Future':
        if (commitment.ServiceStartDate == '0001-01-01') {
          return false;
        }
        return (commitment.ServiceStartDate > currentDate);
      default:
        return true;
    }
  }
}
