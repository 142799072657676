import {SelectItem} from 'primeng/api';

const CONTRACT_OPTIONS: SelectItem<string>[] = [{
  'label': 'Past',
  'value': 'Past',
}, {
  'label': 'Current',
  'value': 'Current',
}, {
  'label': 'Future',
  'value': 'Future',
}, {
  'label': 'All',
  'value': 'All',
}];

export {
  CONTRACT_OPTIONS,
};
