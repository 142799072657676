import {Column} from '../../models/column.model';

const LEDGER_COLUMNS: Column[] = [
  {field: 'DocumentDate', header: 'Document Date', order: 10, hide: false},
  {field: 'DocumentType', header: 'Document Type', order: 20, hide: false},
  {field: 'DocumentNo', header: 'Document No.', order: 30, hide: false},
  {field: 'Description', header: 'Description', order: 40, hide: false},
  {field: 'OriginalAmount', header: 'Orginal Amount', order: 50, hide: false},
  {field: 'Amount', header: 'Amount', order: 60, hide: false},
  {field: 'RemaininAmount', header: 'Remaining Amount', order: 70, hide: false},
  {field: 'DueDate', header: 'Due Date', order: 80, hide: false},
  {field: 'Open', header: 'Open', order: 90, hide: false},
];

export {
  LEDGER_COLUMNS,
};
