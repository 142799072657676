import {Column} from '../../models/column.model';

const cseOrderCols: Column[] = [
  {field: 'createdAt', header: 'Order Date', order: 10, hide: false},
  {field: 'websiteId', header: 'Brand', order: 20, hide: false},
  {field: 'user', header: 'Order Taker', order: 30, hide: false},
  {field: 'websiteOrderId', header: 'Website Id', order: 40, hide: false},
  {field: 'tdCode', header: 'TD Code', order: 50, hide: false},
  {field: 'orderType', header: 'Order Type', order: 60, hide: false},
  {field: 'fault', header: 'Fault', order: 70, hide: true},
  {field: 'otherFault', header: 'Other Fault', order: 80, hide: true},
  {field: 'originalOrderTotal', header: 'Original Total', order: 90, hide: true},
  {field: 'finalOrderTotal', header: 'Final Total', order: 100, hide: false},
  {field: 'finalOrderVat', header: 'Final VAT', order: 110, hide: true},
  {field: 'proRataMultiplier', header: 'Pro-rata Multiplier', order: 120, hide: true},
  {field: 'proRataCurrentPrice', header: 'Pro-rata Current Price', order: 130, hide: true},
  {field: 'proRataToDate', header: 'Pro-rata to Date', order: 140, hide: true},
  {field: 'nextRenewalDate', header: 'Next Renewal to Date', order: 145, hide: true},
  {field: 'overrideReason', header: 'Price Override Reason', order: 150, hide: true},
  {field: 'overrideReasonOther', header: 'Price Override Other Reason', order: 160, hide: true},
  {field: 'couponCode', header: 'Coupon Code', order: 170, hide: false},
  {field: 'couponAmount', header: 'Coupon Discount', order: 180, hide: false},
  {field: 'howHeard', header: 'How Heard', order: 190, hide: true},
  {field: 'partnership', header: 'Partnership', order: 200, hide: true},
  {field: 'referringAccount', header: 'Referring Account', order: 210, hide: true},
  {field: 'calledFrom', header: 'Called From', order: 220, hide: true},
  {field: 'billingPostcode', header: 'Billing Postcode', order: 230, hide: true},
  {field: 'alarmUserPostcode', header: 'Alarm User Postcode', order: 240, hide: true},
  {field: 'leadId', header: 'Lead Id', order: 250, hide: true},
  {field: 'currentPlan', header: 'Current Plan', order: 260, hide: true},
  {field: 'newPlan', header: 'New Plan', order: 265, hide: true},
  {field: 'currentRenewalPrice', header: 'Current Renewal Price', order: 270, hide: true},
  {field: 'newRenewalPrice', header: 'New Renewal Price', order: 275, hide: true},
  {field: 'paymentMethod', header: 'Payment Method', order: 280, hide: true},
];

const cseLineCols: Column[] = [
  {field: 'cseOrderLine', header: 'Line Number', order: 10, hide: false},
  {field: 'cseOrderLinePart', header: 'Line Part', order: 20, hide: false},
  {field: 'cseOrderTitle', header: 'Title', order: 30, hide: false},
  {field: 'originalPrice', header: 'Original Price', order: 40, hide: false},
  {field: 'finalPrice', header: 'Final Price', order: 50, hide: false},
  {field: 'finalVat', header: 'Final VAT', order: 60, hide: false},
  {field: 'quantity', header: 'Quantity', order: 70, hide: false},
  {field: 'productId', header: 'Product Id', order: 80, hide: true},
  {field: 'variationId', header: 'Variation Id', order: 90, hide: true},
  {field: 'hardwareId', header: 'Hardware Id', order: 100, hide: true},
  {field: 'shopifyProductId', header: 'Shopify Product Id', order: 110, hide: true},
  {field: 'shopifyVariantId', header: 'Shopify Variant Id', order: 120, hide: true},
];

const existingEquipCols: Column[] = [
  {field: 'nameOnOrder', header: 'Name On Order', order: 10, hide: false},
  {field: 'serial', header: 'Serial', order: 20, hide: false},
  {field: 'replacing', header: 'Replacing', order: 30, hide: false},
  {field: 'hardwareId', header: 'Hardware Id', order: 40, hide: true},
  {field: 'equipId', header: 'Equipment Id', order: 50, hide: true},
];

export {
  cseOrderCols,
  cseLineCols,
  existingEquipCols,
}
