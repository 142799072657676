import {Column} from '../../models/column.model';

const COMMITMENT_COLUMNS: Column[] = [
  {field: 'ServiceObjectNo', header: 'Service Object', order: 10, hide: false},
  {field: 'ServiceStartDate', header: 'Start Date.', order: 20, hide: false},
  {field: 'ServiceEndDate', header: 'End Date', order: 30, hide: false},
  {field: 'ItemNo', header: 'Item No.', order: 40, hide: false},
  {field: 'Description', header: 'Description', order: 50, hide: false},
  {field: 'NextBillingDate', header: 'Next Bill', order: 60, hide: false},
  {field: 'Price', header: 'Price', order: 70, hide: false},
  {field: 'CalculationBaseAmount', header: 'Base Amount', order: 80, hide: false},
  {field: 'QuantityDecimal', header: 'Quantity', order: 90, hide: false},
  {field: 'BillingRhythm', header: 'Billing Freq.', order: 100, hide: false},
];

export {
  COMMITMENT_COLUMNS,
};
