import {SelectItem} from 'primeng/api';
import {sortByLabel} from '../helpers/helperFunctions';

interface BrandOrTypeSelectItem extends SelectItem<string> {
  'hardwareName'?: string;
  'brandOrTypeRegex'?: string;
  'manufacturedYearRegex'?: string;
  'yearFirst'?: boolean;
  'weekOrMonth'?: string;
  'manualFile'?: string;
}

const HARDWARE_LOOKUP_KEY_PREFIX: string = 'hardware: brands/types for plan code ';

let hardwareSerialLookups: BrandOrTypeSelectItem[];
let brandsOrTypesByPlanCode: {[planCode: string]: BrandOrTypeSelectItem[]} = {};

function getHardwareSerialLookups(): BrandOrTypeSelectItem[] {
  if (!hardwareSerialLookups) {
    const tmpHardwareSerialLookups: BrandOrTypeSelectItem[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key: string = localStorage.key(i);
      if (key.startsWith(HARDWARE_LOOKUP_KEY_PREFIX)) {
        const brandSelects: BrandOrTypeSelectItem[] = JSON.parse(localStorage.getItem(key))
        brandSelects.forEach((brandOrType: BrandOrTypeSelectItem) => {
          if (brandOrType.brandOrTypeRegex) {
            tmpHardwareSerialLookups.push(brandOrType);
          }
        });
      }
    }
    if (tmpHardwareSerialLookups.length > 0) {
      hardwareSerialLookups = tmpHardwareSerialLookups;
    }
    return tmpHardwareSerialLookups;
  }
  return hardwareSerialLookups;
}

function getBrandsOrTypesForPlanCode(planCode: string): BrandOrTypeSelectItem[] {
  if (!brandsOrTypesByPlanCode[planCode]) {
    const lookupKey: string = `${HARDWARE_LOOKUP_KEY_PREFIX}${planCode}`;
    // Handle requests to get details before login is complete
    if (!localStorage.getItem(lookupKey)) {
      return [];
    }
    brandsOrTypesByPlanCode[planCode] = JSON.parse(localStorage.getItem(lookupKey)) || [];
  }
  return brandsOrTypesByPlanCode[planCode];
}

let equipmentLookups: BrandOrTypeSelectItem[] = [];
// We cannot distinguish between a Pebbell and Chiptech where a UK phone number is the serial
const GPS_PLANS: string[] = [
  '#1B', '#1BF', '#1T', '#1TF'
];
// We cannot distinguish between a James and Chiptech where a Swedish phone number entered
const SWEDISH_SIM_PLANS: string[] = [
  '#1T', '#1TF', '#1J', '#1JF'
]

const UK_PHONE_NUM_MATCH: RegExp = /\b447\d{9}\b/;
const SWEDISH_PHONE_NUM_MATCH: RegExp = /\b467\d{12}\b/;

function getBrandOrTypeFromSerialAndPlan(serial: string, planCode: string): BrandOrTypeSelectItem {
  let matchingSelectItem: BrandOrTypeSelectItem = undefined;
  if (!serial || !planCode) {
    return matchingSelectItem;
  }
  const brandOrTypeLookups: BrandOrTypeSelectItem[] = getBrandsOrTypesForPlanCode(planCode);
  matchingSelectItem = brandOrTypeLookups.find((brandOrType: BrandOrTypeSelectItem) => {
    if (brandOrType.brandOrTypeRegex) {
      const typeRegex: RegExp = new RegExp(brandOrType.brandOrTypeRegex, 'i');
      return typeRegex.test(serial);
    }
    return false;
  });
  if (GPS_PLANS.includes(planCode) || SWEDISH_SIM_PLANS.includes(planCode)) {
    if (!matchingSelectItem) {
      // Sometime the FD/non-FD prefix isn't correct
      if (planCode.endsWith('F')) {
        planCode = planCode.replace(/F$/, '');
      } else {
        planCode = `${planCode}F`;
      }
      const brandOrTypeLookups: BrandOrTypeSelectItem[] = getBrandsOrTypesForPlanCode(planCode);
      matchingSelectItem = brandOrTypeLookups.find((brandOrType: BrandOrTypeSelectItem) => {
        if (brandOrType.brandOrTypeRegex) {
          const typeRegex: RegExp = new RegExp(brandOrType.brandOrTypeRegex, 'i');
          return typeRegex.test(serial);
        }
        return false;
      });
    }
  }
  return matchingSelectItem;
}

function getManualFilenameFromSerialAndPlan(serial: string, planCode: string): string {
  const matchingSelectItem: BrandOrTypeSelectItem = getBrandOrTypeFromSerialAndPlan(serial, planCode);
  if (matchingSelectItem && matchingSelectItem.manualFile) {
    return matchingSelectItem.manualFile;
  }
  return '';
}

function getManualLookups(): SelectItem<string>[] {
  const manualLookups: SelectItem<string>[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key: string = localStorage.key(i);
    if (key.startsWith(HARDWARE_LOOKUP_KEY_PREFIX)) {
      const brandSelects: BrandOrTypeSelectItem[] = JSON.parse(localStorage.getItem(key))
      brandSelects.forEach((brandOrType: BrandOrTypeSelectItem) => {
        if (brandOrType.manualFile) {
          const manualAlreadyInList: boolean = manualLookups.some((lookup: SelectItem<string>) => {
            return (lookup.value == brandOrType.manualFile);
          });
          if (!manualAlreadyInList) {
            manualLookups.push({
              'label': brandOrType.label,
              'value': brandOrType.manualFile
            });
          }
        }
      });
    }
  }
  return sortByLabel(manualLookups);
}

function getEquipmentNameFromSerialAndPlan(serial: string, planCode: string): string {
  const matchingSelectItem: BrandOrTypeSelectItem = getBrandOrTypeFromSerialAndPlan(serial, planCode);
  if (matchingSelectItem && matchingSelectItem.label) {
    if ((GPS_PLANS.includes(planCode) && UK_PHONE_NUM_MATCH.test(serial)) ||
        (SWEDISH_SIM_PLANS.includes(planCode) && SWEDISH_PHONE_NUM_MATCH.test(serial))) {
      return `${matchingSelectItem.label} - GPS type assumed`;
    }
    return matchingSelectItem.label;
  }
  return '';
}

function getEquipmentNameFromSerial(serial: string): string {
  if (!serial) {
    return '';
  }
  if (UK_PHONE_NUM_MATCH.test(serial)) {
    return 'Pebbell/GO - Unknown';
  }
  if (SWEDISH_PHONE_NUM_MATCH.test(serial)) {
    return 'James/GO - Unknown';
  }
  const serialLookups: BrandOrTypeSelectItem[] = getHardwareSerialLookups();
  const matchingSelectItem: BrandOrTypeSelectItem = serialLookups.find((serialLookup: BrandOrTypeSelectItem) => {
    const serialRegex: RegExp = new RegExp(serialLookup.brandOrTypeRegex, 'i');
    return (serialRegex.test(serial));
  });
  if (matchingSelectItem) {
    return matchingSelectItem.label;
  }
  return '';
}


function getManualFilenameFromSerial(serial: string): string {
  if (!serial) {
    return '';
  }
  const serialLookups: BrandOrTypeSelectItem[] = getHardwareSerialLookups();
  const matchingSelectItem: BrandOrTypeSelectItem = serialLookups.find((serialLookup: BrandOrTypeSelectItem) => {
    const serialRegex: RegExp = new RegExp(serialLookup.brandOrTypeRegex, 'i');
    return (serialRegex.test(serial));
  });
  if (matchingSelectItem) {
    return matchingSelectItem.manualFile;
  }
  return '';
}

function getHardwareNameFromSerial(serial: string): string {
  if (!serial || UK_PHONE_NUM_MATCH.test(serial)) {
    return '';
  }
  const serialLookups: BrandOrTypeSelectItem[] = getHardwareSerialLookups();
  const matchingSelectItem: BrandOrTypeSelectItem = serialLookups.find((serialLookup: BrandOrTypeSelectItem) => {
    const serialRegex: RegExp = new RegExp(serialLookup.brandOrTypeRegex, 'i');
    return (serialRegex.test(serial));
  });
  if (matchingSelectItem) {
    return matchingSelectItem.hardwareName? matchingSelectItem.hardwareName: matchingSelectItem.value;
  }
  return '';
}

export {
  BrandOrTypeSelectItem,
  getBrandsOrTypesForPlanCode,
  getManualFilenameFromSerialAndPlan,
  getManualFilenameFromSerial,
  getManualLookups,
  getEquipmentNameFromSerialAndPlan,
  getEquipmentNameFromSerial,
  getHardwareNameFromSerial,
}
