import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Order} from '../../models/order.model';
import {OrderService} from '../order.service';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {BcDataForCustomer} from '../../models/responses/businessCentral/bcDataForCustomer.model';
import {Column} from '../../models/column.model';
import {COMMITMENT_COLUMNS} from '../../lookups/finance/commitmentColumns';
import {CONTRACT_COLUMNS} from '../../lookups/finance/contractColumns';
import {LEDGER_COLUMNS} from '../../lookups/finance/ledgerColumns';
import {CONTRACT_OPTIONS} from '../../lookups/finance/contractOptions';
import {Table} from 'primeng/table';
import moment from 'moment-timezone';

@Component({
  selector: 'app-finance-dialog[order][closeModal]',
  templateUrl: './finance-dialog.component.html',
  styleUrls: ['../post-order-sections.scss', './finance-dialog.component.scss'],
})
export class FinanceDialogComponent implements OnInit {
  @Input() order: Order;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('contractTable', {static: false}) private contractTable: Table;
  dialogVisible: boolean;
  loading: boolean;
  bcDataForCustomer: BcDataForCustomer;
  contractOptions: SelectItem<string>[];
  selectedContractOption: string;
  contractColumns: Column[];
  commitmentColumns: Column[];
  ledgerColumns: Column[];
  currentDate: string;

  constructor(
    private orderService: OrderService,
    public confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    if (!this.order) {
      return;
    }
    this.loading = true;
    this.contractOptions = CONTRACT_OPTIONS;
    this.selectedContractOption = 'Current';
    this.contractColumns = CONTRACT_COLUMNS;
    this.commitmentColumns = COMMITMENT_COLUMNS;
    this.ledgerColumns = LEDGER_COLUMNS;
    this.currentDate = moment.tz('Europe/London').format('YYYY-MM-DD');
    this.dialogVisible = true;
    this.orderService.getBusinessCentralData(this.order.legalCompany, this.order.alarmUserDetails.tdCode).subscribe({
      'next': (response: SingleRecordResponse<BcDataForCustomer>) => {
        let errorMsg: string = '';
        if (response.success) {
          if (!response.data) {
            errorMsg = 'No data returned';
          } else {
            this.bcDataForCustomer = response.data;
            // Use set timeout to delay enough for the contract table to render
            setTimeout(() => {
              this.contractOptionChange();
            });
          }
        } else {
          errorMsg = response.message;
        }
        this.loading = false;
        if (errorMsg) {
          this.showErrorPopUp('Error loading Business Central data',
            `There was an error loading the data from Business Central please try again.\n${errorMsg}`);
        }
      }, 
      'error': (error: any) => {
        this.loading = false;
        this.showErrorPopUp('Error loading Business Central data',
          `There was an error loading the data from Business Central please try again.\n${error.message}`);
      }
    })
  }

  contractOptionChange() {
    if (this.contractTable) {
      this.contractTable.filter(this.selectedContractOption, 'ServiceCommitments', 'contract-has-relevant-commitments');
    }
  }

  closeDialog() {
    this.dialogVisible = false;
    this.closeModal.emit();
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
