import {Component, OnInit, OnDestroy} from '@angular/core';
import {WebsiteService} from "../setup/websites/website.service";
import {SelectItem, ConfirmationService} from 'primeng/api';
import {OrderService} from "../post-order/order.service";
import {Router} from "@angular/router";
import moment from 'moment-timezone';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {OrderResponse} from '../models/responses/orderResponse.model';
import {WebsitesResponse} from '../models/responses/websitesResponse.model';
import {Website} from '../models/website.model';
import {Title} from '@angular/platform-browser';
import {phoneNumberOnly} from '../helpers/keyboardHelpers';
import {Order, OrderTag, Vim} from '../models/order.model';
import {BusinessGrowthCustomer} from '../models/businessGrowthCustomer.model';
import {BusinessGrowthService} from '../business-growth-customers/business-growth.service';
import {CseOrderNavigationData} from '../models/cseOrderNavigationData.model';
import { LocksSocketService } from '../sockets/locks-socket.service';
import { LeadLockData } from '../models/socket-io/leadLockData.model';
import {OrderType, getCseOrderTypes, CalculationMethod} from '../lookups/cseOrder/cseOrderTypes';
import {doPlanCodeAndTypeMatch, roundToTwoDecimalPlaces} from '../helpers/helperFunctions';
import {PRICE_REGEX, getRenewalDateDetails} from '../helpers/getPlanCodeAndPrice';
import {FutureRenewalDetails, RenewalDateDetails} from '../models/responses/functionReturns/renewalDateDetails.model';
import {getCseOrderPageFaults} from '../lookups/faultyEquipment';
import {UsersService} from '../setup/users/users.service';
import {BaseOrderInformation} from '../models/cseOrder/baseOrderInformation.model';
import {formatPhoneNumberInE164Format} from '../validators/phone-number.validator';

@Component({
  selector: 'app-cse-order',
  templateUrl: './cse-order.component.html',
  styleUrls: ['./cse-order.component.scss'],
  providers: [ConfirmationService]
})
export class CseOrderComponent implements OnInit, OnDestroy {
  phoneNumber = phoneNumberOnly;
  websites: Website[];
  baseOrderInformation: BaseOrderInformation;
  searchTDCode: string;
  tdCodeFound: boolean;
  tdCodeSearched: boolean;
  showPlaceOrder: boolean;
  partnerships: SelectItem<BusinessGrowthCustomer>[];
  partnershipByVoucherCode: {[code: string]: BusinessGrowthCustomer};
  orderTypes: SelectItem<OrderType>[] = [];
  showOrderForm: boolean;
  vimPopupOpen: boolean = false;
  cseOrderNavigationData: CseOrderNavigationData;
  crmRenewalDate: string;
  faultOptions: SelectItem<string>[];
  userName: string;

  constructor(
    private router: Router,
    private title: Title,
    private websiteService: WebsiteService,
    private orderService: OrderService,
    private businessGrowthService: BusinessGrowthService,
    private locksSocket: LocksSocketService,
    private userService: UsersService,
    private confirmationService: ConfirmationService,
  ) {
    // This has to be done here as ngOnInit is too late, navigation has completed
    if (this.router.getCurrentNavigation().extras.state) {
       this.cseOrderNavigationData = (this.router.getCurrentNavigation().extras.state as CseOrderNavigationData);
    }
  }

  ngOnDestroy(): void {
    if (this.cseOrderNavigationData) {
      this.locksSocket.emit('unlockingLead', { 'leadId': this.cseOrderNavigationData.leadId, 'user': this.userName });
    }
  }

  ngOnInit() {
    this.showPlaceOrder = true;
    this.userName = localStorage.getItem('userName');
    this.orderTypes = getCseOrderTypes();
    this.showOrderForm = false;
    this.tdCodeFound = false;
    this.tdCodeSearched = false;
    this.baseOrderInformation = {
      'cseOrder': {
        'orderId': null,
        'tdCode': '',
        'referringAccount': '',
        'websiteId': null,
        'user': this.userName,
        'websiteOrderId': '',
        'orderType': '',
        'fault': '',
        'otherFault': '',
        'originalOrderTotal': 0,
        'finalOrderTotal': 0,
        'finalOrderVat': 0,
        'proRataMultiplier': '',
        'proRataCurrentPrice': 0,
        'proRataToDate': '',
        'nextRenewalDate': '',
        'overrideReason': '',
        'overrideReasonOther': '',
        'couponCode': '',
        'couponAmount': 0,
        'cseOrderItems': [],
        'existingActiveItems': [],
        'howHeard': '',
        'partnership': '',
        'calledFrom': '',
        'billingPostcode': '',
        'alarmUserPostcode': '',
        'leadId': null,
        'currentPlan': '',
        'newPlan': '',
        'currentRenewalPrice': null,
        'newRenewalPrice': null,
        'paymentMethod': '',
      },
      'orderType': undefined,
      'withheldNumber': false,
      'allowReplacementWithoutTd': false,
      'cseOrderNavigationData': this.cseOrderNavigationData,
      'existingOrder': undefined,
      'currentMoment': moment.tz('Europe/London'),
      'hasBulkTag': false,
      'proRataMultiplier': 0,
    };
    this.partnerships = [];
    this.partnershipByVoucherCode = {};
    this.businessGrowthService.getBusinessGrowthCustomers().subscribe({
      next: (response: MultiRecordResponse<BusinessGrowthCustomer>) => {
        if (!response.success) {
          console.error(`Error getting Business Growth Customers. Error: ${response.message}`, response.error);  
        } else {
          response.data.forEach((bgCust: BusinessGrowthCustomer) => {
            this.partnerships.push({
              'label': bgCust.bgcName,
              'value': bgCust,
            });
            if (bgCust.bgcCouponCode) {
              this.partnershipByVoucherCode[bgCust.bgcCouponCode] = bgCust;
            }
          });
        }
      },
      error: (err: any) => {
        console.error('Cautght error getting Business Growth Customers. Error: ', err);
      }
    });
    this.faultOptions = getCseOrderPageFaults();
    this.websiteService.getWebsites().subscribe({
      next: (websites: WebsitesResponse) => {
        this.websites = websites.websites;
        if (this.cseOrderNavigationData) {
          const brand: string = this.cseOrderNavigationData.brand;
          if (brand) {
            const website: Website = this.websites.find((website: Website) => website.title == brand);
            if (website) {
              this.selectBrand(website);
            }
          }
        }
      },
      error: (err: any) => {
        console.error('Error getting website data', err);
      }
    });
    this.title.setTitle('CRM Order');
    if (this.cseOrderNavigationData) {
      this.baseOrderInformation.orderType = this.orderTypes.find((orderType: SelectItem<OrderType>) => (orderType.value.title == 'Lead')).value;
      this.locksSocket.emit('lockingLead', {
        leadId: this.cseOrderNavigationData.leadId,
        user: this.userName
      }, (addedLead: LeadLockData) => {
        console.log(`lead locked ${addedLead.added}`)
      });
    }
  }

  get selectedWebsite(): Website {
    return (this.baseOrderInformation.cseOrder.websiteId as Website);
  }

  selectBrand(website: Website) {
    // Don't allow changes if processing started
    if (!this.showPlaceOrder) {
      return;
    }
    if (this.baseOrderInformation.cseOrder.websiteId && (website.title == this.selectedWebsite.title)) {
      // Already on the correct one, nothing to do
      return;
    }
    this.baseOrderInformation.orderType = undefined;
    this.showOrderForm = false;
    this.clearFields();
    // Clear existing order details/search
    this.tdCodeFound = false;
    this.searchTDCode = '';
    this.baseOrderInformation.cseOrder.websiteId = website;
  }

  clearFields() {
    this.baseOrderInformation.hasBulkTag = false;
    this.baseOrderInformation.existingOrder = undefined;
    this.baseOrderInformation.allowReplacementWithoutTd = false;
    this.baseOrderInformation.withheldNumber = false;
    this.baseOrderInformation.cseOrder.calledFrom = '';
    this.baseOrderInformation.cseOrder.nextRenewalDate = '';
    this.baseOrderInformation.cseOrder.proRataToDate = '';
    this.baseOrderInformation.cseOrder.proRataCurrentPrice = 0;
    this.baseOrderInformation.proRataMultiplier = 0;
    this.crmRenewalDate = '';
    this.baseOrderInformation.cseOrder.newPlan = '';
    this.baseOrderInformation.cseOrder.currentRenewalPrice = null;
    this.baseOrderInformation.cseOrder.newRenewalPrice = null;
    this.baseOrderInformation.cseOrder.existingActiveItems = [];
  }

  get order(): Order {
    return this.baseOrderInformation.existingOrder;
  }

  get selectedOrderType(): OrderType {
    return this.baseOrderInformation.orderType;
  }

  get planType(): string {
    return (this.order && this.order.accountDetails)? this.order.accountDetails.planType: '';
  }

  get vims(): Vim[] {
    if (!this.order || !this.order.vim) {
      return [];
    }
    return this.order.vim;
  }

  set calledFrom(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.baseOrderInformation.cseOrder.calledFrom = e164FormatNumber;
  }

  get calledFrom(): string {
    return this.baseOrderInformation.cseOrder.calledFrom;
  }

  search() {
    this.tdCodeFound = false;
    this.tdCodeSearched = false;
    this.searchTDCode = this.searchTDCode.trim().toLocaleUpperCase();
    this.clearFields();
    if (!this.searchTDCode) {
      return;
    }
    this.showOrderForm = false;
    this.orderService.getOrderTdCode(this.searchTDCode, this.selectedWebsite._id).subscribe({
      next: (response: OrderResponse) => {
        this.tdCodeSearched = true;
        if (response.success && !!response.order) {
          this.baseOrderInformation.existingOrder = response.order;
          let hasNccTag: boolean = false;
          this.order.tags.forEach((tag: OrderTag) => {
            const tagName: string = tag.tagID.tagName.toLocaleLowerCase();
            if (tagName.startsWith('bulk')) {
              this.baseOrderInformation.hasBulkTag = true;
            }
            if (['norfolk cc', 'ncc 6 wk free'].includes(tagName)) {
              hasNccTag = true;
            }
          });
          if (this.baseOrderInformation.hasBulkTag && !this.userService.userHasPermission('Bulk Order Entry')) {
            this.showErrorPopUp('Bulk Account',
              'This order has a Bulk Account tag. Only staff with the "Bulk Order Entry" permission can place orders for these customers'
            );
            this.searchTDCode = '';
            return;
          } else if (hasNccTag && !this.userService.userHasPermission('NCC Order Entry')) {
            this.showErrorPopUp('NCC Account',
              'This order has an NCC Account tag. Only staff with the "NCC Order Entry" permission can place orders for these customers'
            );
            this.searchTDCode = '';
            return;
          }
          if (!this.order.accountDetails || !this.planType) {
            this.showErrorPopUp('Missing Plan Type', 'This order has no Plan Type you will need to set it before you can proceed.');
            this.searchTDCode = '';
            return;
          }
          if (!doPlanCodeAndTypeMatch(this.order.accountDetails.plan, this.planType)) {
            this.showErrorPopUp('Mismatched Plan Type', 
              `This order's plan code and plan type to not match. You will need to correct this before you can proceed.`
            );
            this.searchTDCode = '';
            return;
          }
          
          this.tdCodeFound = true;
          this.showOrderForm = true;
          this.vimPopupOpen = (this.order.vim.length > 0);
          this.calculateRenewalDateAndProrataMultiplier();
        } else {
          console.error('Order not found');
        }
      },
      error: (err: any) => {
        this.tdCodeSearched = true;
        console.error('Error finding existing order from TD Code', err);
      }
    });
  }

  closeVimPopup() {
    this.vimPopupOpen = false;
  }

  isExistingCustomer(): boolean {
    return this.selectedOrderType && !this.selectedOrderType.isNewOrder;
  }

  isReplacementWithoutTdAllowed(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.FREE);
  }

  isReplacementDueToFault(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.title == 'Faulty');
  }

  isAllowedDiscontinued(): boolean {
    return this.selectedOrderType &&
      ([CalculationMethod.FREE, CalculationMethod.LOST].includes(this.selectedOrderType.calculationMethod));
  }

  get orderTypeTitle(): string {
    if (!this.selectedOrderType) {
      return '';
    }
    return this.selectedOrderType.title;
  }

  changeOrderType() {
    this.showOrderForm = false;
    this.clearFields();
    this.searchTDCode = '';
    this.tdCodeFound = false;      
    this.tdCodeSearched = false;
    if (!this.isExistingCustomer()) {
      if (!this.selectedWebsite.allowNewCustomerOrders) {
        this.baseOrderInformation.orderType = undefined;
        this.showErrorPopUp('New Customer Orders Not Allowed',
          'New customer orders are not allowed on this brand, please select a different brand to create the order.'
        );
      } else {
        // Have to do it like this else init of shopify-order or woocommerce-order not called again
        setTimeout(() => {
          this.showOrderForm = true;
        });
      }
    }
  }

  changeReplacementWithoutTd() {
    if (this.isReplacementWithoutTdAllowed() && this.baseOrderInformation.allowReplacementWithoutTd)  {
      this.showOrderForm = true;
    } else {
      this.showOrderForm = this.tdCodeFound;
    }
  }

  isAdditional(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.ADDITIONAL);
  }

  calculateRenewalDateAndProrataMultiplier() {
    if ((this.planType == 'lifetime') && !(this.isAdditional() && (this.selectedWebsite.usingWebBackend === 'Shopify'))) {
      if (!this.isAllowedDiscontinued()) {
        this.showErrorPopUp('Lifetime Calculations',
          'Charges cannot be calculated for rental equipment for Lifetime orders, you will need to set the prices manually.'
        );
      }
      return;
    }
    // If this is > 0 it has already been calculated
    if (this.baseOrderInformation.proRataMultiplier > 0) {
      return;
    }
    const renewalDateDetails: RenewalDateDetails = getRenewalDateDetails(this.order);
    let futureRenewalDetails: FutureRenewalDetails;
    if (renewalDateDetails.crm) {
      futureRenewalDetails = renewalDateDetails.crm;
    } else {
      futureRenewalDetails = renewalDateDetails.calculated;
    }
    // Use first future rather than first adjustable as will add whole periods for those skipped
    // to avoid slight discrepancy that would be caused by periods having different number of days
    const daysToFirstFutureRenewal: number = futureRenewalDetails.firstFutureRenewal.diff(this.baseOrderInformation.currentMoment, 'days');
    switch (this.order.accountDetails.planType) {
      case 'monthly':
        this.baseOrderInformation.proRataMultiplier = daysToFirstFutureRenewal / 30;
        break;
      case 'quarterly':
        this.baseOrderInformation.proRataMultiplier = daysToFirstFutureRenewal / 91;
        break;
      case 'annual':
        this.baseOrderInformation.proRataMultiplier = daysToFirstFutureRenewal / 365;
        break;
    }
    this.baseOrderInformation.proRataMultiplier += futureRenewalDetails.renewalsBeforeAjustable;
    this.baseOrderInformation.cseOrder.proRataToDate = futureRenewalDetails.firstAdjustableRenewal.format('DD/MM/YYYY');
    this.baseOrderInformation.cseOrder.nextRenewalDate = futureRenewalDetails.firstFutureRenewal.format('DD/MM/YYYY');
    if (renewalDateDetails.crmStoredNextRenewal) {
      this.crmRenewalDate = renewalDateDetails.crmStoredNextRenewal.format('DD/MM/YYYY');
    }
    if (PRICE_REGEX.test(this.order.renewalInformation.renewalPrice)) {
      this.baseOrderInformation.cseOrder.proRataCurrentPrice =
        roundToTwoDecimalPlaces(Number(this.order.renewalInformation.renewalPrice) * this.baseOrderInformation.proRataMultiplier);
    }
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  updateShowPlaceOrder(newValue: boolean) {
    this.showPlaceOrder = newValue;
  }
}
