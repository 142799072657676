import {SelectItem} from 'primeng/api';

interface StatusCfg {
  [status: string]: {background: string, color: string}
}

const statusConfigs: StatusCfg = {
  'active': {background: "#70cd38", color: "#000000"},
  'no ncf': {background: "#e9c80d", color: "#000000"},
  'cancelling': {background: "#e0291f", color: "#000000"},
  'cancelled': { background: "#58585a", color: "#FFFFFF" },
  // 'One Off Purchase': { background: '#CC9933', color: '#000000' }
};

// This is for primeng dropdowns
const statusSelectItems: SelectItem<string>[] = [];

const statuses: string[] = Object.keys(statusConfigs);
statuses.forEach((status: string) => {
  statusSelectItems.push({label: status, value: status});
});

function getStatusColour(status: string, defaultColour: string = '#000000'): string {
  const config = statusConfigs[status];
  if (config) {
    return config.color;
  }
  return defaultColour;
}

function getStatusBackgroundColour(status: string, defaultColour: string = '#FFFFFF'): string {
  const config = statusConfigs[status];
  if (config) {
    return config.background;
  }
  return defaultColour;
}

interface EquipStatusCfg {
  [equipStatus: string]: {
    'active': boolean;
    'includeInRenewal': boolean;
    'validInSet': boolean;
    'displaySection'?: string;
    'disabled': boolean;
  };
}

const hardwareStatusConfig: EquipStatusCfg = {
  '': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'active', 'disabled': true},
  'Pending': {'active': true, 'includeInRenewal': false, 'validInSet': true, 'displaySection': 'active', 'disabled': false},
  'Live': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'active', 'disabled': false},
  'Returning': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition', 'disabled': false},
  'Returning - Chargeable': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition', 'disabled': true},
  'Returning - Non Chargeable': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition', 'disabled': true},
  'Returning - ALB': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition', 'disabled': true},
  'Returning - Chargeable - Faulty': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition', 'disabled': true},
  'Returning - Non- Chargeable - Faulty': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition', 'disabled': true},
  'Lost - Chasing': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition', 'disabled': false},
  'Lost - Paid': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive', 'disabled': false},
  'Missing': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive', 'disabled': false},
  'Returned - Faulty': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive', 'disabled': false},
  'Returned - Not Faulty': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive', 'disabled': false},
  'Missing - Never Sent': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive', 'disabled': false},
  'Damaged - Chargeable': {'active': true, 'includeInRenewal': true, 'validInSet': false, 'displaySection': 'transition', 'disabled': false},
  'Damaged - Recommending Write Off': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition', 'disabled': false},
  'Recommending Write Off': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition', 'disabled': false},
  'Written Off': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive', 'disabled': false},
};

const keySafeStatusConfig: EquipStatusCfg = {
  'Bought': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'disabled': false},
  'Returning - Faulty': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'disabled': false},
  'Returning - Non-Faulty': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'disabled': false},
  'Returned - Faulty': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'disabled': false},
  'Returned': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'disabled': false},
  'Missing': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'disabled': false},
}

const equipmentStatusSelectItems: SelectItem<string>[] = Object.keys(hardwareStatusConfig)
  .filter((key: string) => !!key)
  .map((key: string) => {
    return {
      'label': key,
      'value': key,
      'disabled': hardwareStatusConfig[key].disabled,
    }
  });

const keySafeStatusSelectItems: SelectItem<string>[] = Object.keys(keySafeStatusConfig).map((key: string) => {
  return {
    'label': key,
    'value': key,
    'disabled': keySafeStatusConfig[key].disabled,
  }
});

const serviceStatuses: string[] = [
  'Active',
  'Inactive'
];

// This is for the homepage filters
const allEquipStatusSelectItems: SelectItem<string>[] = [];

equipmentStatusSelectItems.forEach((status: SelectItem<string>) => {
  allEquipStatusSelectItems.push({label: status.label, value: status.value});
});

export {
  StatusCfg,
  EquipStatusCfg,
  statuses,
  statusConfigs,
  statusSelectItems,
  hardwareStatusConfig,
  keySafeStatusConfig,
  equipmentStatusSelectItems,
  allEquipStatusSelectItems,
  keySafeStatusSelectItems,
  serviceStatuses,
  getStatusColour,
  getStatusBackgroundColour,
}
