import {Column} from '../../models/column.model';

const CONTRACT_COLUMNS: Column[] = [
  {field: 'No', header: 'Contract Number', order: 10, hide: false},
  {field: 'SelltoCustomerNo', header: 'Sell To No.', order: 20, hide: false},
  {field: 'SelltoCustomerName', header: 'Sell To Name', order: 30, hide: false},
  {field: 'BilltoCustomerNo', header: 'Bill To No.', order: 40, hide: false},
  {field: 'BilltoName', header: 'Bill To Name', order: 50, hide: false},
  {field: 'Active', header: 'Active', order: 60, hide: false},
  {field: 'YourReference', header: 'Reference', order: 70, hide: false},
];

export {
  CONTRACT_COLUMNS,
};
